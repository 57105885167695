import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./AssetSummaryPopover.scss";
import { RootState } from "../../../store";
import AssetTags from "../assetTags/AssetTags";
import { getIsContentFailed, getIsDisableEditContent } from "../../helpers";
import { PATHS } from "../../constants";
import { updateViewCount } from "../../../services/contentManagement";
import { IContent } from "../../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  contentFilterField,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateContentFilter,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";

interface IAssetSummaryPopoverProps {
  content: IContent;
  tags?: boolean;
  fromWhere?: string;
}

const AssetSummaryPopover = (props: IAssetSummaryPopoverProps) => {
  const { content, tags = true, fromWhere = "" } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contentFilter } = useSelector((state: RootState) => state.content);
  const currentPage = contentFilter?.currentPage ?? 1;

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: getIsContentFailed(content),
        fromWhere,
      },
    });

    updateViewCount(assetId);
  };

  const renderReadMore = () => {
    const pointerClass = "cursor-pointer";
    return (
      <span
        className={`read-more ${
          !getIsDisableEditContent(content) ? pointerClass : "cursor-default"
        }`}
        onClick={() => handleContentDetail(content)}>
        read more
      </span>
    );
  };

  return (
    <div className="card-summary">
      <h4 className="summary-title">Summary</h4>
      <div className="card-summary-content">
        <p>
          {content?.summary && content.summary.length > 160 ? (
            `${content.summary.substring(0, 145)}...`
          ) : content?.summary ? (
            content.summary
          ) : (
            <div className="no-record">No records found.</div>
          )}
          {content?.summary && content.summary.length > 160 && renderReadMore()}
        </p>
      </div>
      {tags && (
        <div className="card-summary-tag acn-flex acn-flex-wrap">
          <div className="card-summary-tag-list acn-flex acn-flex-bottom">
            <AssetTags linkedTags={content?.linkedTags} content={content} fromWhere={fromWhere} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetSummaryPopover;
