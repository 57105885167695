import { Outlet } from "react-router-dom";
import "./AppLayout.scss";
import { SideBar } from "../../components";
import { History } from "../../pages";
import { ChatFloatingBtn, IMAGES, PATHS } from "../../shared";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { MenuProps } from "antd";
type MenuItem = Required<MenuProps>["items"][number];

const AppLayout = () => {
  const { appLayoutView } = useSelector((state: RootState) => state.app);

  const sideBarItems: MenuItem[] = [
    {
      label: "Home",
      icon: <img src={IMAGES.homeIcon} />,
      key: PATHS.home,
    },
    // {
    //   label: "History",
    //   icon: <img src={IMAGES.historyIcon} />,
    //   key: PATHS.history
    //   onClick: () => navigate(PATHS.accessGroup),/
    // },
    {
      label: "Sources",
      icon: <img src={IMAGES.sourcesIcon} />,
      key: PATHS.sources,
    },
    {
      label: "My Content",
      icon: <img src={IMAGES.myContentIcon} />,
      key: PATHS.myContent,
    },
    {
      label: "Pages",
      icon: <img src={IMAGES.pagesIcon} />,
      key: "pages-submenu",
      children: [
        {
          label: "Industry",
          key: "idustry-submenu",
          children: [
            { label: "Energy", key: PATHS.topicPage },
            { label: "Financial", key: 345 },
            { label: "Retail Industry", key: 234 },
            { label: "Aerospace", key: 344 },
            { label: "Automotive", key: 324 },
          ],
        },
        {
          label: "Function",
          key: "function-submenu",
          children: [
            { label: "Coming Soon", key: PATHS.comingSoon },
          ],
        },
      ],
    },
  ];

  return (
    <div className="app-layout-container">
      <div className={"side-bar-outlet-wrap sidebar-with-content sidebar-with-user-content"}>
        <SideBar {...{ sideBarItems }} />
      </div>
      <div className="history-outlet-container">
        {appLayoutView !== "close-chat-page" ? <History /> : ""}
        {appLayoutView !== "full-chat-page" ? (
          <>
            <ChatFloatingBtn />
            <Outlet />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AppLayout;
