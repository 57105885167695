import { Input } from "antd";
import { fileType, getAllIds, IMAGES, useChats } from "../../../shared";
import "./StartChatPanel.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import {
  setEmptyUploadFileList,
  setStartNewConversation,
  setUserQuestion,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { UploadMultipleAssets } from "../../uploadAssetsContentMangement";

const StartChatPanel = () => {
  const dispatch = useDispatch();
  const { selectedChatHistory, uploadFileList } = useSelector((state: RootState) => state.conversationalSearch);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const { clearAllChatStates, defaultConfigureState } = useChats();
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState(false);
  const [selectedFileList, setSelectedFileList] = useState<File[]>([]);

  const [question, setQuestion] = useState("");
  const handleEnterKeyAction = (e: any) => {
    if (e.key === "Enter" && question.trim()) {
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      dispatch(setStartNewConversation(true));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
  };

  useEffect(() => {
    if (uploadFileList?.length && selectedFileList.length === uploadFileList.length){
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      dispatch(setStartNewConversation(true));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
    
  }, [uploadFileList, selectedFileList])
    
  const handleFileUpload = (files: File[]) => {
    setSelectedFileList(files)
  }
  
  return (
    <>
      <div className="start-chat-panel">
        <div className="help-you">
          <div className="brand-icon">
            <img src={IMAGES.octopusIcon} alt="Brand Icon" />
          </div>
          <h2>
            Hello!
            <br />
            How can I help you today?
          </h2>
        </div>
        <div className="start-chat-bottom">
          {/* <div className="chat-tags">
            <Button className="tags-gpt"><i className="ci ci-intent-ai-icon ai" />Insight on Advanced AI</Button>
            <Button className="tags-gpt"><i className="ci ci-intent-flight-icon" />Insights on Airline Industry</Button>
            <Button className="tags-gpt"><i className="ci ci-intent-finance-icon" />Find last year's financial analysis</Button>
            <Button className="tags-gpt"><i className="ci ci-intent-file-icon" />Create slide on renewable energy presentation</Button>
          </div> */}
          <div className="ask-me-input d-flex d-flex-middle position-relative">
            <Input
              type="text"
              value={question}
              prefix={
                <i
                  onClick={() => [dispatch(setEmptyUploadFileList()), setIsOpenUploadAsset(true)]}
                  className="ri-attachment-2"></i>
              }
              placeholder="Ask me anything..."
              onKeyDownCapture={handleEnterKeyAction}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
            {/* <Button type="link" className="send-btn position-absolute">
              <i className="ci ci-mic"></i>
            </Button> */}
             <UploadMultipleAssets
              isModalOpen={isOpenUploadAsset}
              setIsModalOpen={setIsOpenUploadAsset}
              setSelectedFiles={handleFileUpload}
              uploadMultiple={true}
            />
          </div>
          {/* <Divider plain>Or</Divider>
          <Button onClick={() => navigate(PATHS.sources)} type="primary" className="start-chat-btn">
            Browse Content
          </Button> */}
          <p className="by-query">
            By entering query, you are agreeing to our <Link to={"#"}>privacy policy</Link>.
          </p>
        </div>
      </div>
    </>
  );
};

export default StartChatPanel;
