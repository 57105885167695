import { Button } from "antd";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "./PdfViewer.scss";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setPDFData, setPdfDetails } from "../../../store/pdfViewer/pdfViewer.slice";
import { IMAGES, RelevantAssetView } from "../../../shared";
import { searchPlugin } from "@react-pdf-viewer/search";
import jumpToPagePlugin from "./jumpToPagePlugin";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { getPDFPreSignedUrl } from "../../../services/conversationalSearch";
import LoadingPdf from "./LoadingPdf";
import { setRelevantAssetView } from "../../../store/contentManagement/contentSlice";

interface IPDFViewer {
  isRelevantAsset: boolean;
  btnText?: string;
  btnClick?: () => void;
}

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js`;

const PDFViewer = (props: IPDFViewer) => {
  const { isRelevantAsset, btnText = "View Detail", btnClick } = props;

  const [url, setUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pdfData, pdfDetails } = useSelector((state: RootState) => state.pdfViewer);

  const dispatch = useDispatch();

  const zoomPluginInstance = zoomPlugin();

  const toolbarPluginInstance = toolbarPlugin();

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { CurrentPageLabel, GoToFirstPage, GoToLastPage, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  const { ZoomIn, ZoomOut, CurrentScale } = zoomPluginInstance;
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const searchPluginInstance = searchPlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const { setTargetPages } = searchPluginInstance; // highlight need to add here
  const { page } = pdfDetails;

  useEffect(() => {
    setTargetPages((targetPage) => targetPage.pageIndex === page);
    handleJumpToPage();
  }, [page]);

  useEffect(() => {
    if (pdfDetails?.fileName && pdfData && Object.keys(pdfData).includes(pdfDetails?.fileName)) {
      if (url !== pdfData[pdfDetails?.fileName]) {
        setUrl(pdfData[pdfDetails?.fileName]);
        setIsError(false);
      }
    } else {
      fetchURL();
    }
  }, [pdfDetails]);

  const fetchURL = async () => {
    try {
      setIsLoading(true);
      const res = await getPDFPreSignedUrl(pdfDetails.fileName);
      const response = await fetch(res);

      if (response?.status === 200) {
        setIsError(false);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data: any = reader.result;
          dispatch(
            setPDFData({
              fileName: pdfDetails?.fileName,
              base64Data: base64data,
            })
          );
          setUrl(base64data);
        };
        reader.readAsDataURL(blob);
      } else {
        setIsError(true);
        setUrl("");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while fetching url", error);
    }
  };
  const openLinksInNewTab = () => {
    const containers = document.querySelectorAll(".rpv-core__annotation--link");
    containers?.forEach((container) => {
      const links = container?.querySelectorAll("a");

      links?.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const url = link.getAttribute("href");
          if (url) {
            window.open(url, "_blank");
          }
        });
      });
    });
  };

  const handleJumpToPage = () => {
    jumpToPage(page);
    // jumpToPage(page === 0 ? 2 : page);
  };

  const handleClosePDF = () => {
    document.body.classList.remove("relevant-assets-page"); // Toggle the class on body tag
    dispatch(setRelevantAssetView(RelevantAssetView.None));
    dispatch(
      setPdfDetails({
        assetId: -1,
        fileName: "",
        presignedUrl: "",
        title: "",
        page: 0,
        showOpenIcon: false,
      })
    );
  };

  const handleBtnClick = () => {
    if (btnClick) {
      return btnClick();
    }
    return dispatch(setRelevantAssetView(RelevantAssetView.PDFInfo));
  };

  return (
    <div className="pdfDetailsContainer">
      <div className="pdfHeaderContainer">
        <div className="pdfHeaderSubContainer">
          <ZoomIn>
            {(props) => (
              <Button className="icon-btn" onClick={props.onClick} type="text" shape="circle">
                <i className="ri-zoom-in-line"></i>
              </Button>
            )}
          </ZoomIn>
          <CurrentScale />
          <ZoomOut>
            {(props) => (
              <Button className="icon-btn" onClick={props.onClick} type="text" shape="circle">
                <i className="ri-zoom-out-line"></i>
              </Button>
            )}
          </ZoomOut>
        </div>

        <div className="page-navigation-icon-wrap">
          <GoToFirstPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled}
                type="text"
                shape="circle">
                <i className="ri-arrow-left-double-line"></i>
              </Button>
            )}
          </GoToFirstPage>
          <GoToPreviousPage>
            {(props) => {
              return (
                <Button
                  className="icon-btn"
                  onClick={props.onClick}
                  // disabled={props.isDisabled}
                  type="text"
                  shape="circle">
                  <i className="ri-arrow-left-s-line"></i>
                </Button>
              );
            }}
          </GoToPreviousPage>
          <CurrentPageLabel>
            {(props) => (
              <div className="Current-page-label">{`Page ${props.currentPage + 1} of ${
                props.numberOfPages
              }`}</div>
            )}
          </CurrentPageLabel>
          <GoToNextPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled}
                type="text"
                shape="circle">
                <i className="ri-arrow-right-s-line"></i>
              </Button>
            )}
          </GoToNextPage>
          <GoToLastPage>
            {(props) => (
              <Button
                className="icon-btn"
                onClick={props.onClick}
                disabled={props.isDisabled}
                type="text"
                shape="circle">
                <i className="ri-arrow-right-double-line"></i>
              </Button>
            )}
          </GoToLastPage>
        </div>

        <div className="asset-close-pdf-wrap">
          {isRelevantAsset && (
            <Button type="primary" className="btn" onClick={handleBtnClick}>
              {btnText}
            </Button>
          )}
          <img
            src={IMAGES.closePdfIcon}
            alt="Icon Link"
            className="close-pdf-icon"
            onClick={handleClosePDF}
          />
        </div>
      </div>
      {isLoading && (
        <div className="loading-pdf">
          <LoadingPdf />
        </div>
      )}
      {isError ? <div className="error-document">Document not found. Please try again</div> : <></>}
      {url && !isError ? (
        <Worker workerUrl={GlobalWorkerOptions.workerSrc}>
          <Viewer
            fileUrl={url}
            defaultScale={1}
            onDocumentLoad={() => {
              setTimeout(() => {
                openLinksInNewTab();
              }, 1000);
              handleJumpToPage();
            }}
            plugins={[
              toolbarPluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
              jumpToPagePluginInstance,
              searchPluginInstance,
            ]}
          />
        </Worker>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PDFViewer;
