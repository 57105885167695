import { Button, Card, Flex } from "antd";
import "./profileDetails.scss";
import { PageHeader } from "../../components";
import { motion } from "framer-motion";
import { IMAGES } from "../../shared";
import { Link } from "react-router-dom";

const ProfileDetails = () => {


  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="Profile Preview"
          isRelevantAsset={false}
          content={
            <>
              <Link to={'/profile'}><Button
                type="text"
                className="page-close-btn"
                icon={<i className="ri-close-fill"></i>}
              /></Link>
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Card className="profile-details-card">
            
            <div className="page-scroll">
              <Card className="profile-details-card-inner">
              <img className="w-100 d-flex" src={IMAGES.pdfViewer} alt=" " />
                <div className="profile-details-text">
                <div className="ant-tabs-extra-content">
                      <Button
                        type="text"
                        className="text-btn"
                        icon={<i className="ri-message-3-line"></i>}>
                        Chat
                      </Button>
                      <Button
                        type="text"
                        className="text-btn"
                        icon={<i className="ci-small-download-icon ci icon-link"></i>}>
                        Download
                      </Button>
                    </div>
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>

              </Card>

            </div>

            
          </Card>
        </motion.div>
      </Flex>
    </>
  );
};

export default ProfileDetails;
