import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
} from "antd";

import EditContentTags from "../../../components/viewEditContentManagement/EditContentTags/EditContentTags";
import {
  // AssetTagsGroup,
  DeleteModal,
  PageHeader,
  ShowAllTaxonomyTags,
  TableSkeleton,
} from "../../../components";
import { RootState } from "../../../store";
import { TFiltersType } from "../../../store/filtersFromTaxonomyData/filters.interface";
import { IaccessGroups } from "../../../store/accessGroup/accessGroup.interface";
import {
  accessGroupsInitialState,
  setAccessGroups,
} from "../../../store/accessGroup/accessGroupSlice";
import { getTaxonomyByTitle } from "../../../services/taxonomy";
import { mapDataToTreeSelect } from "../../../shared/taxonomyHelper";
import {
  CustomPagination,
  findParentsNodeNameById,
  isEmpty,
  TaxonomyTitle,
  UserRoles,
} from "../../../shared";
import "./AccessGroup.scss";

const AccessGroup = () => {
  const dispatch = useDispatch();

  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { isAccessGroupsLoading, accessGroups } = useSelector(
    (state: RootState) => state.accessGroup
  );

  const [addNew, setAddNew] = useState(false);
  const [groupList, setGroupList] = useState<IaccessGroups[]>([]);
  const [isTagsModal, setIsTagsModal] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowDiscardChanges, setIsShowDiscardChanges] = useState<boolean>(false);
  const [selectedAccessGroup, setSelectedAccessGroup] = useState<IaccessGroups | null>(null);
  const [selectedContentTitle, setSelectedContentTitle] = useState<string>(TFiltersType.Industry);

  const mappedContentIndustry = mapDataToTreeSelect(industryTaxonomy.taxonomyTree ?? []);
  const mappedContentFunction = mapDataToTreeSelect(functionTaxonomy.taxonomyTree ?? []);

  useEffect(() => {
    dispatch(
      setAccessGroups([
        {
          id: 0,
          title: "Group 1",
          role: UserRoles.User,
          industry: [],
          function: [],
          searchView: false,
          download: true,
          upload: true,
          edit: true,
          status: true,
        },
        {
          id: 1,
          title: "Group 2",
          role: UserRoles.Admin,
          industry: [],
          function: [],
          searchView: true,
          download: true,
          upload: true,
          edit: false,
          status: false,
        },
      ])
    );
    getTaxonomyByTitle(TaxonomyTitle.Industry);
    getTaxonomyByTitle(TaxonomyTitle.Function);
  }, []);

  useEffect(() => {
    setGroupList(accessGroups);
  }, [accessGroups]);

  const handleChangeIndustry = (selectedNodes: any[]) => {
    let ids: string[] = [];
    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node) => {
        ids.push(node?.value);
      });
    }

    handleChange({ target: { name: "industry", value: ids } });
  };

  const handleChangeFunction = (selectedNodes: any[]) => {
    let ids: string[] = [];
    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node) => {
        ids.push(node?.value);
      });
    }

    handleChange({ target: { name: "function", value: ids } });
  };

  const handleChange = ({
    target,
  }: {
    target: { checked?: boolean; name: string; value: string | string[] };
  }) => {
    let tempArray: IaccessGroups[] = [...groupList];
    let currentItemIndex: number = tempArray.findIndex((e) => e?.isNew || e?.isEdit);
    let currentItem = tempArray[currentItemIndex];
    const objKey = target?.name;

    if (!isEmpty(currentItem) && objKey in currentItem) {
      tempArray[currentItemIndex] = {
        ...tempArray[currentItemIndex],
        [objKey]: target?.checked
          ? true
          : typeof target?.value === "string"
          ? target?.value.toString()
          : typeof target?.value === "object"
          ? target?.value
          : false,
      };
    }
    setGroupList(tempArray);
  };

  const resetChanges = () => {
    setAddNew(false);
    let tempArray = [...accessGroups];
    tempArray = tempArray.map((item) => ({
      ...item,
      isEdit: false,
      isNew: false,
    }));
    dispatch(setAccessGroups(tempArray));
  };

  const resetChangeWithModalClose = () => {
    setIsShowDiscardChanges(false);
    resetChanges();
  };

  const handleSave = (record: IaccessGroups, index: number, confirm: boolean) => {
    if (isEmpty(groupList)) return;
    let tempArray = [...groupList];
    const currentItem = tempArray[index];
    const currentItemPrev = accessGroups[index];

    if (confirm) {
      if (!isEmpty(tempArray) && !currentItem?.title && !currentItem?.title?.trim()) {
        toast.error("Please enter name");
        return;
      }
      tempArray = tempArray.map((item) => ({
        ...item,
        isEdit: false,
        isNew: false,
      }));
      dispatch(setAccessGroups(tempArray));
    } else {
      if (record?.isNew) {
        tempArray.pop(); // use this only in case new Group and also need to add condition
      } else if (JSON.stringify(currentItem) !== JSON.stringify(currentItemPrev)) {
        setIsShowDiscardChanges(true);
        return;
      }
      resetChanges();
    }
    setAddNew(false);
  };

  const handleAddNewGroup = () => {
    setAddNew(true),
      setGroupList([...groupList, { ...accessGroupsInitialState, id: groupList.length }]);
  };

  const handleEdit = (record: IaccessGroups) => {
    const destructureList = [...groupList].map((item) => ({
      ...item,
      isEdit: item?.id === record?.id ? true : false,
    }));
    setGroupList(destructureList);
    dispatch(setAccessGroups(destructureList));
  };

  const handleDeleteBtn = (record: IaccessGroups, index: number) => {
    setSelectedAccessGroup(record);
    setIsShowDeleteModal(true);
    setSelectedIndex(index);
  };

  const handleStatusChange = (record: IaccessGroups) => {
    if (record?.isNew) return;
    const updatedStatus = accessGroups.map((v: IaccessGroups) =>
      v?.id === record?.id ? { ...v, status: !record?.status } : v
    );
    dispatch(setAccessGroups(updatedStatus));
  };

  const getParentsNodeNameBySelectIds = (linkedTagIds: number[]) => {
    let parentsNodeNameBySelectIds: string[] = [];

    if (linkedTagIds && linkedTagIds?.length) {
      linkedTagIds.forEach((id) => {
        const nodeNameById: string[] = findParentsNodeNameById(
          industryTaxonomy?.taxonomyTree ?? [],
          id
        );
        if (nodeNameById && nodeNameById?.length) {
          parentsNodeNameBySelectIds.push(...nodeNameById);
        } else {
          const assetTypeNodeNameById: string[] = findParentsNodeNameById(
            functionTaxonomy?.taxonomyTree ?? [],
            id
          );
          if (assetTypeNodeNameById && assetTypeNodeNameById?.length) {
            parentsNodeNameBySelectIds.push(...assetTypeNodeNameById);
          }
        }
      });
      parentsNodeNameBySelectIds = [...new Set(parentsNodeNameBySelectIds)];
    }

    return parentsNodeNameBySelectIds;
  };

  const renderTaxonomyTags = (key: string, data: any, type: string) => {
    if (!isEmpty(data)) {
      if (data?.length === 1) {
        return (
          <>
            <div className="item-tags item-tags-ui" key={data[0]}>
              <Space size={[0, 8]} wrap className="tags-span">
                {getParentsNodeNameBySelectIds([+data[0]])}
              </Space>
            </div>
          </>
        );
      }
      if (data?.length > 1) {
        return (
          <div className="item-tags item-tags-ui" key={key}>
            <Space size={[0, 8]} wrap className="tags-span">
              {data.slice(0, 1).map((item: any, index: number) => {
                console.log("item", item);
                return (
                  <p key={index} className="tags-p">
                    {getParentsNodeNameBySelectIds([+item])}
                  </p>
                );
              })}
              <span
                className="more-tag cursor-pointer"
                onClick={() => {
                  setSelectedAccessGroup(data.map((e: any) => getParentsNodeNameBySelectIds([+e])));
                  setIsTagsModal(key);
                  setSelectedContentTitle(type);
                }}>
                +more
              </span>
            </Space>

            <ShowAllTaxonomyTags
              isOpen={isTagsModal === key ? true : false}
              setModalClose={handleCloseTagModal}
              selectedContent={selectedAccessGroup}
              contentTitle={selectedContentTitle}
            />
          </div>
        );
      }
    } else {
      return "N/A";
    }
  };

  const handleCloseTagModal = () => {
    setIsTagsModal("");
    setSelectedAccessGroup(null);
  };

  const columns: TableProps["columns"] = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "auto",
      render: (text, record) => (
        <>
          <span className="td-label">Title : </span>
          {record?.isNew || record?.isEdit ? (
            <Input
              onChange={handleChange}
              name="title"
              value={record?.title ? record?.title : ""}
              // suffix={
              //   record.isEdit ? (
              //     <>
              //       <CheckOutlined className="saveIcon" onClick={() => updateData(true)} />
              //       <CloseOutlined onClick={() => updateData(false)} />
              //     </>
              //   ) : (
              //     <></>
              //   )
              // }
            />
          ) : (
            <strong>
              <u style={{ cursor: "pointer" }}> {text}</u>
            </strong>
          )}
        </>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "150px",
      render: (role: string, record: IaccessGroups) => (
        <>
          <span className="td-label">Role : </span>
          <div className="selectRole">
            <Select
              popupClassName="ui-dropdown-default ui-dropdown-select-role"
              value={role}
              style={{ width: 80 }}
              disabled={!record?.isEdit}
              onChange={(e) => handleChange({ target: { name: "role", value: e } })}
              // className="flat-select"
              options={[
                { value: "USER", label: "User" },
                { value: "CONTENT_EDITOR", label: "Editor" },
                { value: "ADMIN", label: "Admin" },
              ]}
            />
          </div>
        </>
      ),
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      render: (industry, record: IaccessGroups) => {
        return (
          <>
            <span className="td-label">Industry : </span>
            <div className="industry-column-group">
              {record.isEdit || record.isNew ? (
                <>
                  <label className="count-label" aria-disabled={industry?.length ? false : true}>
                    {industry?.length ? (
                      <>
                        Industry<span className="count">({industry?.length})</span>
                      </>
                    ) : (
                      "Choose"
                    )}
                  </label>
                  <EditContentTags
                    selectedTags={industry}
                    mappedContentTags={mappedContentIndustry}
                    popupClassName="ui-dropdown-default ui-dropdown-select-role ui-dropdown-checkbox-default"
                    placeholder=""
                    handleChange={handleChangeIndustry}
                    isNonLeafNodeDisable={false}
                    treeCheckStrictly={true}
                    allowClear={true}
                    popupMatchSelectWidth={false}
                  />
                </>
              ) : (
                // <AssetTagsGroup linkedTags={mappedContentIndustry} content={record} />
                renderTaxonomyTags(`${record?.id}-ind`, industry, TFiltersType.Industry)
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Function",
      dataIndex: "function",
      key: "function",
      render: (data, record) => (
        <>
          <span className="td-label">Function : </span>
          <div className="industry-column-group">
            {record.isEdit || record.isNew ? (
              <>
                <label className="count-label" aria-disabled={data?.length ? false : true}>
                  {data?.length ? (
                    <>
                      Function<span className="count">({data?.length})</span>
                    </>
                  ) : (
                    "Choose"
                  )}
                </label>
                <EditContentTags
                  selectedTags={data}
                  mappedContentTags={mappedContentFunction}
                  popupClassName="ui-dropdown-default ui-dropdown-select-role ui-dropdown-checkbox-default"
                  placeholder=""
                  handleChange={handleChangeFunction}
                  isNonLeafNodeDisable={false}
                  treeCheckStrictly={true}
                  allowClear={true}
                  popupMatchSelectWidth={false}
                />
              </>
            ) : (
              renderTaxonomyTags(`${record?.id}-fun`, data, TFiltersType.Functions)
            )}

            {/* <Select
            popupClassName="ui-dropdown-default ui-dropdown-select-role ui-dropdown-checkbox-default"
            //   value={user?.userPermissions[0].role}

            //   onChange={(e) => handleRoleStatus(e, user)}
            className="flat-select"
            defaultValue="Function"
            options={[
              { value: "level", label: "Lorem Ipsum" },
              { value: "level1", label: "Lorem Ipsum 1" },
              { value: "level2", label: "Lorem Ipsum 2" },
              { value: "level3", label: "Lorem Ipsum 3" },
              { value: "level4", label: "Lorem Ipsum 4" },
            ]}
            menuItemSelectedIcon={({ isSelected }) => (
              <span>
                {isSelected ? (
                  <Checkbox checked={true}></Checkbox>
                ) : (
                  <Checkbox checked={false}></Checkbox>
                )}
              </span>
            )}
          /> */}
          </div>
        </>
      ),
    },
    {
      title: "Search & View",
      dataIndex: "searchView",
      key: "searchView",
      width: "130px",
      align: "center",
      render: (searchview) => (
        <>
          <span className="td-label">Search & View : </span>
          <Checkbox
            name="searchView"
            onChange={(e) =>
              handleChange({ target: { name: "searchView", checked: e.target.checked, value: "" } })
            }
            checked={searchview}></Checkbox>
        </>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      //   width: "95px",
      width: "110px",
      align: "center",
      render: (download) => (
        <>
          <span className="td-label">Download : </span>
          <Checkbox
            name="download"
            onChange={(e) =>
              handleChange({ target: { name: "download", checked: e.target.checked, value: "" } })
            }
            checked={download}></Checkbox>
        </>
      ),
    },
    {
      title: "Upload",
      key: "upload",
      dataIndex: "upload",
      //   width: "78px",
      width: "110px",
      align: "center",
      render: (upload) => (
        <>
          <span className="td-label">Upload : </span>
          <Checkbox
            name="upload"
            onChange={(e) =>
              handleChange({ target: { name: "upload", checked: e.target.checked, value: "" } })
            }
            checked={upload}></Checkbox>
        </>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      width: "110px",
      align: "center",
      dataIndex: "edit",
      render: (edit) => (
        <>
          <span className="td-label">Edit : </span>
          <Checkbox
            name="edit"
            onChange={(e) =>
              handleChange({ target: { name: "edit", checked: e.target.checked, value: "" } })
            }
            checked={edit}
          />
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: "120px",
      dataIndex: "status",
      render: (status: boolean, record: IaccessGroups, index: number) => (
        <>
          <span className="td-label">Status : </span>
          <div className="switch-edit-action">
            <Switch checked={status} onChange={() => handleStatusChange(record)} />
            {record.isNew || record.isEdit ? (
              <>
                <CheckOutlined
                  className="saveIcon"
                  onClick={() => handleSave(record, index, true)}
                />
                <CloseOutlined
                  onClick={() => handleSave(record, index, false)}
                  className="closeIcon"
                />
              </>
            ) : (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Edit",
                      onClick: () => handleEdit(record),
                    },
                    {
                      key: "2",
                      label: "Delete",
                      onClick: () => handleDeleteBtn(record, index),
                    },
                  ],
                }}
                overlayStyle={{ width: 80 }}
                overlayClassName="table-action-dropdown table-action-dropdown-user"
                placement="bottomRight">
                <Button className="more-action">
                  <i className="ri-more-2-fill"></i>
                </Button>
              </Dropdown>
            )}
          </div>
        </>
      ),
    },
  ];

  const handlePagination = (_page: number) => {};

  const handleDeleteAccessGroup = () => {
    if (selectedIndex !== -1) {
      const values = [...accessGroups];
      values.splice(selectedIndex, 1);
      dispatch(setAccessGroups(values));
    }
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setIsShowDeleteModal(false);
    setSelectedIndex(-1);
  };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="Access Group"
          isRelevantAsset={false}
          content={
            <>
              <Input
                type="search"
                className="header-search"
                placeholder="Search by name or email..."
                prefix={<i className="ri-search-line"></i>}
                //   onChange={handleSearchDebounce}
              />
              <Button
                className="btn"
                type="default"
                disabled={addNew}
                icon={<i className="ri-add-circle-fill"></i>}
                onClick={handleAddNewGroup}>
                Add new group
              </Button>
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Row>
            <Col span={24}>
              <Card
                className={`global-table-card ${isEmpty(groupList) ? "global-table-empty" : ""}`}>
                {isAccessGroupsLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    className="global-table responsive-table access-group-table"
                    columns={columns}
                    dataSource={groupList}
                    tableLayout="fixed"
                    scroll={{ y: "calc(100vh - 188px)" }}
                    pagination={false}
                  />
                )}
                {!isEmpty(groupList) ? (
                  <CustomPagination
                    currentPage={10}
                    total={10}
                    pageSize={10}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </motion.div>
      </Flex>

      <DeleteModal
        isLoading={false}
        isModalOpen={isShowDeleteModal}
        handleCancel={handleCloseDeleteModal}
        handleSubmit={handleDeleteAccessGroup}
        message="Are you sure you want to delete this access group?"
        title="Delete Access Group"
      />

      {/* discard changes */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowDiscardChanges}
        handleCancel={() => setIsShowDiscardChanges(false)}
        handleSubmit={resetChangeWithModalClose}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Yes"
        cancelBtnName="No"
      />
    </>
  );
};

export default AccessGroup;
