import { useState } from "react";
import { Space } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { PATHS } from "../../constants";
import { getIsContentFailed, getIsDisableEditContent } from "../../helpers";
import { updateViewCount } from "../../../services/contentManagement";
import { SelectedTagModal } from "../../../components/viewEditContentManagement";
import { findNodeNameByValueForView, findParentNodesByValueForView } from "../../taxonomyHelper";
import {
  contentByIdInitialValue,
  contentFilterField,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateContentFilter,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";
import {
  IContent,
  IFormattedIndustry,
  ITagsByAssestId,
} from "../../../store/contentManagement/content.interface";

interface IAssetTagsProps {
  linkedTags: ITagsByAssestId;
  content: IContent;
  fromWhere?: string;
}

const AssetTags = (props: IAssetTagsProps) => {
  const { linkedTags, content, fromWhere = "" } = props;

  const [isTagsModal, setIsTagsModal] = useState<number>(-1);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);

  const { contentFilter } = useSelector((state: RootState) => state.content);
  const currentPage = contentFilter?.currentPage ?? 1;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedIndustryIds = linkedTags?.linkedTaxonomyNodes?.Industry || [];
  const selectedFunctionIds = linkedTags?.linkedTaxonomyNodes?.Function || [];
  const ids = [...selectedIndustryIds, ...selectedFunctionIds];
  const formattedTags: IFormattedIndustry[] = [];

  if (ids && ids.length) {
    ids.forEach((value: number) => {
      const strValue = String(value);
      formattedTags.push({
        parents: findParentNodesByValueForView(value, linkedTags.taxonomyTree),
        value: strValue,
        id: strValue,
        title: findNodeNameByValueForView(linkedTags.taxonomyTree, value),
      });
    });
  }

  const handleCloseTagModal = () => {
    setIsTagsModal(-1);
    setSelectedContent(null);
  };

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: getIsContentFailed(content),
        fromWhere,
      },
    });

    updateViewCount(assetId);
  };

  const renderMore = () => {
    const pointerClass = "cursor-pointer";
    return (
      <span
        className={`more-tag ${
          !getIsDisableEditContent(content) ? pointerClass : "cursor-default"
        }`}
        onClick={() => handleContentDetail(content)}>
        +more
      </span>
    );
  };

  return (
    <>
      <span className="td-label">Tags: </span>
      {formattedTags && formattedTags.length ? (
        formattedTags.slice(0, 1).map((industry: IFormattedIndustry, industryIdx: number) => {
          if (industry.parents && industry.parents.length) {
            if (industry.parents.length === 1) {
              return (
                <div className="item-tags item-tags-ui" key={industryIdx}>
                  <Space size={[0, 8]} className="tags-span" align="center">
                    {industry.parents.slice(0, 1).map((indParent: any, indParentIdx: number) => {
                      return (
                        <>
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName} {indParentIdx === 0 && <RightOutlined />}
                          </p>
                          <p key={indParentIdx} className="tags-p">
                            {industry.title}
                          </p>
                        </>
                      );
                    })}
                    {renderMore()}
                    {content?.asset_id === isTagsModal && (
                      <SelectedTagModal
                        isOpen={content?.asset_id === isTagsModal}
                        setModalClose={handleCloseTagModal}
                        selectedContent={selectedContent}
                      />
                    )}
                  </Space>
                </div>
              );
            } else {
              return (
                <div className="item-tags item-tags-ui" key={industryIdx}>
                  <Space size={[0, 8]} wrap className="tags-span">
                    {industry.parents.slice(0, 2).map((indParent: any, indParentIdx: number) => {
                      return (
                        <div className="text-icon-right d-flex d-flex-middle">
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName}
                          </p>
                          {indParentIdx === 0 && <RightOutlined />}
                        </div>
                      );
                    })}
                    {renderMore()}
                    {content?.asset_id === isTagsModal && (
                      <SelectedTagModal
                        isOpen={content?.asset_id === isTagsModal}
                        setModalClose={handleCloseTagModal}
                        selectedContent={selectedContent}
                      />
                    )}
                  </Space>
                </div>
              );
            }
          } else {
            return (
              <div className="item-tags item-tags-ui" key={industryIdx}>
                <Space size={[0, 8]} wrap className="tags-span">
                  {industry.title}
                  {renderMore()}
                  {content?.asset_id === isTagsModal && (
                    <SelectedTagModal
                      isOpen={content?.asset_id === isTagsModal}
                      setModalClose={handleCloseTagModal}
                      selectedContent={selectedContent}
                    />
                  )}
                </Space>
              </div>
            );
          }
        })
      ) : (
        <div className="no-record">N/A</div>
      )}
    </>
  );
};

export default AssetTags;
