import { useState, useRef, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Card,
  Divider,
  Button,
  Progress,
  Checkbox,
  GetProp,
  Tooltip,
  Select,
  CheckboxProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { RootState } from "../../store";
import { setFileUploadProgress } from "../../store/contentManagement/contentSlice";
import { uploadAsset } from "../../services/contentManagement";
import {
  AiOptions,
  FileLocation,
  PATHS,
  IMAGES,
  RunVisionFileType,
  SurveyFileType,
  UploadAssetState,
  convertToNumber,
  findParentsNodeNameById,
  getUserId,
  isEmpty,
  IUploadFormProps,
} from "../../shared";
import { mapDataToTreeSelect } from "../../shared/taxonomyHelper";
import EditContentTags from "../viewEditContentManagement/EditContentTags/EditContentTags";
import "./UploadAssets.scss";

interface IUploadAssets {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSelectedFiles?: (file: File) => void;
  fromWhere: string;
  toWhere?: string;
}

const UploadAssets = ({
  isModalOpen,
  setIsModalOpen,
  fromWhere,
  toWhere = PATHS.myContent,
}: IUploadAssets) => {
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [selectedIndustriesTag, setSelectedIndustriesTag] = useState<string[]>([]);
  const [selectedFunctionsTag, setSelectedFunctionsTag] = useState<string[]>([]);

  const [isShowRunVision, setIsShowRunVision] = useState<boolean>(false);
  const [runVision, setRunVision] = useState<boolean>(false);
  const [isShowAutoSummarize, setIsShowAutoSummarize] = useState<boolean>(false);
  const [autoSummarize, setAutoSummarize] = useState<boolean>(false);
  const [autoTagTaxonomy, setAutoTagTaxonomy] = useState<boolean>(false);

  console.log(fromWhere, autoTagTaxonomy); // remove in the end

  const [fileType, setFileType] = useState<string>("");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { industryTaxonomy, assetTypeTaxonomy, functionTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const { fileUploadProgress } = useSelector((state: RootState) => state.content);

  const [uploadingState, setUploadingState] = useState<UploadAssetState>(UploadAssetState.INITIAL);
  const [fileLocation, setFileLocation] = useState<FileLocation>(FileLocation.ORGANISATION);
  const [isShowSurvey, setIsShowSurvey] = useState<boolean>(false);
  const [survey, setSurvey] = useState<boolean>(false);
  const [aiSelectedValues, setAiSelectedValues] = useState<any[]>([]);

  const aiOptions = [
    {
      label: "Auto Summarize",
      value: AiOptions.SUMMARIZE,
      icon: IMAGES.icon1,
      tooltip: "Automatically generate concise summaries of your uploaded documents.",
      disabled: !isShowAutoSummarize,
    },
    {
      label: "AutoTag Taxonomy",
      value: AiOptions.AUTO_TAG_TAXONOMY,
      icon: IMAGES.icon2,
      tooltip:
        "Automatically assign relevant tags and categories to your content for better organization.",
      disabled: true,
    },
    {
      label: "Process using Vision Models",
      value: AiOptions.VISION_MODEL,
      icon: IMAGES.icon3,
      tooltip: "Analyze images and extract meaningful information using advanced vision AI models.",
      disabled: !isShowRunVision,
    },
  ];

  const mappedContentIndustries = mapDataToTreeSelect(industryTaxonomy?.taxonomyTree ?? []);
  const mappedContentFunctions = mapDataToTreeSelect(functionTaxonomy?.taxonomyTree ?? []);
  const mappedAssetType = assetTypeTaxonomy?.taxonomyTree
    .filter((item) => item.taxonomyNodeName)
    .map((item) => ({
      label: item.taxonomyNodeName,
      value: String(item.taxonomyNodeId),
    }));

  const handleClose = () => {
    form.resetFields();
    setFileName("");
    setIsModalOpen(false);
    setFile(null);
    handleRemoveFile();
    setRunVision(false);
    setFileType("");
    setIsShowRunVision(false);
    setUploadingState(UploadAssetState.INITIAL);
    setIsShowSurvey(false);
    setSurvey(false);
    setIsShowAutoSummarize(false);
    setAutoSummarize(false);
    setAutoTagTaxonomy(false);
    setFileLocation(FileLocation.ORGANISATION);
    setAiSelectedValues([]);
    setSelectedIndustriesTag([]);
    setSelectedFunctionsTag([]);
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setRunVision(false);
    setIsShowRunVision(false);

    if (e.target.files) {
      const file = e.target.files[0];
      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);

      setIsShowRunVision(RunVisionFileType.includes(fileType));
      setFileType(fileType);
      setFile(file);
      setFileName(file.name);
      setUploadingState(UploadAssetState.FILE);
      setIsShowSurvey(SurveyFileType.includes(fileType));
      setIsShowAutoSummarize(true);
    }
  };

  const handleRemoveFile = () => {
    // form.resetFields();
    setUploadingState(UploadAssetState.INITIAL);
    setFileName("");
    setIsShowRunVision(false);
    setRunVision(false);
    setIsShowSurvey(false);
    setSurvey(false);
    setAiSelectedValues([]);
    // setAutoTagTaxonomy(false);
    setFileLocation(FileLocation.ORGANISATION);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const getParentsNodeNameBySelectIds = (linkedTagIds: number[]) => {
    let parentsNodeNameBySelectIds: string[] = [];

    if (linkedTagIds && linkedTagIds?.length) {
      linkedTagIds.forEach((id) => {
        const nodeNameById: string[] = findParentsNodeNameById(
          industryTaxonomy?.taxonomyTree ?? [],
          id
        );
        if (nodeNameById && nodeNameById?.length) {
          parentsNodeNameBySelectIds.push(...nodeNameById);
        } else {
          const assetTypeNodeNameById: string[] = findParentsNodeNameById(
            assetTypeTaxonomy?.taxonomyTree ?? [],
            id
          );
          if (assetTypeNodeNameById && assetTypeNodeNameById?.length) {
            parentsNodeNameBySelectIds.push(...assetTypeNodeNameById);
          }
        }
      });
      parentsNodeNameBySelectIds = [...new Set(parentsNodeNameBySelectIds)];
    }

    return parentsNodeNameBySelectIds;
  };

  const onFinish = async () => {
    const values = form.getFieldsValue();

    const industriesTag = !isEmpty(selectedIndustriesTag)
      ? convertToNumber(selectedIndustriesTag)
      : [];

    const functionsTag = !isEmpty(selectedFunctionsTag)
      ? convertToNumber(selectedFunctionsTag)
      : [];

    let linkedTagIds = [...industriesTag, ...functionsTag];

    if (values?.assetType) {
      linkedTagIds.push(Number(values?.assetType));
    }
    const tags = getParentsNodeNameBySelectIds(linkedTagIds);

    setProceedLoading(true);
    setIsUploading(true);
    dispatch(setFileUploadProgress(0));

    if (!!file) {
      const formData = new FormData();
      formData.append("file", file);

      const formParams: IUploadFormProps = {
        formData,
        userId: getUserId(),
        title: !isEmpty(values?.title) ? values?.title : "",
        summary: !isEmpty(values?.summary) ? values?.summary : "",
        fileType: fileType,
        fileLevel: fileLocation,
        visionProcessing: runVision,
        isSurveyData: survey,
        generateSummary: autoSummarize,
        linkedTagIds,
        tags,
      };

      await uploadAsset(formParams, navigate, setUploadingState, "", toWhere);

      setIsModalOpen(false);
    } else {
      setUploadingState(UploadAssetState.FAILED);
      toast.error("Failed to upload asset");
    }
    setIsUploading(false);
    setProceedLoading(false);
    handleRemoveFile();
    handleClose();
  };

  const renderAsserUploadingState = (fileName: string, fileUploadProgress: number) => {
    return (
      <div className="uploading-field">
        <p className="upload-status">
          {uploadingState === UploadAssetState.FILE
            ? "Selected file"
            : fileUploadProgress < 100
            ? "Uploading..."
            : "Uploaded"}
        </p>
        <div className="file-container">
          <span>
            <i className="ri-attachment-line browseIcon"></i>
            <span>{fileName}</span>
          </span>
          {uploadingState === UploadAssetState.FILE ? (
            <div className="btn-wrapper">
              <Button type="text" className="text-btn remove-file" onClick={handleRemoveFile}>
                <i className="ri-delete-bin-fill"></i>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {uploadingState === UploadAssetState.UPLOADING ? (
            <Progress percent={fileUploadProgress} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const fileLocationChange: CheckboxProps["onChange"] = (e) => {
    if (e?.target?.checked === true) {
      setFileLocation(FileLocation.USER);
    } else {
      setFileLocation(FileLocation.ORGANISATION);
    }
  };

  const handleChangeIndustry = (selectedNodes: string[]) => {
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setSelectedIndustriesTag(ids);
  };

  const handleChangeFunction = (selectedNodes: string[]) => {
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setSelectedFunctionsTag(ids);
  };

  const aiOptionsChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues: any[]) => {
    setAiSelectedValues(checkedValues);
    checkedValues.includes(AiOptions.SUMMARIZE) ? setAutoSummarize(true) : setAutoSummarize(false);
    checkedValues.includes(AiOptions.AUTO_TAG_TAXONOMY)
      ? setAutoTagTaxonomy(true)
      : setAutoTagTaxonomy(false);
    checkedValues.includes(AiOptions.VISION_MODEL) ? setRunVision(true) : setRunVision(false);
  };

  const renderFileUpload = () => {
    return (
      <Form
        form={form}
        name="uploadFile"
        initialValues={{
          file: null,
          title: "",
          assetType: null,
          summary: "",
          industrys: [],
          functions: [],
          aiOptions: [],
        }}
        onFinish={onFinish}
        requiredMark={true}
        size="large"
        className="form-container add-user-form">
        <Card className="input-card">
          <p className="input-label">Upload File</p>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple={false}
              placeholder="Upload Asset"
              onChange={handleFileUpload}
            />
            <div className="upload-file-container">
              <div className="file-name">
                <span>
                  <i className="ri-attachment-line browseIcon"></i>
                  <span>Browse</span>
                </span>
              </div>

              <Button
                onClick={handleClickUploadFile}
                className="btn-primary fill-button btn-type2"
                type="primary"
                size="middle"
                disabled={isUploading}>
                Browse
              </Button>
            </div>
          </div>

          <div className="file-upload-extra">
            <Checkbox
              checked={survey}
              disabled={!isShowSurvey || isUploading}
              onChange={(e) => setSurvey(e.target.checked)}>
              Select if uploading survey data
            </Checkbox>
            <Divider className="extra-divider" />

            {uploadingState === UploadAssetState.FILE ||
            uploadingState === UploadAssetState.UPLOADING ||
            uploadingState === UploadAssetState.UPLOADED ? (
              <>
                {renderAsserUploadingState(fileName, fileUploadProgress)}
                <Checkbox
                  checked={fileLocation === FileLocation.USER}
                  onChange={fileLocationChange}
                  className="margin-bottom">
                  Only for me
                </Checkbox>
              </>
            ) : (
              ""
            )}

            <Form.Item
              label="Title"
              rules={[{ required: true, message: "Please enter Title" }]}
              name="title"
              labelCol={{ span: 24 }}>
              <Input placeholder="Add Title" autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Type"
              rules={[{ required: true, message: "Please select Type/s" }]}
              name="assetType"
              labelCol={{ span: 24 }}>
              <Select
                showSearch
                popupClassName="ui-dropdown-default"
                placeholder="Select Type/s"
                className="ant-select-lg"
                filterOption={(inputValue: string, option: any) =>
                  option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }>
                {mappedAssetType &&
                  mappedAssetType?.map((v) => {
                    return (
                      <Select.Option key={v?.value} value={v?.value} label={v?.label}>
                        <div>{v?.label}</div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item label="Summary" name="summary" labelCol={{ span: 24 }}>
              <TextArea placeholder="Optional" autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Taxonomy(s)"
              name="industrys"
              labelCol={{ span: 24 }}
              className="select-tags-input-wrap margin-small">
              <EditContentTags
                maxTagCountnumber={3}
                selectedTags={selectedIndustriesTag}
                mappedContentTags={mappedContentIndustries}
                popupClassName="ui-choose-select"
                placeholder="Select Industry"
                handleChange={handleChangeIndustry}
                className="select-dropdown-multiple"
                isNonLeafNodeDisable={false}
                treeCheckStrictly={true}
              />
            </Form.Item>

            <Form.Item name="functions" labelCol={{ span: 24 }} className="select-tags-input-wrap">
              <EditContentTags
                maxTagCountnumber={3}
                selectedTags={selectedFunctionsTag}
                mappedContentTags={mappedContentFunctions}
                popupClassName="ui-choose-select"
                placeholder="Select Function"
                handleChange={handleChangeFunction}
                className="select-dropdown-multiple"
                isNonLeafNodeDisable={false}
                treeCheckStrictly={true}
              />
            </Form.Item>

            <Form.Item name="aiOptions" labelCol={{ span: 24 }} className="ai-action-items">
              <div className="input-label dark">Perform AI Actions:</div>
              <Checkbox.Group
                className="ai-options"
                value={aiSelectedValues}
                onChange={aiOptionsChange}>
                {aiOptions.map((item, index: number) => (
                  <Checkbox key={index} {...item}>
                    <img src={item.icon} className="ai-check-icon" alt="Icon" />
                    {item.label}
                    <Tooltip title={item.tooltip}>
                      <i className="ri-information-line"></i>
                    </Tooltip>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>

            {/* <div className="form-item ai-action-items">
              <div className="input-label dark">Perform AI Actions:</div>
              <Checkbox.Group
                className="ai-options"
                value={aiSelectedValues}
                onChange={aiOptionsChange}>
                {aiOptions.map((item, index: number) => (
                  <Checkbox key={index} {...item}>
                    <img src={item.icon} className="ai-check-icon" alt="Icon" />
                    {item.label}
                    <Tooltip title={item.tooltip}>
                      <i className="ri-information-line"></i>
                    </Tooltip>
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </div> */}
          </div>
        </Card>
        <div className="ant-modal-footer">
          <Button onClick={handleClose} className="btn" type="default" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={proceedLoading}
            htmlType="submit"
            className="btn"
            type="primary"
            disabled={
              uploadingState === UploadAssetState.INITIAL ||
              uploadingState === UploadAssetState.FAILED
            }>
            Proceed
          </Button>
        </div>
      </Form>
    );
  };

  // const renderOR = () => {
  //   return (
  //     <Divider className="divider">
  //       <p className="text-or">OR</p>
  //     </Divider>
  //   );
  // };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleClose}
      title="Upload Asset"
      centered
      width={500}
      className="asset-upload-form-container"
      footer={false}>
      <div className="container upload-asset-modal">
        <div>{renderFileUpload()}</div>
      </div>
    </Modal>
  );
};

export default UploadAssets;
