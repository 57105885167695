import { toast } from "react-toastify";
import { get, post } from "./apiClients";

export const getAllSources = async () => {
  try {
    const res = await get(`/core/sharepoint/all-source`);
    console.log(res);
  } catch (error) {
    console.log(error);
    return error;
  } finally {
  }
};

export const sharepointConnect = async (request: {
  username: string;
  password: string;
  domain: string;
}) => {
  try {
    const res = await post("/core/sharepoint/token", request);

    console.log(res);
  } catch (error: any) {
    console.log("login Error", error?.error);
    toast.error(error?.error || "Failed to connect");
  } finally {
  }
};

export const getSourcesById = async (sourceId: number) => {
  try {
    const res = await get(`/core/sharepoint/source/${sourceId}`);
    console.log(res);
  } catch (error) {
    console.log(error);
    return error;
  } finally {
  }
};
