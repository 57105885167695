import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "../../images";
import { setAppLayoutview } from "../../../store/appState/appSlice";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import "./ChatFloatingBtn.scss";
import { AppLayout } from "../../constants";

const ChatFloatingBtn = () => {
  const dispatch = useDispatch();
  const { appLayoutView } = useSelector((state: RootState) => state.app);

  const [bodyClass, setBodyClass] = useState(appLayoutView || AppLayout.MINIMIZE);

  useEffect(() => {
    document.body.classList.add(bodyClass);
    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [bodyClass]);

  const toggleChat = () => {
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    setBodyClass(AppLayout.MINIMIZE);
  };

  return appLayoutView !== AppLayout.MINIMIZE ? (
    <Button type="text" id="chatToggle" onClick={toggleChat}>
      <img src={IMAGES.waveImage} alt="Wave" className="wave" />
      <span className="wrap">
        <img src={IMAGES.brandIcon} alt="Ocktogen AI" className="octopus" />
      </span>
    </Button>
  ) : (
    ""
  );
};

export default ChatFloatingBtn;
