import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAppSliceProps, IAppearanceProps, IAssetCount } from "./app.interface";
import { PURGE } from "redux-persist";
import { Layout, ViewType } from "../../shared";

const initialAssetCount: IAssetCount = {
  assets_contributed: 0,
  download_count: 0,
  draft_assets: 0,
  view_count: 0,
};

const initialState: IAppSliceProps = {
  appearance_loading: false,
  appLayoutView: "minimize-chat-page",
  routeHistory: [],
  appearance: {
    logo: {
      lg: "",
      sm: "",
      text: "",
    },
    primary_color: "",
    secondary_color: "",
  },
  assetCount: initialAssetCount,
  isAssetCountLoading: false,
  layout: Layout.User,
  viewType: ViewType.GRID,
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAppearanceLoading: (state, action: PayloadAction<boolean>) => {
      state.appearance_loading = action.payload;
    },
    setAppearance: (state, action: PayloadAction<IAppearanceProps>) => {
      state.appearance = action.payload;
    },
    setAppLayoutview: (state, action: PayloadAction<string>) => {
      state.appLayoutView = action.payload;
    },
    setRoutHitsory: (state, action: PayloadAction<string[]>) => {
      state.routeHistory = action.payload || [];
    },

    setAssetCount: (state, action: PayloadAction<IAssetCount>) => {
      state.assetCount = action.payload;
    },
    setIsAssetCountLoading: (state, action: PayloadAction<boolean>) => {
      state.isAssetCountLoading = action.payload;
    },

    setIncViewCount: (state) => {
      state.assetCount = { ...state.assetCount, view_count: state?.assetCount?.view_count + 1 };
    },
    setIncDownloadCount: (state) => {
      state.assetCount = {
        ...state.assetCount,
        download_count: state?.assetCount?.download_count + 1,
      };
    },
    setUpdateContributedCount: (state, action: PayloadAction<number>) => {
      state.assetCount = { ...state.assetCount, assets_contributed: action.payload };
    },

    setUpdateLayout: (state, action: PayloadAction<string>) => {
      state.layout = action.payload;
    },
    setViewType: (state, action: PayloadAction<ViewType>) => {
      state.viewType = action.payload;
    },
  },
});

export const {
  setAppearanceLoading,
  setAppearance,
  setAppLayoutview,
  setRoutHitsory,
  setAssetCount,
  setIsAssetCountLoading,
  setIncViewCount,
  setIncDownloadCount,
  setUpdateContributedCount,
  setUpdateLayout,
  setViewType,
} = appSlice.actions;

export default appSlice.reducer;
