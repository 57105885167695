import { Link, useLocation, useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { Button, Menu, MenuProps, Popover } from "antd";
import { toast } from "react-toastify";
import { persistStore } from "redux-persist";
import { useSelector, useDispatch } from "react-redux";

import HeadLogo from "../HeadLogo/HeadLogo";
import { RootState, store } from "../../store";
import { setIsOpenChatHistory } from "../../store/conversationalSearch/conversationalSearchSlice";
import { setAppLayoutview, setRoutHitsory, setUpdateLayout } from "../../store/appState/appSlice";
import {
  clearAdminContentFilter,
  clearContentFilter,
  clearSourceFilter,
} from "../../store/contentManagement/contentSlice";
import { setOldAppearance } from "../../services/appAppearanceApi";
import { AppLayout, Layout, PATHS, UserRoles, getLocalStorageItem } from "../../shared";
import "./SideBar.scss";

type MenuItem = Required<MenuProps>["items"][number];
interface IAdminSideBar {
  sideBarItems: MenuItem[];
}

const SideBar: FC<IAdminSideBar> = ({ sideBarItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { appearance, routeHistory } = useSelector((state: RootState) => state.app);
  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // toggleMenu
  const [currentActive, setCurrentActive] = useState<string[]>([pathname]);

  useEffect(() => {
    upadteRouteHitsory();
  }, [pathname]);

  const upadteRouteHitsory = () => {
    setCurrentActive([pathname]);

    if (routeHistory?.length) {
      if (
        pathname.includes(PATHS.viewEditContentManagement) ||
        pathname.includes(PATHS.adminTaxonomyOverview)
      ) {
        const active = routeHistory[0] !== location.pathname ? routeHistory[0] : routeHistory[1];
        setCurrentActive([active]);
      }
    }

    if (!routeHistory?.length || routeHistory[0] !== location.pathname) {
      if (
        document.body.classList.contains("relevant-assets-page") &&
        location.pathname !== PATHS.relevantAssets
      )
        document.body.classList.remove("relevant-assets-page");
      const newPaths = [location.pathname, ...(routeHistory ? routeHistory : [])];
      if (newPaths.length > 5) {
        newPaths.pop(); // remove the oldest path to keep the array length to 5
      }
      dispatch(setRoutHitsory(newPaths));
    }
  };

  // const checkIsActive = (path: string): boolean => {
  //   if (
  //     path === pathname ||
  //     (pathname.includes(PATHS.viewEditContentManagement) &&
  //       routeHistory?.length > 1 &&
  //       routeHistory[1] === path)
  //   )
  //     return true;
  //   return false;
  // };

  const handlePath = (path: string) => {
    if (path === PATHS.history) {
      dispatch(setIsOpenChatHistory(true));
    } else navigate(path);
  };

  const handleLogout = () => {
    toast.success("Logout Successfully");
    persistStore(store).purge();
    localStorage.removeItem("user_foundationKM");
    localStorage.removeItem("token_foundationKM");
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    setOldAppearance(appearance);
    navigate(PATHS.login);
  };

  const renderAdminBtn = () => {
    const btnIcon: string = pathname.includes(PATHS.admin)
      ? "ci ci-user-management"
      : "ci ci-team-management";
    const btnText: string = pathname.includes(PATHS.admin) ? "User" : "Admin";
    const btnPath: PATHS = pathname.includes(PATHS.admin) ? PATHS.home : PATHS.adminAccessGroup;
    const newLayout: string = pathname.includes(PATHS.admin) ? Layout.User : Layout.Admin;

    const handleBtnClick = () => {
      handlePath(btnPath);
      dispatch(setUpdateLayout(newLayout));
    };

    return (
      <Button icon={<i className={btnIcon}></i>} className="admin-menu" onClick={handleBtnClick}>
        {btnText}
      </Button>
    );
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle("sidebar-filter-open"); // Toggle the class on body tag
  };

  // AI Assistant Button End
  const handleClickSidebarItem: MenuProps["onClick"] = (e: any) => {
    if (e?.key !== pathname) {
      handlePath(e?.key);
      dispatch(clearContentFilter());
      dispatch(clearSourceFilter());
      dispatch(clearAdminContentFilter());
      dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    }
  };

  return (
    <div id="sidebar" className="sidebar">
      <span className="sidebar-overlay" onClick={toggleMenu}></span>
      <div className="sidebar-wrap">
        <div className="mobileView toggle-click" onClick={toggleMenu}>
          <div className="arrow-list">
            <i className="ri-menu-2-fill"></i>
          </div>
          {/* Admin Menu */}
        </div>
        <div className="top-bar">
          <div className="admin-logo d-flex d-flex-center d-flex-middle">
            <div className="cursor-pointer" onClick={() => navigate(PATHS.home)}>
              <HeadLogo type="sm" />
            </div>
          </div>
          <div className="side-nav-wrap">
            <ul hidden>
              {/* <li className="side-menu-item ai-assistant-item">
                  <div className="ai-assistant-content">
                    <img className="logo-img" src="/assets/images/ai-assistence.svg" alt="Icon" />
                    <div className="side-nav-text">AI Assistant</div>
                  </div>
                  <div className="ai-assistant-button d-flex">
                    <Button
                      type="text"
                      onClick={() => handleClassChange("minimize-chat-page")}
                      className={bodyClass === "minimize-chat-page" ? "active" : ""}
                      icon={<i className="ci ci-minimize"></i>}></Button>
                    <Button
                      type="text"
                      onClick={() => handleClassChange("full-chat-page")}
                      className={bodyClass === "full-chat-page" ? "active" : ""}
                      icon={<i className="ci ci-fullscreen"></i>}></Button>
                    <Button
                      type="text"
                      onClick={() => handleClassChange("close-chat-page")}
                      className={bodyClass === "close-chat-page" ? "active" : ""}
                      icon={<i className="ci ci-cancel-circle"></i>}></Button>
                  </div>
                </li> */}

              {/* {sideBarItems.map((item: IsideBarItems, index: number) => (
                <motion.li
                  key={index}
                  className={
                    checkIsActive(item?.path) ? "active-menus side-menu-item" : "side-menu-item"
                  }
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: index * 0.12 }}
                  onClick={() => handleClickSidebarItem(item.path)}>
                  <img className="logo-img" src={item.icon} alt="Icon" />
                  <div className="side-nav-text">{item.title}</div>
                </motion.li>
              ))} */}
            </ul>
            <Menu
              rootClassName="pop-menu-wrap"
              selectedKeys={currentActive}
              items={sideBarItems}
              onClick={handleClickSidebarItem}></Menu>
          </div>
        </div>
        <div className="bottom-menu d-flex d-flex-center  d-flex-wrap">
          {userDetails.userRole === UserRoles.Admin && renderAdminBtn()}
          <Popover
            overlayClassName="account-setting-menu"
            content={
              <ul className="account-setting-content">
                <li>
                  <Link to={PATHS.resetPassword}>
                    <Button type="text">Reset Password</Button>
                  </Link>
                </li>
                <li>
                  <Button type="text" onClick={() => handleLogout()}>
                    Logout
                  </Button>
                </li>
              </ul>
            }
            trigger="click"
            open={open}
            placement="leftBottom"
            rootClassName="popover-menu-list"
            onOpenChange={() => setOpen(!open)}>
            <div className="user-icon" onClick={() => setOpen(true)}>
              <i className="ri-user-fill"></i>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
