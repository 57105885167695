import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button, Card, Checkbox, Col, Dropdown, Flex, MenuProps, Row, Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentActionButton,
  DeleteModal,
  FileCard,
  PageHeader,
  TopicAbout,
} from "../../components";
import { RootState } from "../../store";
import {
  AssetUploadStatus,
  BookmarkIcon,
  FileCardType,
  PATHS,
  PageLimit,
  getIsContentFailed,
  getIsDisableEditContent,
  getIsShowRunVision,
  isEmpty,
} from "../../shared";
import {
  contentByIdInitialValue,
  contentFilterField,
  setAllContentDetails,
  setContentById,
  setSelectContentDetails,
  setSelectedAllTagsByAssestId,
  setSelectionAppliedForSources,
  setUpdateContentFilter,
  tagsByAssestIdInitValue,
  updateAssetByAssetId,
} from "../../store/contentManagement/contentSlice";
import {
  bookmarkContent,
  deleteAssetById,
  getAllContent,
  getAssetDetailsByAssetId,
  runVision,
  selectContent,
  stopProcessing,
  updateViewCount,
} from "../../services/contentManagement";
import { IContent } from "../../store/contentManagement/content.interface";
import "./TopicPage.scss";

const TopicPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    contents,
    isContentTableLoading,
    contentFilter,
    contentDeleteLoading,
    totalContentsCount,
    contentFilesInContext,
  } = useSelector((state: RootState) => state.content);

  const currentPage = contentFilter?.currentPage ?? 1;

  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);

  let contentInterval: any;

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (!isEmpty(contents)) {
        contents.forEach((content) => {
          if (
            [
              String(AssetUploadStatus.PROCESSING),
              AssetUploadStatus.SENT_TO_LLM,
              AssetUploadStatus.UPLOADED_TO_DO,
            ].includes(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  const handleDeleteContent = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        true,
        contentFilter,
        "content"
      );
      if (res?.data) {
        handleUpdatePageOnDelete();
      }
    }
    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    const filteredContext = contentFilesInContext.filter(
      (c) => selectedContent?.asset_id !== c.asset_id
    );
    selectContent(
      filteredContext.map((c) => c.asset_id),
      true
    );
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
  };

  useEffect(() => {
    getAllContent(contentFilter);
  }, []);

  const handleUpdatePageOnDelete = () => {
    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      // handleSetSearchParams(String(currentPage - 1), searchText);
    }
  };

  const handleSelectAllContents = (e: any) => {
    if (!isEmpty(contents)) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,
      }));
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const getEditableContents = () => {
    let editableContents: number[] = [];
    if (!isEmpty(contents)) {
      editableContents = contents
        .filter((item) => !getIsDisableEditContent(item))
        .map((item) => item?.asset_id);
    }
    return editableContents || [];
  };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (!isEmpty(contents)) {
      selectedContents = contents
        .filter((item) => !getIsDisableEditContent(item) && item?.isSelected)
        .map((item) => item?.asset_id);
    }
    return selectedContents || [];
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    const content = contents.find((e) => e.asset_id === assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: { isEditMode, isReadOnly: content ? getIsContentFailed(content) : false },
    });

    updateViewCount(assetId);
  };

  const getRunVisionLabel = (content: IContent) => {
    let label = "Run Vision";

    if (content?.visionParsed) {
      if (
        [
          String(AssetUploadStatus.UPLOADED_TO_DO),
          AssetUploadStatus.PROCESSING,
          AssetUploadStatus.SENT_TO_LLM,
        ].includes(content?.assetStatus)
      ) {
        label = "Run Vision Processing";
      } else if (content?.assetStatus === AssetUploadStatus.COMPLETED) {
        label = "Run Vision Completed";
      } else if (content?.assetStatus === AssetUploadStatus.FAILED) {
        label = "Run Vision Failed";
      }
    }
    return label;
  };

  const getIsDisableRunVision = (content: IContent) => {
    let isDisable = true;

    if (content?.visionParsed === false && content?.assetStatus === AssetUploadStatus.COMPLETED) {
      isDisable = false;
    }
    return isDisable;
  };

  const handleRunVision = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      toast.success("Vision is running");
      const res = await runVision(content?.asset_id);

      if (res?.data?.visionParsed) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.SENT_TO_LLM,
          visionParsed: true,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleStopProcessing = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      const res = await stopProcessing(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.STOPPING,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const getIsShowStopBtn = (content: IContent) =>
    [
      String(AssetUploadStatus.UPLOADED_TO_DO),
      AssetUploadStatus.PROCESSING,
      AssetUploadStatus.SENT_TO_LLM,
      AssetUploadStatus.CONVERTED_TO_PDF,
    ].includes(content?.assetStatus);

  const getStopBtnLabel = (content: IContent) => {
    let label = "";

    if (
      [
        String(AssetUploadStatus.UPLOADED_TO_DO),
        AssetUploadStatus.PROCESSING,
        AssetUploadStatus.SENT_TO_LLM,
        AssetUploadStatus.CONVERTED_TO_PDF,
      ].includes(content?.assetStatus)
    ) {
      label = "Stop";
    } else if (AssetUploadStatus.STOPPING === content?.assetStatus) {
      label = "Stopping";
    }

    return label;
  };

  const getItems = (content: IContent, index: number) => {
    let items: MenuProps["items"] = [];
    const isDisable = getIsDisableRunVision(content);
    const label = getRunVisionLabel(content);
    const isShowRunVision = getIsShowRunVision(content?.original_file_name || "");

    const getIsDisableStopBtn = (content: IContent) =>
      AssetUploadStatus.STOPPING === content?.assetStatus;

    if (isShowRunVision) {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleRunVision(selectedContent, index);
              }}>
              {label}
            </div>
          ),
          className: isDisable ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    } else {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    }

    return items;
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content?.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const fileExtraOptions = (content: IContent, index: number) => {
    return (
      <>
        {/* <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon disabled={isContentTableLoading} isActive={content?.is_bookmarked} />
        </div> */}
        <Dropdown
          trigger={["click"]}
          menu={{
            onClick: () => {
              setSelectedContent(content);
            },
            items: getItems(content, index),
          }}
          overlayClassName="table-action-dropdown content-dropdown"
          placement="bottomRight">
          <Button className="custom-icon more-action" onClick={() => setSelectedContent(content)}>
            <i className="ri-more-2-fill"></i>
          </Button>
        </Dropdown>
      </>
    );
  };
  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const allContent = () => {
    return (
      <>
        <div className="file-topic-page">
          <div className="top-bar d-flex d-flex-middle d-flex-between">
            {contents && contents?.length > 0 && (
              <div className="ant-tabs-nav-container">
                <Checkbox
                  className="select-label"
                  checked={getSelectedContents().length === getEditableContents().length}
                  indeterminate={
                    getSelectedContents().length &&
                    getSelectedContents().length < getEditableContents().length
                      ? true
                      : false
                  }
                  onChange={handleSelectAllContents}>
                  {getSelectedContents().length ? (
                    `${getSelectedContents().length} Out of ${contents?.length} selected`
                  ) : (
                    <>
                      Select All <span>(Showing {contents?.length} results)</span>
                    </>
                  )}
                </Checkbox>
              </div>
            )}

            {!isContentTableLoading && (
              <div className="ant-tabs-extra-content">
                <div className="btn-wrap">
                  <ContentActionButton />
                </div>
              </div>
            )}
          </div>
          <div className="page-scroll">
            <Row className="tab-row-inner">
              {contents?.length
                ? contents.map((item: IContent, index: number) => (
                    <FileCard
                      key={index}
                      data={item}
                      type={FileCardType.GENERATED}
                      extraOptions={fileExtraOptions(item, index)}
                      extraOptionsTwo={fileExtraOptionsTwo(item, index)}
                      onClick={() => handleViewEditContentNavigate(item.asset_id)}
                    />
                  ))
                : ""}
            </Row>
          </div>
        </div>
      </>
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "0",
      label: "About",
      children: <TopicAbout />,
    },
    {
      key: "1",
      label: "Related Assets",
      children: allContent(),
      icon: <span className="count">{contents?.length ? `(${contents?.length})` : ""}</span>,
    },
  ];

  return (
    <>
      <Flex className="inner-app-wrap topic-page" vertical>
        <PageHeader title="" breadcrumbView={true} isRelevantAsset={false} />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}>
          <Row className="content-tab">
            <Col span={24}>
              <Card className="content-tab-card">
                <Tabs items={tabItems} />
              </Card>
            </Col>
          </Row>
        </motion.div>

        {/* Single content delete */}
        <DeleteModal
          isLoading={contentDeleteLoading}
          isModalOpen={isShowAssetDeleteModal}
          handleCancel={() => setIsShowAssetDeleteModal(false)}
          handleSubmit={handleDeleteContent}
          message="Are you sure you want to delete this content?"
          title="Delete Content"
        />
      </Flex>
    </>
  );
};

export default TopicPage;
