import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ITaxonomyByTitle,
  ITaxonomySliceProps,
  TaxonomyCreate,
  TaxonomyData,
} from "./taxonomy.interface";
import { PURGE } from "redux-persist";

export const taxonomyByTitleInitValue: ITaxonomyByTitle = {
  taxonomyId: null,
  title: "",
  purpose: "",
  taxonomyType: "",
  levels: null,
  taxonomyTree: [],
  synonyms: "",
};

const initialState: ITaxonomySliceProps = {
  allTaxonomyData: [],
  taxonomyTableLoading: false,
  taxonomyDeleteLoading: false,
  getTaxonomyTree: {
    title: "",
    purpose: "",
    taxonomyType: "",
    levels: 0,
    versions: 1.0,
    taxonomyTree: [],
    taxonomyId: 0,
    termsCount: 0,
  },
  getTreeTableLoading: false,
  numOfCheckedRow: "",
  industryTaxonomy: taxonomyByTitleInitValue,
  functionTaxonomy: taxonomyByTitleInitValue,
  assetTypeTaxonomy: taxonomyByTitleInitValue,
  portfoliosTaxonomy: taxonomyByTitleInitValue,
  isTaxonomyByTitleLoading: false,
  deleteTreeTableLoading: false,
  totalTaxonomyCount: 0,
  taxonomyCurrPage: 1,
};

export const taxonomySlice = createSlice({
  name: "taxonomySlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllTaxonomyData: (state, action: PayloadAction<TaxonomyData[]>) => {
      state.allTaxonomyData = action.payload;
    },

    setTaxonomyTableLoading: (state, action: PayloadAction<boolean>) => {
      state.taxonomyTableLoading = action.payload;
    },

    deleteTaxonomy: (state, action: PayloadAction<number>) => {
      state.allTaxonomyData = state.allTaxonomyData.filter((v) => v.taxonomyId !== action.payload);
    },

    addTaxonomy: (state, action: PayloadAction<TaxonomyData>) => {
      state.allTaxonomyData.push(action.payload);
    },

    setTaxonomyTree: (state, action: PayloadAction<TaxonomyCreate>) => {
      state.getTaxonomyTree = action.payload;
    },

    setTreeTableLoading: (state, action: PayloadAction<boolean>) => {
      state.getTreeTableLoading = action.payload;
    },

    setTaxonomyDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.taxonomyDeleteLoading = action.payload;
    },

    setNumOfCheckedRow: (state, action: PayloadAction<any>) => {
      state.numOfCheckedRow = action.payload;
    },

    setTaxonomyByIndustry: (state, action: PayloadAction<ITaxonomyByTitle>) => {
      state.industryTaxonomy = action.payload;
    },

    setTaxonomyByFunction: (state, action: PayloadAction<ITaxonomyByTitle>) => {
      state.functionTaxonomy = action.payload;
    },

    setTaxonomyByAssetType: (state, action: PayloadAction<ITaxonomyByTitle>) => {
      state.assetTypeTaxonomy = action.payload;
    },

    setTaxonomyByPortfolios: (state, action: PayloadAction<ITaxonomyByTitle>) => {
      state.portfoliosTaxonomy = action.payload;
    },

    setIsTaxonomyByTitleLoading: (state, action: PayloadAction<boolean>) => {
      state.isTaxonomyByTitleLoading = action.payload;
    },

    setDeleteTreeTableLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteTreeTableLoading = action.payload;
    },

    setTotalTaxonomyCount: (state, action: PayloadAction<number>) => {
      state.totalTaxonomyCount = action.payload;
    },

    setTaxonomyCurrPage: (state, action: PayloadAction<number>) => {
      state.taxonomyCurrPage = action.payload;
    },
  },
});

export const {
  addTaxonomy,
  deleteTaxonomy,
  setTaxonomyTree,
  setAllTaxonomyData,
  setNumOfCheckedRow,
  setTreeTableLoading,
  setTaxonomyByIndustry,
  setTaxonomyByFunction,
  setTaxonomyByAssetType,
  setTaxonomyTableLoading,
  setTaxonomyDeleteLoading,
  setIsTaxonomyByTitleLoading,
  setDeleteTreeTableLoading,
  setTotalTaxonomyCount,
  setTaxonomyCurrPage,
  setTaxonomyByPortfolios,
} = taxonomySlice.actions;

export default taxonomySlice.reducer;
