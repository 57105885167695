import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Card, Checkbox, Image, Row } from "antd";
import "./RelevantAssets.scss";
import { RootState } from "../../store";
import { AssetFileCard, ContentChatButton } from "../../components";
import { setPdfDetails } from "../../store/pdfViewer/pdfViewer.slice";
import {
  FileCardType,
  FromWhere,
  Images,
  PATHS,
  PDFViewer,
  RelevantAssetView,
  uniqueId,
} from "../../shared";
import {
  contentByIdInitialValue,
  setContentById,
  setRelevantAssetView,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
} from "../../store/contentManagement/contentSlice";
import ViewEditContentManagement from "../viewEditContentManagement/ViewEditContentManagement";
import { useLocation, useNavigate } from "react-router-dom";
import { ICitations } from "../../store/conversationalSearch/conversationalSearch.interface";
import { getThumbnailsByPage } from "../../services/conversationalSearch";
import {
  setIsChatStartWithAssetFilter,
  setSelectedFilesForAssetFilter,
  setSelectedMessageCitations,
  setSelectedReleventAsset,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { updateViewCount } from "../../services/contentManagement";

const RelevantAssets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { routeHistory } = useSelector((state: RootState) => state.app);
  const { pdfDetails } = useSelector((state: RootState) => state.pdfViewer);
  const { relevantAssetView } = useSelector((state: RootState) => state.content);
  const { selectedChatHistory, selectedMessageCitations } = useSelector(
    (state: RootState) => state.conversationalSearch
  );

  const [selectedAssets, setSelectedAssets] = useState<ICitations[]>([]);
  const [citationList, setCitationList] = useState<ICitations[]>([]);
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);
  const [currentChatId, setCurrentChatId] = useState(selectedChatHistory.id);

  useEffect(() => {
    return () => {
      dispatch(setSelectedReleventAsset([]));
      dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setIsChatStartWithAssetFilter(false));
      setSelectedAssets([]);
      setCurrentChatId(-1);
    };
  }, []);
  useEffect(() => {
    if (currentChatId === -1 || (!selectedMessageCitations?.length && !citationList.length)) {
      let redirectPath = routeHistory[1] || PATHS.home;
      if (redirectPath === PATHS.relevantAssets)
        redirectPath = routeHistory?.find((p: string) => p != PATHS.relevantAssets) || PATHS.home;
      navigate(redirectPath);
    }
  }, [selectedChatHistory]);

  useEffect(() => {
    const fromWhere = location?.state?.fromWhere || "";
    if (selectedMessageCitations?.length > 0 && fromWhere === FromWhere.ConversationalSearch) {
      dispatch(setSelectedMessageCitations([]));
      const filteredCitations = selectedMessageCitations.filter((c) => c.asset_level === "primary");
      setCitationList(filteredCitations);
      if (pdfDetails.assetId === -1) dispatch(setRelevantAssetView(RelevantAssetView.None));
      // const groupedData = groupItemsByFileName(filteredCitations);
      // setListViewRecords(groupedData);
    }
  }, [selectedMessageCitations, location?.state]);

  const isImage = (item: ICitations) => Images.includes(item?.file_type || "");

  const toggleCardCollapseForCitation = (pdfDetails: ICitations) => {
    if (isImage(pdfDetails)) {
      setVisible(true);
      setSelectedImageURL(pdfDetails.pre_signed_url);
    } else {
      document.body.classList.add("relevant-assets-page"); // Toggle the class on body tag
      dispatch(setRelevantAssetView(RelevantAssetView.PDF));
      dispatch(
        setPdfDetails({
          assetId: pdfDetails?.asset_id,
          fileName: pdfDetails?.file_name,
          presignedUrl: pdfDetails?.pre_signed_url || "",
          title: pdfDetails?.title,
          page: (pdfDetails.page || 1) - 1,
          showOpenIcon: false,
        })
      );
    }
  };
  useEffect(() => {
    if (citationList?.length > 0 && selectedChatHistory?.id !== -1) {
      const request = citationList
        ?.filter((c) => c.asset_level === "primary")
        ?.map((citation) => {
          return { file_name: citation.file_name, page_no: citation?.page };
        });
      getThumbnailsByPage(selectedChatHistory.id, request);
    }
  }, [citationList, selectedChatHistory?.id]);

  const getSelectedAssets = () => {
    return selectedAssets.reduce((acc: ICitations[], current: ICitations) => {
      if (!acc.some((item: ICitations) => item.asset_id === current.asset_id)) {
        acc.push(current);
      }
      return acc;
    }, []);
  };
  // const handleChatWithAssetFilter = () => {
  //   const uniqAssets: ICitations[] = selectedAssets.reduce(
  //     (acc: ICitations[], current: ICitations) => {
  //       if (!acc.some((item: ICitations) => item.asset_id === current.asset_id)) {
  //         acc.push(current);
  //       }
  //       return acc;
  //     },
  //     []
  //   );
  //   dispatch(setSelectedReleventAsset(uniqAssets));
  //   dispatch(setSelectedFilesForAssetFilter(selectedAssets));
  //   dispatch(setIsChatStartWithAssetFilter(false));
  //   // setSelectedAssets([]);
  // };
  const handleSetCheckedAssets = (e: CheckboxChangeEvent, data: ICitations) => {
    let tempData = [...selectedAssets];
    if (e.target.checked) {
      setSelectedAssets([...tempData, data]);
    } else {
      setSelectedAssets(tempData.filter((e) => e.s_no !== data.s_no));
    }
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(setSelectedMessageCitations(citationList));
    updateViewCount(assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: true,
        fromWhere: FromWhere.ConversationalSearch,
      },
    });
  };

  const renderAssetView = () => {
    switch (relevantAssetView) {
      case RelevantAssetView.PDF:
        return (
          <div>
            <PDFViewer isRelevantAsset={true} />
          </div>
        );

      case RelevantAssetView.PDFInfo:
        return <ViewEditContentManagement isRelevantAsset={true} />;

      default:
        return null;
    }
  };

  return (
    <>
      <div className="relevant-assets-wrap">
        <Card className="relevant-assets">
          <div className="top-bar d-flex d-flex-middle d-flex-between">
            <h2>Relevant Assets</h2>
            <Button
              type="text"
              className="page-close-btn"
              onClick={() => {
                navigate(routeHistory[1] || PATHS.home);
              }}
              icon={<i className="ri-close-fill"></i>}
            />
          </div>

          <div className="bottom-bar d-flex d-flex-middle d-flex-between">
            <div className="ant-tabs-nav-container">
              <Checkbox
                className="select-label"
                onChange={(e) =>
                  e.target.checked ? setSelectedAssets([...citationList]) : setSelectedAssets([])
                }>
                Select All <span>(Showing {citationList.length} results)</span>
              </Checkbox>
            </div>
            <div className="ant-tabs-extra-content">
              {/* <BookmarkIcon /> */}
              {/* <Button
                onClick={handleChatWithAssetFilter}
                type="primary"
                disabled={selectedAssets.length ? false : true}
                className="btn chat-document"
                icon={<i className="ci-small-chat-icon ci"></i>}>
                Chat
              </Button> */}
              {/* <Button
                type="text"
                className="text-btn"
                icon={<i className="ri-delete-bin-line"></i>}>
                Delete
              </Button> */}
              <ContentChatButton
                {...{
                  selectedContents: getSelectedAssets() || [],
                }}
              />
            </div>
          </div>
          <div className="page-scroll">
            <Row className="tab-row-inner">
              {citationList.length
                ? citationList.map((item, index) => (
                    <Fragment key={`citation-${item.id}-${index}`}>
                      <AssetFileCard
                        data={item}
                        handleSetCheckedAssets={handleSetCheckedAssets}
                        isChecked={selectedAssets.find((e) => e.s_no === item.s_no) ? true : false}
                        type={FileCardType.UPLOADED}
                        key={uniqueId()}
                        onCardClick={() => toggleCardCollapseForCitation(item)}
                        onClick={() => handleViewEditContentNavigate(item.asset_id, false)}
                      />
                    </Fragment>
                  ))
                : ""}
            </Row>
          </div>
        </Card>

        {renderAssetView()}
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                navigate(PATHS.relevantAssets, {
                  state: { s_no: 0, fromWhere: FromWhere.ConversationalSearch },
                });
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RelevantAssets;
