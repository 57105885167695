import { Button, Modal } from "antd";
import { ReactNode } from "react";

interface IModal {
  title: string;
  message: string;
  content?: ReactNode;
  isLoading: boolean;
  isModalOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  submitBtnName?: string;
  cancelBtnName?: string;
  headerIcon?: string;
}

const DeleteModal: React.FC<IModal> = ({
  submitBtnName = "Delete",
  cancelBtnName = "Cancel",
  ...props
}) => {
  const {
    isLoading,
    isModalOpen,
    handleSubmit,
    handleCancel,
    message,
    content,
    title,
    headerIcon,
  } = props;

  return (
    <Modal
      rootClassName="delete-modal-root"
      title={
        <>
          {headerIcon ? <img src={headerIcon} alt="headerIcon" width={28} className="header-icon-delete" /> : ""}
          {title}
        </>
      }
      centered={true}
      onCancel={handleCancel}
      footer={
        <>
          <Button className="btn" onClick={handleCancel}>
            {cancelBtnName}
          </Button>
          <Button className="btn" loading={isLoading} danger onClick={handleSubmit}>
            {submitBtnName}
          </Button>
        </>
      }
      open={isModalOpen}>
      <div>{content || message}</div>
    </Modal>
  );
};

export default DeleteModal;
