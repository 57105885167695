import { store } from "../store";
// import { get } from "./apiClients";
import { setAppearanceLoading, setAppearance } from "../store/appState/appSlice";
import jsonData from "../json/appAppearance.json";
import { IAppearanceProps } from "../store/appState/app.interface";

export const getAppearance = async (): Promise<void> => {
  try {

    store.dispatch(setAppearanceLoading(true));

    // const data = await get(`${location.origin}/json/appAppearance.json`);

    const obj = jsonData.appearance;

    store.dispatch(setAppearance(obj));

    document.documentElement.style.setProperty('--primary', obj.primary_color);
    document.documentElement.style.setProperty('--hover', obj.secondary_color);

    store.dispatch(setAppearanceLoading(false));

  } catch (error) {
    store.dispatch(setAppearanceLoading(false));
    console.log(error);
  } finally {
    store.dispatch(setAppearanceLoading(false));
  }
};

export const setOldAppearance = (oldData: IAppearanceProps): void => {
  try {
    store.dispatch(setAppearanceLoading(true));
    store.dispatch(setAppearance(oldData));
    store.dispatch(setAppearanceLoading(false));
  } catch (error) {
    store.dispatch(setAppearanceLoading(false));
    console.log(error);
  } finally {
    store.dispatch(setAppearanceLoading(false));
  }
};