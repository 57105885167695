import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { NavigateFunction } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import configs from "./config";
import { store } from "../store";
import { IMAGES } from "./images";
import { setUserId } from "../store/auth/auth.slice";
import { setPdfDetails } from "../store/pdfViewer/pdfViewer.slice";
import { ITaxonomyTree } from "../store/taxonomy/taxonomy.interface";
import { getAllChatHistory } from "../services/conversationalSearch";
import { IContent, IContentUser } from "../store/contentManagement/content.interface";
// import { getContentSelected } from "../services/contentManagement";
import { setRelevantAssetView } from "../store/contentManagement/contentSlice";
import { setAllFileTypesSelected } from "../store/filtersFromTaxonomyData/filters.slice";
import {
  IChatHistory,
  IFileINContext,
  IMessages,
} from "../store/conversationalSearch/conversationalSearch.interface";
import {
  AssetUploadStatus,
  FileLocationLabel,
  fileType,
  Month,
  PATHS,
  RelevantAssetView,
  RunVisionFileType,
  SummaryStaus,
  userType,
} from "./constants";
import { ILoginUserDetails } from "../types";
import { getAppearance } from "../services/appAppearanceApi";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

export const getLocalStorageItem = (name: string) => {
  return localStorage.getItem(name);
};

export const removeLocalstorage = () => {
  localStorage.removeItem("token_foundationKM");
  localStorage.removeItem("user_foundationKM");
};

export const removeCookie = () => {
  Cookies.remove("token_foundationKM", { domain: configs.HOST });
  Cookies.remove("user_foundationKM", { domain: configs.HOST });
};

export const getUserId = (): number => {
  const user = getLocalStorageItem("user_foundationKM");
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.id ?? -1;
};

export const formateDate = (date: string): string => {
  if (!!date) {
    const formatDate = new Date(date); // Convert received time to a JavaScript Date object

    const currentDate = new Date(); // Get the current date
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const dayOfWeek = formatDate?.toLocaleDateString("en-US", {
      weekday: "short",
    });

    if (
      formatDate.getDate() === currentDate.getDate() &&
      formatDate.getMonth() === currentDate.getMonth() &&
      formatDate.getFullYear() === currentDate.getFullYear()
    )
      return "Today";
    if (
      formatDate.getDate() === yesterday.getDate() &&
      formatDate.getMonth() === currentDate.getMonth() &&
      formatDate.getFullYear() === currentDate.getFullYear()
    )
      return "Yesterday";

    return ` ${dayOfWeek} ${formatDate.getDate()}, ${formatDate.getFullYear()}`;
  }
  return "Invalid Date";
};

// under 999
export const uniqueId = () => {
  // Generate a timestamp
  const timestamp = new Date().getTime();

  // Generate a random number
  const random = Math.floor(Math.random() * 1000);

  // Concatenate timestamp and random number
  const combinedId = `${timestamp}${random}`;

  // Convert the combined string to a number and take the modulus with 1000
  const numericId = parseInt(combinedId, 10) % 1000;

  return numericId;
};

export const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const getFormattedDate = (date: string, type: string): string => {
  if (date) {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    if (type === "type1") return `${Month[month]} ${day}, ${year}`;
    return `${day}, ${Month[month]} ${year}`;
  } else {
    return "";
  }
};

export const handleOpenPdfDoc = (
  assetId: number,
  fileName: string,
  presignedUrl: string,
  title: string,
  page: number,
  showOpenIcon: boolean
) => {
  // store.dispatch(setIsPdfShow(true));
  store.dispatch(setRelevantAssetView(RelevantAssetView.PDF));
  store.dispatch(
    setPdfDetails({
      assetId: assetId,
      fileName: fileName,
      presignedUrl: presignedUrl,
      title,
      page,
      showOpenIcon,
    })
  );
};

export function convertDataLevels(dataLevels: any) {
  const result: any = [];

  dataLevels.forEach((item: any) => {
    const levels = item?.tree.reduceRight((acc: any, level: any) => {
      if (level?.level?.length > 1) {
        return level.level;
      } else {
        const currLevel = {
          id: level?.level[0]?.id,
          levelname: level?.level[0]?.levelname,
          description: level?.level[0]?.description,
          linkedAsset: level?.level[0]?.linkedAsset,
          children: acc?.length > 0 ? [...acc] : [],
        };
        return [currLevel];
      }
    }, []);

    levels.reduceRight((acc: any, curr: any) => {
      const existing = acc.find((item: any) => item?.levelname === curr?.levelname);
      if (existing) {
        existing?.children.push(...curr?.children);
      } else {
        acc.push(curr);
      }
      return acc;
    }, result);
  });

  return result;
}

export const getIndustryList = (data: ITaxonomyTree[] | null | undefined) => {
  const tempIndustriesArr: any = [];
  data?.length &&
    data.forEach((element: any) => {
      tempIndustriesArr.push({
        ...element,
        value: element.taxonomyNodeId,
        label: element.taxonomyNodeName,
      });
    });
  return tempIndustriesArr;
};

export const getFunctionsList = (data: ITaxonomyTree[]) => {
  const tempFunctionsArr: any = [];
  data.forEach((element: any) => {
    tempFunctionsArr.push({
      ...element,
      value: element.taxonomyNodeId,
      label: element.taxonomyNodeName,
    });
  });
  return tempFunctionsArr;
};

export const onDownloadPdf = async (
  pre_signed_url: string,
  setIsDownloading: ((v: boolean) => void) | null,
  fileName: string
) => {
  if (pre_signed_url) {
    try {
      setIsDownloading && setIsDownloading(true);
      // Fetch the PDF file from the URL
      const response = await fetch(pre_signed_url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName); // Specify the filename for the downloaded file
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download File");
    } finally {
      setIsDownloading && setIsDownloading(false);
    }
  }
};

export const getAllIds = (obj: any) => {
  const ids: any = [];
  function collectIds(obj: any) {
    if (typeof obj === "object" && obj !== null && "taxonomyNodeId" in obj) {
      ids.push(obj.taxonomyNodeId.toString());
    }
    if (Array.isArray(obj)) {
      obj.forEach((item) => collectIds(item));
    } else if (typeof obj === "object" && obj !== null) {
      for (let key in obj) {
        collectIds(obj[key]);
      }
    }
  }
  collectIds(obj);
  return ids;
};

export const mergeArrays = (newIds: string[], existingIds: string[]) => {
  for (let i = 0; i < newIds.length; i++) {
    if (!existingIds.includes(newIds[i])) {
      existingIds.push(newIds[i]);
    }
  }
  return existingIds;
};
export const generateRandomNumber = () => {
  const currentDate = new Date();
  const timestamp = currentDate.getTime(); // Get the current timestamp in milliseconds
  const randomSeed = timestamp % 10000; // Take last 4 digits of the timestamp
  const randomPart = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0"); // Generate a random number and pad it to 4 digits
  return parseInt(String(randomSeed) + randomPart); // Append the random number to the timestamp
};

export const getUserRole = () => {
  const user = getLocalStorageItem("user_foundationKM");
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.userRole ?? null;
};

export const convertToNumber = (data: string[]) => {
  var numberArray = data.map(function (element) {
    return parseFloat(element);
  });
  return !!numberArray?.length ? numberArray : [];
};

export const convertToString = (data: number[]) => {
  var stringArray = data.map(function (element) {
    return element.toString();
  });
  return !!stringArray?.length ? stringArray : [];
};

export const compareFilters = (data: string[], existingData: string[]) => {
  const tempExistingData = [...existingData];

  tempExistingData.sort();
  data.sort();

  if (tempExistingData.length !== data.length) {
    return false;
  }

  for (let i = 0; i < tempExistingData.length; i++) {
    if (tempExistingData[i] !== data[i]) {
      return false;
    }
  }
  return true; // return true if all elements are equal
};

export const getCapitalizedStr = (str: string) => {
  if (str) {
    str = str.toLowerCase();
    const words = str.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }

    return words.join(" ");
  }
  return "";
};

export const renderFileTypeIcon = (type: string | null) => {
  let filePath = "";
  if (type === "png" || type === "jpg" || type === "jpeg") {
    filePath = IMAGES.imageIcon;
  }
  if (type === "ppt" || type === "pptx") {
    filePath = IMAGES.pptIcon;
  }
  if (type === "pdf") {
    filePath = IMAGES.pdfIcon;
  }
  if (type === "xls" || type === "xlsx") {
    filePath = IMAGES.xlsIcon;
  }
  if (type === "doc" || type === "docx") {
    filePath = IMAGES.docsIcon;
  }
  if (!type) {
    filePath = IMAGES.imageIcon;
  }
  return filePath;
};

export const getUserName = () => {
  const user = getLocalStorageItem("user_foundationKM");
  const parsedUser = user && JSON.parse(user);

  return parsedUser?.name ?? "";
};

// const groupItemsByFileName = (items: ICitations[]): IGroupted[] => {
//   const grouped: { [key: string]: IGroupedItems } = {};
//   items.forEach((item) => {
//     const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
//     if (!(key in grouped)) {
//       grouped[key] = {
//         id: item.asset_id,
//         title: item.title,
//         file_name: item.file_name,
//         original_file_name: item.original_file_name,
//         page: [],
//       };
//     }
//     if (!grouped[key].page.includes(item.page)) {
//       grouped[key].page.push(item.page);
//     }
//   });

//   return Object.values(grouped);
// };

// export const getCurrentLevelIds = (data: any) => {
//   const ids: any = [];
//   data.map((node: ITaxonomyTree) => {
//     ids.push(node.taxonomyNodeId);
//   });

//   return ids;
// };

export const truncateString = (str: string, maxLength: number) => {
  if (typeof str === "string" && str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

export const getIsShowRunVision = (fileName: string) => {
  const fileType = fileName?.substring(fileName.lastIndexOf(".") + 1);
  return RunVisionFileType.includes(fileType);
};
//group chat history
const isToday = (date: string | Date): boolean => {
  const today = new Date();
  const someDate = new Date(date);
  return today.toDateString() === someDate.toDateString();
};

const isWithinDays = (date: string | Date, days: number): boolean => {
  const today = new Date();
  const someDate = new Date(date);
  const diffTime = Math.abs(today.getTime() - someDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= days;
};

const getMonthYear = (date: string | Date): string => {
  const d = new Date(date);
  return `${d.getFullYear()}-${d.getMonth() + 1}`;
};

export interface GroupedData {
  [string: string]: IChatHistory[];
}

export const groupDataByDate = (data: IChatHistory[]): GroupedData => {
  let groupedData: GroupedData = {};
  const historyData = [...data];
  historyData
    .sort((a, b) => b.id - a.id)
    .forEach((item) => {
      if (isToday(item.createdOn)) {
        if (!groupedData.Today) groupedData.Today = [];
        groupedData.Today.push(item);
      } else if (isWithinDays(item.createdOn, 30)) {
        if (!groupedData["Previous 30 days"]) groupedData["Previous 30 days"] = [];
        groupedData["Previous 30 days"].push(item);
      } else {
        const monthYear = getMonthYear(item.createdOn);
        if (!groupedData[monthYear]) groupedData[monthYear] = [];
        groupedData[monthYear].push(item);
      }
    });

  return groupedData;
};

export function formatBytes(bytes: number) {
  const kb = bytes / 1024;
  const mb = kb / 1024;
  if (kb > 1024) return `${mb.toFixed(0)} MB`;
  else return `${kb.toFixed(0)} KB`;
}

export const getIsDisableEditContent = (content: IContent) =>
  ![String(AssetUploadStatus.COMPLETED)].includes(content?.assetStatus);

export const getIsSummaryProcessing = (status: string) =>
  [String(SummaryStaus.TO_BE_PROCESSED), SummaryStaus.PROCESSING].includes(status);

export const getIsContentFailed = (content: IContent) =>
  [
    String(AssetUploadStatus.FAILED),
    AssetUploadStatus.FAILED_IN_LLM,
    AssetUploadStatus.STOPPED,
  ].includes(content?.assetStatus);

export const getUserFirstName = (name = "") => {
  return name ? name.split(" ")[0] : "";
};

export const formattedNum = (num = 0) => {
  if (typeof num === "number") {
    if (num > 0 && num < 10) {
      return `0${num}`;
    } else {
      return num;
    }
  }

  return 0;
};

export const getAssetTypeOptions = (assetTypes: ITaxonomyTree[]) => {
  const options: any[] = [];

  if (assetTypes && assetTypes?.length) {
    assetTypes.forEach((item) => {
      options.push({ value: Number(item?.taxonomyNodeId), label: item?.taxonomyNodeName });
    });
  }

  return options;
};

export const getFileTypeOptions = (list: any[]) => {
  const options: any[] = [];

  if (list && list?.length) {
    list.forEach((item) => {
      options.push({ value: item?.key, label: item?.key });
    });
  }

  return options;
};

export function findParentsNodeNameById(data: ITaxonomyTree[], targetId: number) {
  let path: string[] = [];

  function traverse(nodes: any, currentPath: any[]) {
    for (let node of nodes) {
      let newPath = currentPath?.slice(); // Clone the path
      newPath.push(node?.taxonomyNodeName?.trim());

      if (node.taxonomyNodeId === targetId) {
        path = newPath;
        return true; // Found the node
      }

      if (node.children && traverse(node.children, newPath)) {
        return true; // Node found in the children
      }
    }
    return false; // Node not found in the current branch
  }

  traverse(data, []);
  return path || [];
}

export function isEmpty(value: any) {
  // Check if value is null or undefined
  if (value == null) {
    return true;
  }

  // Check if value is a boolean
  if (typeof value === "boolean") {
    return false;
  }

  // Check if value is a number
  if (typeof value === "number") {
    return false;
  }

  // Check if value is a string
  if (typeof value === "string") {
    const trimmedVal = value?.trim() || "";
    return trimmedVal.length === 0;
  }

  // Check if value is an array
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  // Check if value is a map or set
  if (value instanceof Map || value instanceof Set) {
    return value.size === 0;
  }

  // Check if value is an object
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function isNew(date: string) {
  let newDate = new Date().toISOString();
  const dateOnly = date.split("T")[0];
  const currentDateOnly = newDate.split("T")[0];
  if (dateOnly === currentDateOnly) {
    return true;
  }
  return false;
}

export const countWithZero = (number: number) => {
  return (number < 10 ? "0" : "") + number;
};
export const setLoginData = (data: ILoginUserDetails, navigate: NavigateFunction) => {
  const user = {
    id: data.id,
    username: data.username,
    name: data.name,
    email: data.email,
    userRole:
      data.userPermissions && data.userPermissions.length ? data.userPermissions[0].role : null,
  };

  localStorage.setItem("user_foundationKM", JSON.stringify(user));
  localStorage.setItem("token_foundationKM", data?.jwttoken);

  store.dispatch(setUserId(data?.id));

  navigate(PATHS.home);
  getAllChatHistory({ userId: data?.id, configSettingId: 1 });
  // getContentSelected();
  getAppearance();
  store.dispatch(setAllFileTypesSelected(fileType));
  toast.success("Login successfully");
};

export const formatRelativeTime = (date: string | number | Date): string => {
  return dayjs(date).fromNow();
};

export const getIsAssetProcessing = (status: string) =>
  [
    String(AssetUploadStatus.PROCESSING),
    AssetUploadStatus.SENT_TO_LLM,
    AssetUploadStatus.CONVERTED_TO_PDF,
    AssetUploadStatus.UPLOADED_TO_DO,
  ].includes(status);

export const getCardOwnersDetail = (user: IContentUser): string => {
  if (!!user?.id && getUserId() === user?.id) {
    return FileLocationLabel.USER;
  }
  return user?.name ?? "";
};

export const getFilesInContext = (
  selectedFilesForAssetFilter: IFileINContext[],
  selectedReleventAsset: IFileINContext[],
  selectedChatHistory: IChatHistory,
  type: string
): any[] => {
  let messageFileInContext: IFileINContext[] | null;
  try {
    const lastMessage: IMessages | undefined = selectedChatHistory.messages
      .reverse()
      .find((message) => message.type === userType.INFO);
    const content = lastMessage?.content?.includes("{") ? JSON.parse(lastMessage.content) : {};
    messageFileInContext = content.filesInContext;
  } catch (_) {
    messageFileInContext = null;
  }
  if (
    !isEmpty(selectedFilesForAssetFilter) ||
    !isEmpty(selectedReleventAsset) ||
    !isEmpty(messageFileInContext)
  ) {
    // let fileData: IFileINContext[] | IFileContext[] = [];
    if (!isEmpty(messageFileInContext)) {
      if (type === "ws")
        return (messageFileInContext || []).map((f: IFileINContext) => ({
          file_name: f.file_name,
          asset_id: f.asset_id,
          title: f.title,
          file_type: f.file_type || "",
          asset_type: f.asset_type,
        }));
      else
        return (messageFileInContext || []).map((f: IFileINContext) => ({
          fileName: f.file_name,
          assetId: f.asset_id,
          title: f.title,
          fileType: f.file_type || "",
          assetType: f.asset_type,
        }));
    } else {
      if (type === "ws") {
        return (
          isEmpty(selectedReleventAsset) ? selectedFilesForAssetFilter : selectedReleventAsset
        )
          .filter((item: IFileINContext) => !item.isPublished || item.isPublished === "TRUE")
          .map((f: IFileINContext) => ({
            file_name: f.file_name,
            asset_id: f.asset_id,
            title: f.title,
            file_type: f.file_type || "",
            asset_type: f.asset_type,
          }));
      } else {
        return (
          isEmpty(selectedReleventAsset) ? selectedFilesForAssetFilter : selectedReleventAsset
        )
          .filter((item) => !item.isPublished || item.isPublished === "TRUE")
          .map((f) => ({
            fileName: f.file_name,
            assetId: f.asset_id,
            title: f.title,
            fileType: f.file_type || "",
            assetType: f.asset_type,
          }));
      }
    }
  } else return [];
};

export const getCardOwnersFullName = (user: IContentUser): string => {
  return user?.name ?? "";
};

export function findTaxonomyNodeIds(data: any[]) {
  let result: any[] = [];
  function recurse(nodes: any) {
    nodes.forEach((node: any) => {
      result?.push(node?.taxonomyNodeId.toString());
      if (node?.children && node?.children.length > 0) {
        recurse(node?.children);
      }
    });
  }
  recurse(data);
  return result;
}
