import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Modal,
  Popover,
  Row,
  Table,
  Tooltip,
  Image,
} from "antd";
import {
  ContentChatButton,
  DeleteModal,
  FileCard,
  FileCardSkeleton,
  PageHeader,
  TableSkeleton,
  UploadAssets,
} from "../../components";
import { RootState } from "../../store";
import { ITaxonomyTree } from "../../store/taxonomy/taxonomy.interface";
import {
  IContent,
  IContentFilter,
  ITagsByAssestId,
} from "../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  contentFilterField,
  setAllContentDetails,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateSourceFilter,
  tagsByAssestIdInitValue,
  setUpdateAllSourceFilter,
  clearSourceFilterWithoutBookmark,
  updateAssetByAssetId,
  sourceFilterInitValue,
  setSelectionAppliedForSources,
  setSelectContentDetails,
  setContentFilesInContext,
  setContentSelectedIds,
} from "../../store/contentManagement/contentSlice";
import {
  bookmarkContent,
  deleteAssetById,
  getAllContent,
  getAssetDetailsByAssetId,
  likeContent,
  updateViewCount,
} from "../../services/contentManagement";
import {
  AssetTags,
  AssetUploadStatus,
  BookmarkIcon,
  ContentSourceFilter,
  CustomPagination,
  FileCardType,
  FromWhere,
  NoContentData,
  PATHS,
  PageLimit,
  ViewType,
  getFormattedDate,
  renderFileTypeIcon,
  AssetSummaryPopover,
  getIsDisableEditContent,
  isEmpty,
  getIsContentFailed,
  getIsAssetProcessing,
  getIsSummaryProcessing,
  // AppLayout,
  PDFViewer,
  handleOpenPdfDoc,
  Images,
  getCardOwnersFullName,
} from "../../shared";
import {
  // setIsChatStartWithAssetFilter,
  setSelectedFilesForAssetFilter,
  // setSelectedReleventAsset,
} from "../../store/conversationalSearch/conversationalSearchSlice";
// import { IFileINContext } from "../../store/conversationalSearch/conversationalSearch.interface";
import {
  // setAppLayoutview,
  setViewType,
} from "../../store/appState/appSlice";
import {
  isMyContentChatFilterEmpty,
  isSourceFilterEmpty,
  isSourcesChatFilterEmpty,
} from "../../shared/helper/contentHelper";
import "./Source.scss";

const Sources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { viewType } = useSelector((state: RootState) => state.app);
  const {
    contents,
    isContentTableLoading,
    totalContentsCount,
    contentDeleteLoading,
    sourceFilter,
    contentFilesInContext,
  } = useSelector((state: RootState) => state.content);

  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [viewedContent, setViewedContent] = useState<IContent>(contentByIdInitialValue);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<IContentFilter>(sourceFilterInitValue);
  const [visible, setVisible] = useState(false);
  const [scaleStep] = useState(0.5);
  const [isShowPDF, setIsShowPDF] = useState<boolean>(false);

  const isDataPersist = location?.state?.isDataPersist || false;
  const currentPage = sourceFilter?.currentPage ?? 1;
  let contentInterval: any;

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content) => {
          if (
            getIsAssetProcessing(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING ||
            getIsSummaryProcessing(content?.summaryStatus)
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const resetIsDataPersistRoute = () => {
    navigate(PATHS.sources, { state: { isDataPersist: false } });
  };

  useEffect(() => {
    if (!isDataPersist) {
      getAllContent(sourceFilter);
    } else {
      resetIsDataPersistRoute();
    }

    return () => {
      dispatch(setContentFilesInContext([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setSelectedFilesForAssetFilter([]));
    };
  }, []);

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  useEffect(() => {
    localFilters?.isFilterVisible
      ? document.body.classList.add("content-filter-visible")
      : document.body.classList.remove("content-filter-visible");
  }, [localFilters]);

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(setUpdateSourceFilter({ field: contentFilterField?.currentPage, value: currentPage }));
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: content ? getIsContentFailed(content) : false,
        fromWhere: FromWhere.Source,
      },
    });
  };

  const findNodeNameByValue = (data: ITaxonomyTree[], value: number): string => {
    for (const item of data) {
      if (item.taxonomyNodeId === value) {
        return item.taxonomyNodeName;
      }
      if (item.children) {
        const result = findNodeNameByValue(item.children, value);
        if (result) {
          return result;
        }
      }
    }
    return "";
  };

  // const handlePublishedValidation = (content: IContent) => {
  //   let flag = false;
  //   let messageList = [];
  //   const assetType = content?.linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];

  //   if (!content?.title || content?.title.trim() === "") {
  //     messageList.push("Title");
  //     flag = true;
  //   }
  //   if (!content?.summary || content?.summary.trim() === "") {
  //     messageList.push("Summary");
  //     flag = true;
  //   }
  //   if (!assetType.length) {
  //     messageList.push("Asset type");
  //     flag = true;
  //   }

  //   if (messageList && messageList.length) {
  //     let message = "";
  //     messageList.forEach((item, index) => {
  //       message =
  //         message +
  //         `${
  //           index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
  //         }${item}`;
  //     });

  //     if (message) {
  //       message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
  //       toast.error(message);
  //     }
  //   }

  //   return flag;
  // };

  // const renderAssetStatus = (isPublished: boolean | string, content: IContent, index: number) => {
  //   switch (content?.assetStatus) {
  //     case AssetUploadStatus.PROCESSING:
  //     case AssetUploadStatus.SENT_TO_LLM:
  //     case AssetUploadStatus.CONVERTED_TO_PDF:
  //     case AssetUploadStatus.UPLOADED_TO_DO:
  //       return <div className="inProgressStatus">In processing</div>;

  //     case AssetUploadStatus.LOADED_INTO_LLM:
  //     case AssetUploadStatus.COMPLETED:
  //       return (
  //         <Switch
  //           value={["TRUE", true].includes(isPublished) ? true : false}
  //           onChange={() => {
  //             if (handlePublishedValidation(content)) return;

  //             const isPublishedValue = ["TRUE", true].includes(isPublished) ? true : false;
  //             if (!isPublishedValue) {
  //               enablePublish(content?.asset_id);
  //             } else {
  //               disablePublish(content?.asset_id);
  //             }
  //             dispatch(setUpdatePublishDraft(index));
  //           }}
  //         />
  //       );

  //     // case AssetUploadStatus.FAILED:
  //     // case AssetUploadStatus.FAILED_IN_LLM:
  //     //   return <div className="failedStatusText">Failed</div>;

  //     case AssetUploadStatus.STOPPING:
  //       return <div className="inProgressStatus">Process stopping</div>;
  //     // case AssetUploadStatus.STOPPED:
  //     //   return <div className="failedStatusText">Process Stopped</div>;

  //     default:
  //       return "";
  //   }
  // };

  // const getRunVisionLabel = (content: IContent) => {
  //   let label = "Run Vision";

  //   if (content.visionParsed) {
  //     if (
  //       [
  //         String(AssetUploadStatus.UPLOADED_TO_DO),
  //         AssetUploadStatus.PROCESSING,
  //         AssetUploadStatus.SENT_TO_LLM,
  //       ].includes(content.assetStatus)
  //     ) {
  //       label = "Run Vision Processing";
  //     } else if (content.assetStatus === AssetUploadStatus.COMPLETED) {
  //       label = "Run Vision Completed";
  //     } else if (content.assetStatus === AssetUploadStatus.FAILED) {
  //       label = "Run Vision Failed";
  //     }
  //   }
  //   return label;
  // };

  // const getIsDisableRunVision = (content: IContent) => {
  //   let isDisable = true;

  //   if (content.visionParsed === false && content.assetStatus === AssetUploadStatus.COMPLETED) {
  //     isDisable = false;
  //   }
  //   return isDisable;
  // };

  // const handleRunVision = async (content: IContent | null, index: number) => {
  //   if (content?.asset_id) {
  //     toast.success("Vision is running");
  //     const res = await runVision(content?.asset_id);

  //     if (res?.data?.visionParsed) {
  //       const updatedContent = {
  //         ...content,
  //         assetStatus: AssetUploadStatus.SENT_TO_LLM,
  //         visionParsed: true,
  //       };
  //       const contentsValues = [...contents];
  //       contentsValues[index] = updatedContent;
  //       dispatch(setAllContentDetails(contentsValues));
  //     }
  //   }
  // };

  const handleDeleteSource = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        false,
        sourceFilter,
        "sources"
      );
      if (res?.data) {
        let contentsValues = [...contents];
        contentsValues = !isEmpty(contentsValues)
          ? contentsValues?.filter((item) => item.asset_id !== selectedContent?.asset_id)
          : [];
        dispatch(setAllContentDetails(contentsValues));

        const filteredContext = contentFilesInContext.filter(
          (c) => selectedContent?.asset_id !== c.asset_id
        );
        dispatch(setSelectedFilesForAssetFilter(filteredContext));
      }
    }

    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    dispatch(setUpdateSourceFilter({ field: contentFilterField?.currentPage, value: currentPage }));
  };

  const handleSelectAllContents = (e: any) => {
    if (contents && contents.length) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,
      }));
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const getEditableContents = () => {
    let editableContents: number[] = [];
    if (contents && contents.length) {
      editableContents = contents
        .filter((item) => !getIsDisableEditContent(item))
        .map((item) => item?.asset_id);
    }
    return editableContents || [];
  };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (contents && contents.length) {
      selectedContents = contents
        .filter((item) => !getIsDisableEditContent(item) && item?.isSelected)
        .map((item) => item?.asset_id);
    }
    return selectedContents || [];
  };

  // const rowSelection = {
  //   selectedRowKeys: getSelectedContents(),
  //   onChange: (selectedRowKeys: any[]) => {
  //     let updatedContent: IContent[] = [];
  //     if (contents && contents?.length) {
  //       contents.forEach((item) => {
  //         if (selectedRowKeys.includes(item?.asset_id)) {
  //           updatedContent.push({ ...item, isSelected: true });
  //         } else {
  //           updatedContent.push({ ...item, isSelected: false });
  //         }
  //       });
  //     }
  //     dispatch(setSelectionAppliedForSources(false));
  //     dispatch(setSelectContentDetails(updatedContent));
  //   },
  //   getCheckboxProps: (record: IContent) => ({
  //     disabled: getIsDisableEditContent(record),
  //   }),
  // };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handleLike = async (
    content: IContent,
    index: number,
    isLiked: boolean | null,
    flag: string
  ) => {
    const res = await likeContent(content.asset_id, isLiked, flag);
    if (res) {
      const updatedContent = {
        ...content,
        is_liked: isLiked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handlePagination = (page: number) => {
    dispatch(setUpdateAllSourceFilter({ ...sourceFilter, currentPage: page }));
    getAllContent({ ...sourceFilter, currentPage: page });
  };

  // const handleChatWithAssetFile = (file: IContent): void => {
  //   dispatch(setSelectedReleventAsset([]));
  //   const fileInContext: IFileINContext[] = [file].map((e) => ({
  //     asset_id: e.asset_id,
  //     asset_type: "",
  //     file_name: e.file_name,
  //     file_type: e.file_type || "",
  //     title: e.title,
  //   }));
  //   dispatch(setAppLayoutview(AppLayout.MINIMIZE));
  //   dispatch(setSelectedFilesForAssetFilter(fileInContext));
  //   // dispatch(setContentSelectedIds(fileInContext.map((e) => e.asset_id)));
  //   dispatch(setIsChatStartWithAssetFilter(false));
  // };

  const chatOption = (content: IContent) => {
    return isContentTableLoading || getIsDisableEditContent(content) ? (
      <></>
    ) : (
      <Tooltip
        placement="topRight"
        trigger="hover"
        title="Chat with this document"
        rootClassName="start-chat-tooltip">
        <ContentChatButton {...{ localFilters, isVisible: true }} />
      </Tooltip>
      // <Tooltip
      //   placement="topRight"
      //   title="Chat with this document"
      //   rootClassName="start-chat-tooltip">
      //   <Button
      //     disabled={isContentTableLoading || getIsDisableEditContent(content)}
      //     onClick={() => handleChatWithAssetFile(content)}
      //     type="text"
      //     className="custom-icon single-chat-button"
      //     icon={<i className="ci-small-chat-icon ci"></i>}></Button>
      // </Tooltip>
    );
  };

  const fileHoverOptions = (content: IContent, index: number) => {
    return (
      <>
        <Button
          className={content?.is_liked === true ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === true ? null : true, "like")
          }
          icon={<i className="ri-thumb-up-fill"></i>}></Button>
        <Button
          className={content?.is_liked === false ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === false ? null : false, "dislike")
          }
          icon={<i className="ri-thumb-down-fill"></i>}></Button>
      </>
    );
  };

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const fileExtraOptions = (content: IContent) => {
    return (
      <>
        <div className="link-btn" onClick={() => handleContentDetail(content)}>
          <i className="ci small-external-link"></i>
        </div>
      </>
    );
  };

  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        {chatOption(content)}
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          {/* if user pic is true */}
          {/* <img src={IMAGES.avatarDefault} alt="profile-pic" className="profile-pic" /> */}

          {/* else */}
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const tableColumns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "200px",
      className: "title-table-col",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Title : </span>
          <Popover
            placement="bottomLeft"
            rootClassName="custom-description-root"
            content={
              <AssetSummaryPopover content={content} tags={false} fromWhere={FromWhere.Source} />
            }
            trigger="hover">
            <strong
              className={`${"cursor-pointer title-col float-title d-flex d-flex-middle"} `}
              onClick={() => handlePDFClick(content)}>
              {/* <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" /> */}
              <div
                className="file-bg"
                style={{ backgroundImage: `url(${content?.thumbnail_pre_signed_url})` }}></div>
              {text ? text : content?.original_file_name}
            </strong>
          </Popover>
        </>
      ),
    },
    {
      title: "Doc. Type",
      key: "docType",
      width: "110px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Doc. Type : </span>
          <span className="doc-type">
            <div className="profile-pic">
              <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />
            </div>
            {content?.file_type}
          </span>
        </>
      ),
    },
    {
      title: "Owner",
      key: "Owner",
      width: "150px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Owner : </span>
          {renderOwnerUI(content)}
        </>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      width: "120px",
      render: (date: string) => (
        <>
          <span className="td-label">Last Updated : </span>
          {getFormattedDate(date, "type1")}
        </>
      ),
    },
    // {
    //   title: "Summary",
    //   dataIndex: "summary",
    //   key: "summary",
    //   width: "300px",
    //   render: (text: string, content: IContent) => (
    //     <>
    //       <span className="td-label">Summary : </span>
    //       <span className="summary-span">
    //         {text?.length > ContentManage.InitSummaryLength && !content?.isShowFullSummary ? (
    //           <span className="content-manage-inner-span d-flex  d-flex-middle">
    //             <div className="content-manage-span">
    //               {text?.slice(0, ContentManage.InitSummaryLength)}
    //             </div>
    //             <span
    //               className="more"
    //               onClick={() => {
    //                 setSummaryDescirption(content?.summary);
    //                 setIsSummaryModal(content?.asset_id);
    //               }}>
    //               +more
    //             </span>

    //             <SummaryManagement
    //               isOpen={content?.asset_id === isSummaryModal}
    //               description={summaryDescription}
    //               setModalClose={handleSummaryModal}
    //             />
    //           </span>
    //         ) : (
    //           <span>{text}</span>
    //         )}
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "Tags",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "200px",
      render: (linkedTags: ITagsByAssestId, content: IContent) => (
        <AssetTags {...{ linkedTags, content }} fromWhere={FromWhere.Source} />
      ),
    },
    // {
    //   title: "Published/Draft",
    //   dataIndex: "isPublished",
    //   key: "isPublished",
    //   width: "15%",
    //   render: (isPublished: boolean | string, content: IContent, index: number) => (
    //     <>
    //       <span className="td-label">Published/Draft : </span>
    //       <span className="published-draft-label d-flex d-flex-middle d-flex-between">
    //         {renderAssetStatus(isPublished, content, index)}
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "Content Source",
      dataIndex: "contentSource",
      key: "contentSource",
      width: "160px",
      render: () => (
        <>
          <span className="td-label">Content Source : </span>
          <span>User Uploaded</span>
          {/* <span className="content-source-info">
            <h5>Title</h5>
            <p>https://greychaindesign.com/</p>
          </span> */}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "90px",
      fixed: "right",
      className: "fix-action",
      render: (_: any, content: IContent, index: number) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="extra-options">
              <div className="file-extra-options">
                {fileExtraOptionsTwo(content, index)}
                <a
                  className={`arrow-link-icon ${
                    getIsDisableEditContent(content) ? "disable-edit" : "arrow-link"
                  } `}>
                  <i
                    className="ri-arrow-right-s-line"
                    onClick={() => handleContentDetail(content)}></i>
                </a>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const tabDataGrid = () => {
    return (
      <>
        <div className="page-scroll">
          <Row className="tab-row-inner">
            {isContentTableLoading ? (
              <FileCardSkeleton />
            ) : !isEmpty(contents) ? (
              contents.map((item: IContent, index: number) => (
                <Fragment key={`${index}-${item.asset_id}`}>
                  <FileCard
                    data={item}
                    type={FileCardType.GENERATED}
                    extraOptions={fileExtraOptions(item)}
                    extraOptionsTwo={fileExtraOptionsTwo(item, index)}
                    hoverOptions={fileHoverOptions(item, index)}
                    onClick={() => handlePDFClick(item)}
                    fromWhere={FromWhere.Source}
                  />
                </Fragment>
              ))
            ) : (
              <NoContentData />
            )}
          </Row>
        </div>
      </>
    );
  };

  const tabDataList = () => {
    return (
      <>
        <Row className="tab-row-inner list-view">
          <Col span={24}>
            <Card className="global-table-card">
              {isContentTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table"
                  rowKey={"asset_id"}
                  columns={tableColumns}
                  dataSource={contents?.length ? contents : []}
                  locale={{ emptyText: <NoContentData /> }}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 235px)", x: "100" }}
                  pagination={false}
                />
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const handleAllBookmark = async () => {
    const isBookmarkedValue = sourceFilter?.isBookmarked ? false : true;
    dispatch(
      setUpdateSourceFilter({
        field: contentFilterField?.isBookmarked,
        value: isBookmarkedValue,
      })
    );
    await getAllContent({
      ...sourceFilter,
      isBookmarked: isBookmarkedValue,
    });
  };

  const toggleFilter = () => {
    dispatch(
      setUpdateAllSourceFilter({ ...localFilters, isFilterVisible: !localFilters?.isFilterVisible })
    );
  };

  const handleResetFilter = async (isToastShow = true) => {
    dispatch(clearSourceFilterWithoutBookmark());
    await getAllContent({ ...sourceFilterInitValue, isBookmarked: sourceFilter?.isBookmarked });
    isToastShow && toast.success("Filter successfully reset");
  };

  const handleApplyFilter = async () => {
    if (isSourceFilterEmpty(localFilters) && isEmpty(contents)) {
      handleResetFilter(false);
      return;
    }

    if (!isSourceFilterEmpty(localFilters)) {
      const res = await getAllContent({
        ...localFilters,
        isBookmarked: sourceFilter?.isBookmarked,
      });
      if (res) {
        dispatch(setUpdateAllSourceFilter(localFilters));
        dispatch(
          setUpdateSourceFilter({
            field: contentFilterField?.isApplied,
            value: true,
          })
        );
        dispatch(
          setUpdateSourceFilter({
            field: contentFilterField?.isFilterChanged,
            value: false,
          })
        );
        toast.success("Filter successfully applied");
      }
    } else {
      toast.error("Please select filters");
    }
  };

  useEffect(() => {
    setLocalFilters(sourceFilter);
  }, [sourceFilter]);

  //reload contents for selected filter comes from the conversation
  useEffect(() => {
    let routeState = location.state;
    if (
      !isEmpty(routeState) &&
      routeState?.isReloadData &&
      !isEmpty(localFilters) &&
      JSON.stringify(sourceFilter) === JSON.stringify(localFilters)
    ) {
      handleApplyFilter();
      navigate("", { state: { isReloadData: false } });
    }
  }, [location, localFilters]);

  const handlePDFOpen = () => {
    setIsShowPDF(true);
  };

  const handlePDFClick = (item: IContent) => {
    setViewedContent(item);
    if (item?.file_type === null) {
      toast.error("URL can't be viewed.");
    } else if (Images.includes(item?.file_type || "")) {
      setVisible(true);
    } else if (item?.file_type === "xls" || item?.file_type === "xlsx") {
      toast.error("The Excel file is not supported. Please download it to view.");
    } else {
      handleOpenPdfDoc(
        item?.asset_id,
        item.file_name,
        item?.pre_signed_url,
        item?.title,
        item.total_pages,
        false
      );
      handlePDFOpen();
    }
  };

  const handlePDFClose = () => {
    setIsShowPDF(false);
    setViewedContent(contentByIdInitialValue);
  };

  const isShowPagination = () => {
    return (
      contents && contents.length && (totalContentsCount || 1) / PageLimit.ContentManagement > 1
    );
  };

  const isTopBarVisible = () => {
    return (
      localFilters &&
      (!isMyContentChatFilterEmpty(localFilters) || !isSourcesChatFilterEmpty(localFilters))
    );
  };

  return (
    <Flex
      className={`handle-layout inner-app-wrap sources-page ${
        !isShowPagination() ? "no-pagination" : ""
      } ${!isTopBarVisible() ? "no-topbar" : ""}`}
      vertical>
      <PageHeader
        title="Sources"
        isRelevantAsset={false}
        content={
          <>
            <div onClick={handleAllBookmark}>
              <BookmarkIcon isActive={sourceFilter?.isBookmarked ? true : false} />
            </div>

            <Button
              type="text"
              className="filter-toggle-btn"
              onClick={toggleFilter}
              icon={<i className="ri-equalizer-fill"></i>}>
              Filters
            </Button>

            <Button
              className="btn upload-btn"
              type="primary"
              onClick={() => setIsOpenUploadAsset(true)}>
              Upload
            </Button>

            <div className="view-change">
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.GRID ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.GRID))}>
                <i className="ri-layout-grid-fill"></i>
              </Button>
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.LIST ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.LIST))}>
                <i className="ri-list-unordered"></i>
              </Button>
            </div>
          </>
        }
      />

      {localFilters?.isFilterVisible && (
        <motion.div
          className="content-source-filter"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}>
          <ContentSourceFilter
            isShowContentType={false}
            isShowContentStatus={false}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25 }}>
        <div className="relevant-assets-wrap">
          <Card className="relevant-assets">
            <div className="top-bar d-flex d-flex-middle d-flex-right" hidden={!isTopBarVisible()}>
              {contents && contents?.length > 0 && (
                <div className="ant-tabs-nav-container" hidden={true}>
                  <Checkbox
                    className="select-label"
                    checked={getSelectedContents().length === getEditableContents().length}
                    indeterminate={
                      getSelectedContents().length &&
                      getSelectedContents().length < getEditableContents().length
                        ? true
                        : false
                    }
                    onChange={handleSelectAllContents}>
                    {getSelectedContents().length ? (
                      `${getSelectedContents().length} Out of ${contents?.length} selected`
                    ) : (
                      <>
                        Select All <span>(Showing {contents?.length} results)</span>
                      </>
                    )}
                  </Checkbox>
                </div>
              )}
              {!isContentTableLoading && (
                <div className="ant-tabs-extra-content">
                  <div className="btn-wrap">
                    <ContentChatButton {...{ localFilters }} />
                  </div>
                </div>
              )}
            </div>

            {viewType === ViewType.GRID ? tabDataGrid() : tabDataList()}
            {isShowPagination() ? (
              <div className="bottom-bar d-flex d-flex-middle d-flex-right">
                <CustomPagination
                  currentPage={currentPage}
                  total={totalContentsCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.ContentManagement}
                />
              </div>
            ) : (
              <></>
            )}
          </Card>
        </div>
      </motion.div>

      {isOpenUploadAsset && (
        <UploadAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          fromWhere={FromWhere.Source}
          toWhere={PATHS.myContent}
        />
      )}

      {["png", "jpg"].includes(viewedContent?.file_type || "") && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${viewedContent?.thumbnail_pre_signed_url}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}

      {/* PDF Modal */}
      <Modal
        className="pdf-modal detail-open"
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={isShowPDF}>
        <PDFViewer isRelevantAsset={true} btnClick={() => handleContentDetail(viewedContent)} />
      </Modal>

      {/* delete Content modal */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteSource}
        message="Are you sure you want to delete this source?"
        title="Delete Source"
      />
      {/* Multiple contents delete */}
      {/* <DeleteModal
        isLoading={isMultipleContentDeleteLoading}
        isModalOpen={isOpenMultipleDelete}
        handleCancel={() => setIsOpenMultipleDelete(false)}
        handleSubmit={handleMultipleContentsDelete}
        message="Are you sure you want to delete these contents?"
        title="Delete Contents"
      /> */}
    </Flex>
  );
};

export default Sources;
