import { IContentFilter } from "../../store/contentManagement/content.interface";
import { isEmpty } from "../helpers";

export const isSourceFilterEmpty = (localFilters: IContentFilter) =>
  isEmpty(localFilters?.assetType) &&
  isEmpty(localFilters?.documentType) &&
  isEmpty(localFilters?.function) &&
  isEmpty(localFilters?.industry) &&
  isEmpty(localFilters?.startDate) &&
  isEmpty(localFilters?.endDate);

export const isMyContentFilterEmpty = (localFilters: IContentFilter) =>
  // isEmpty(localFilters?.contentType) &&
  isEmpty(localFilters?.assetStatus) &&
  isEmpty(localFilters?.assetType) &&
  isEmpty(localFilters?.documentType) &&
  isEmpty(localFilters?.function) &&
  isEmpty(localFilters?.industry) &&
  isEmpty(localFilters?.startDate) &&
  isEmpty(localFilters?.endDate);

export const iAdminFilterEmpty = (localFilters: IContentFilter) =>
  isMyContentFilterEmpty(localFilters);

export const isMyContentChatFilterEmpty = (localFilters: IContentFilter) =>
  (localFilters?.isBookmarked === false || localFilters?.isBookmarked === "FALSE" || localFilters?.isBookmarked === "") &&
  (isEmpty(localFilters?.contentType) || !localFilters?.contentType.includes("personal")) &&
  !localFilters?.isApplied;

export const isSourcesChatFilterEmpty = (localFilters: IContentFilter) =>
  (localFilters?.isBookmarked === false || localFilters?.isBookmarked === "FALSE" || localFilters?.isBookmarked === "") &&
  !localFilters?.isApplied;