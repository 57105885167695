import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Card, Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FileCardSkeleton } from "../../fileCard/fileCardSkeleton";

import { RootState } from "../../../store";
import { IContent, ITagsByAssestId } from "../../../store/contentManagement/content.interface";
import { IHomeBookmarkedContent } from "../../../store/home/home.interface";
import {
  contentByIdInitialValue,
  setContentById,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";
import {
  setHomeBookmarkedContent,
  setOthersLookingAssets,
  setRecentlyViewedAssets,
} from "../../../store/home/homeSlice";
import { bookmarkContent, updateViewCount } from "../../../services/contentManagement";
import {
  BookmarkIcon,
  FromWhere,
  getIsContentFailed,
  getIsDisableEditContent,
  isEmpty,
  isNew,
  NoContentData,
  PATHS,
  renderFileTypeIcon,
} from "../../../shared";
import "./HomeSlider.scss";

interface IHomeSlider {
  isLoading?: boolean;
  title: string;
  description?: boolean;
  data: IContent[];
  settings?: any;
  fromWhere?: string;
}

const HomeSlider: FC<IHomeSlider> = ({
  isLoading,
  title,
  description = false,
  data,
  settings,
  fromWhere = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { appLayoutView } = useSelector((state: RootState) => state.app);
  const { contents } = useSelector((state: RootState) => state.content);
  const { homeBookmarkedContent } = useSelector((state: RootState) => state.home);
  
  const ref = useRef<any>(null);
  const [showCount, setShowCount] = useState<any>(5.3);
  const [elementRect, setElementRect] = useState<number>(0);

  const sliderPrevBtn = () => {
    return (
      <button type="button">
        <i className="ri-arrow-left-s-line"></i>
      </button>
    );
  };

  const sliderNextBtn = () => {
    return (
      <button type="button">
        <i className="ri-arrow-right-s-line"></i>
      </button>
    );
  };

  useEffect(() => {
    if (ref?.current) {
      const { width } = ref?.current?.getBoundingClientRect();
      setElementRect(width + 21);
      const num = elementRect / 230;
      setShowCount(+num.toFixed(2));
    }
  }, [elementRect, appLayoutView]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 250,
    slidesToShow: showCount,
    slidesToScroll: 1,
    // variableWidth: true,
    prevArrow: sliderPrevBtn(),
    nextArrow: sliderNextBtn(),
    ...settings,
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: { isEditMode, isReadOnly: content ? getIsContentFailed(content) : false },
    });
  };

  const noClick = (e: any) => {
    e.stopPropagation();
  };

  const handleUpdatedViewedList = (content: IContent, index: number, isBookmarked: boolean) => {
    const updatedContent = {
      ...content,
      is_bookmarked: isBookmarked,
    };
    const contentsValues = [...data];
    contentsValues[index] = updatedContent;

    if (fromWhere === FromWhere.RecentlyViewed) {
      dispatch(setRecentlyViewedAssets(contentsValues));
    } else if (fromWhere === FromWhere.OthersViewed) {
      dispatch(setOthersLookingAssets(contentsValues));
    }
  };

  const handleUpdateHomeBookmark = (content: IContent, isBookmarked: boolean) => {
    const updatedContent = {
      ...content,
      is_bookmarked: isBookmarked,
    };
    const values = [...homeBookmarkedContent];
    let updatedBookmark: IHomeBookmarkedContent[] = [];

    if (!isEmpty(values)) {
      values.forEach((item) => {
        if (item?.asset_id === updatedContent.asset_id) {
          updatedBookmark.push(updatedContent);
        } else {
          updatedBookmark.push(item);
        }
      });
      updatedBookmark.push(updatedContent);
    } else {
      updatedBookmark.push(updatedContent);
    }

    updatedBookmark = updatedBookmark.filter((item) => item.is_bookmarked);
    dispatch(setHomeBookmarkedContent(updatedBookmark));
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const isBookmarked = content?.is_bookmarked ? false : true;
    const res = await bookmarkContent(content.asset_id, isBookmarked);
    if (res) {
      handleUpdatedViewedList(content, index, isBookmarked);
      handleUpdateHomeBookmark(content, isBookmarked);
    }
  };

  const renderAssetType = (linkedTags: ITagsByAssestId) => {
    const ids = linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];
    const assetTypeId = ids && ids.length ? ids[0] : null;

    let label = "";
    if (!isEmpty(linkedTags?.taxonomyTree) && assetTypeId) {
      linkedTags?.taxonomyTree.forEach((assetType) => {
        if (assetType.taxonomyNodeId === assetTypeId) {
          label = assetType.taxonomyNodeName;
        }
      });
    }

    return <p className="file-cat">{label ? label : null}</p>;
  };

  const renderContentCard = (item: IContent, index: number) => {
    return (
      <Card
        className="file-card cursor-pointer variant"
        onClick={() => handleViewEditContentNavigate(item?.asset_id)}>
        <div className="file-preview">
          <div
            className="file-bg"
            style={{ backgroundImage: `url(${item?.thumbnail_pre_signed_url})` }}></div>
          <div className="file-prev-inner">
            <div className="thumb-labels">
              {isNew(item?.uploadedOn) && <div className="thumbnail-label">Newly added</div>}
            </div>
            <div className="extra-options" onClick={noClick}>
              <div onClick={() => handleBookmark(item, index)}>
                <BookmarkIcon
                  disabled={isLoading || getIsDisableEditContent(item)}
                  isActive={item?.is_bookmarked}
                />
              </div>
            </div>
            <div className="file-icon">
              <img src={renderFileTypeIcon(item?.file_type)} alt="Icon" />
            </div>
          </div>
        </div>
        <div className="file-info">
          <h6 className="file-name">{item?.title ? item?.title : item?.file_name}</h6>
          <div className="file-data">{renderAssetType(item?.linkedTags)}</div>
        </div>
      </Card>
    );
  };

  return (
    <Card className="slider-card-wrapper">
      <h2 className="wrapper-title">{title}</h2>
      {!isEmpty(data) && description ? (
        <></>
      ) : (
        // <p className="wrapper-subText">
        //   Because last you had viewed <strong>{data[0]?.title || data[0]?.file_name}</strong> file.
        // </p>
        ""
      )}
      <div className="slider-wrapper" ref={ref}>
        {isLoading ? (
          <div className="slider-skeleton">
            <FileCardSkeleton />
          </div>
        ) : !isEmpty(data) ? (
          <div className="slider-inner-wrapper">
            <Carousel {...sliderSettings}>
              {data.map((item: IContent, index: number) => (
                <Fragment key={index}>{renderContentCard(item, index)}</Fragment>
              ))}
            </Carousel>
          </div>
        ) : (
          <NoContentData />
        )}
      </div>
    </Card>
  );
};

export default HomeSlider;
