import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Card, Col, Dropdown, Flex, Input, Row, Switch, Table, TableProps } from "antd";
import "./DataConnection.scss";
import { PageHeader } from "../../../components";
import SummaryManagement from "../contentManagement/SummaryManagement";
import { DataConnectionModal } from "../../../components/dataConnectionModal";
import { getAllSources, getSourcesById } from "../../../services/dataConnection";
import { CustomPagination, IMAGES, isEmpty, PATHS, truncateString } from "../../../shared";

const DataConnection = () => {
  const [isDataModalOpen, setIsDataModalOpen] = useState(false);
  const [isSummaryModal, setIsSummaryModal] = useState<number>(-1);
  const [summaryDescription, setSummaryDescirption] = useState<string>("");
  const [dataConnectionList] = useState([{}, {}, {}, {}, {}, {}, {}]);

  useEffect(() => {
    getAllSources();
    getSourcesById(1);
  }, []);

  const navigate = useNavigate();

  // const handleLogin = async (loginType: string) => {
  //   try {
  //     // Perform login based on the loginType
  //     if (loginType === "popup") {
  //       await instance.loginPopup(loginRequest);
  //     } else if (loginType === "redirect") {
  //       await instance.loginRedirect(loginRequest);
  //     }

  //     // Get all accounts
  //     const accounts = instance.getAllAccounts();

  //     // Set the active account if not already set
  //     if (accounts.length === 0) {
  //       throw new Error("No accounts found. Please login.");
  //     }
  //     const activeAccount: AccountInfo = accounts[0];
  //     instance.setActiveAccount(activeAccount);

  //     // Acquire the token silently
  //     const response = await instance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: activeAccount,
  //     });

  //     const accessToken: string = response.accessToken;

  //     // Fetch site ID, drive ID, and SharePoint files sequentially
  //     const siteId = await getSiteId(accessToken, socialLogin?.domain, socialLogin?.relativeUrl);
  //     const driveId = await getDriveId(accessToken, siteId);
  //     await getSharepointFiles(accessToken, siteId, driveId);
  //   } catch (error) {
  //     console.error("Error during login or fetching SharePoint files:", error);
  //     toast.error("Failed to fetch SharePoint files");
  //   }
  // };

  // const getSiteId = async (accessToken: string, domain: string, relativeUrl: string) => {
  //   const response = await axios.get(
  //     `https://graph.microsoft.com/v1.0/sites/${domain}:${relativeUrl}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );

  //   return response.data.id;
  // };

  // const getDriveId = async (accessToken: string, siteId: string) => {
  //   const response = await axios.get(`https://graph.microsoft.com/v1.0/sites/${siteId}/drives`, {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   });

  //   return response.data.value[0].id;
  // };

  // const getSharepointFiles = async (accessToken: string, siteId: string, driveId: string) => {
  //   const response = await axios.get(
  //     `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );

  //   setFiles(response?.data?.value);
  // };

  // Table view
  const columns: TableProps["columns"] = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "260px",
      render: () => (
        <>
          <span className="td-label">Title : </span>
          <strong
            onClick={() => {
              navigate(`${PATHS.adminDataConnectionDetails}/1`, {
                state: { isEditMode: false },
              });
            }}>
            <u style={{ cursor: "pointer" }}> Policies and Procedures</u>
          </strong>
        </>
      ),
    },
    {
      title: "Connecter Name",
      dataIndex: "connecterName",
      key: "connecterName",
      width: "210px",
      render: () => (
        <>
          <span className="td-label">Connecter Name : </span>
          <div className="connecter-name d-flex d-flex-middle">
            <img src={IMAGES.sharepointIcon}></img> Share Point
          </div>
        </>
      ),
    },
    {
      title: "Attributes",
      dataIndex: "attributes",
      key: "attributes",
      width: "240px",
      render: () => {
        return (
          <>
            <span className="td-label">Attributes : </span>
            <div className="attributes-link">https://greychaindesign.com/</div>
          </>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      width: "300px",
      render: (
        text = "Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the",
        row
      ) => {
        const maxLength = 60;
        return (
          <>
            <span className="td-label">Summary : </span>
            <div className="tree-discription d-flex">
              <span>{truncateString(text, maxLength)}</span>
              {text?.length > maxLength && (
                <u
                  onClick={() => {
                    setSummaryDescirption(row?.purpose);
                    setIsSummaryModal(row?.taxonomyId);
                  }}
                  className="more-tag">
                  +more
                </u>
              )}

              <SummaryManagement
                isOpen={isSummaryModal === row?.taxonomyId}
                description={summaryDescription}
                setModalClose={handleSummaryModal}
              />
            </div>
          </>
        );
      },
    },

    {
      title: "Status",
      key: "status",
      width: "120px",
      dataIndex: "status",
      render: () => (
        <>
          <span className="td-label">Status : </span>
          <div className="actions-icons-wrap actions-icons-2 d-flex d-flex-between">
            <Switch />
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "1",
                    label: "Edit",
                  },
                  {
                    key: "2",
                    label: "Delete",
                  },
                ],
              }}
              overlayStyle={{ width: 80 }}
              overlayClassName="table-action-dropdown table-action-dropdown-user"
              placement="bottomRight">
              <Button className="more-action">
                <i className="ri-more-2-fill"></i>
              </Button>
            </Dropdown>
          </div>
        </>
      ),
    },
  ];
  const handlePagination = (_page: number) => {};

  const handleSummaryModal = () => {
    setIsSummaryModal(-1);
    setSummaryDescirption("");
  };
  return (
    <>
      <Flex className="admin-inner-wrap data-connection-page" vertical>
        <PageHeader
          title="Data Connections"
          isRelevantAsset={false}
          content={
            <>
              <Input
                type="search"
                className="header-search"
                placeholder="Search by name or email..."
                prefix={<i className="ri-search-line"></i>}
              />
              <Button
                className="btn"
                type="default"
                onClick={() => setIsDataModalOpen(true)}
                icon={<i className="ri-upload-2-fill"></i>}>
                Connect new source
              </Button>
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Row>
            <Col span={24}>
              <Card
                className={`global-table-card ${
                  isEmpty(dataConnectionList) ? "global-table-empty" : ""
                }`}>
                <Table
                  className="global-table responsive-table data-connection-table"
                  columns={columns}
                  dataSource={dataConnectionList}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
                {!isEmpty(dataConnectionList) ? (
                  <CustomPagination
                    currentPage={10}
                    total={10}
                    pageSize={10}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </motion.div>
      </Flex>

      <DataConnectionModal
        isLoading={false}
        isModalOpen={isDataModalOpen}
        handleCancel={() => setIsDataModalOpen(false)}
        handleSubmit={() => console.log("submit")}
        title="Connect the Source"
      />
    </>
  );
};

export default DataConnection;
