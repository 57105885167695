import { useState, useRef, ChangeEvent, Fragment } from "react";
import {
  Modal,
  Form,
  Card,
  Divider,
  Button,
  Progress,
  Checkbox,
  Radio,
  RadioChangeEvent,
  GetProp,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import "./UploadAssets.scss";
import {
  setFileUploadProgress,
  setURLUploadProgress,
} from "../../store/contentManagement/contentSlice";
import { uploadAsset, uploadAssetURL } from "../../services/contentManagement";
import { RootState } from "../../store";
import {
  AiOptions,
  FileLocation,
  IMAGES,
  IUploadFormProps,
  PATHS,
  RunVisionFileType,
  UploadAssetState,
  getUserId,
} from "../../shared";

import "./UploadAssets.scss";

interface IUploadAssets {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSelectedFiles?: (file: File[]) => void;
  uploadMultiple?: boolean;
}

const UploadMultipleAssets = ({
  isModalOpen,
  setIsModalOpen,
  setSelectedFiles,
  uploadMultiple,
}: IUploadAssets) => {
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [fileList, setFileList] = useState<File[]>([]);
  const [URLValue, setURLValue] = useState<string>("");

  const [runVision, setRunVision] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [isShowRunVision, setIsShowRunVision] = useState<boolean>(false);
  const [autoSummarize, setAutoSummarize] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { fileUploadProgress } = useSelector((state: RootState) => state.content);

  const { uploadFileList } = useSelector((state: RootState) => state.conversationalSearch);
  const [uploadingState, setUploadingState] = useState<UploadAssetState>(UploadAssetState.INITIAL);
  const [fileLocation, setFileLocation] = useState<FileLocation>(FileLocation.USER);
  const [aiSelectedValues, setAiSelectedValues] = useState<any[]>([]);
  // const [isUrlValid, setIsUrlValid] = useState(false);

  // const urlPattern =
  //   /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;

  const locationOptions = [
    { label: "Organization", value: FileLocation.ORGANISATION },
    { label: "For Me", value: FileLocation.USER },
  ];

  const aiOptions = [
    {
      label: "Auto Summarize",
      value: AiOptions.SUMMARIZE,
      icon: IMAGES.icon1,
      tooltip: "Automatically generate concise summaries of your uploaded documents.",
      disabled: false,
    },
    {
      label: "AutoTag Taxonomy",
      value: AiOptions.AUTO_TAG_TAXONOMY,
      icon: IMAGES.icon2,
      tooltip:
        "Automatically assign relevant tags and categories to your content for better organization.",
      disabled: true,
    },
    {
      label: "Process using Vision Models",
      value: AiOptions.VISION_MODEL,
      icon: IMAGES.icon3,
      tooltip: "Analyze images and extract meaningful information using advanced vision AI models.",
      disabled: !isShowRunVision,
    },
  ];

  const handleClose = () => {
    form.resetFields();
    setFileName("");
    setIsModalOpen(false);
    setFile(null);
    handleRemoveFile();
    setRunVision(false);
    setFileType("");
    setIsShowRunVision(false);
    setUploadingState(UploadAssetState.INITIAL);
    setAiSelectedValues([]);
  };

  const handleProceed = () => {
    switch (uploadingState) {
      case UploadAssetState.FILE:
        return onFinish();

      case UploadAssetState.URL:
        return onFinishURL();
    }
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setRunVision(false);
    setIsShowRunVision(false);

    if (e.target.files) {
      const file = e.target.files[0];
      if (uploadMultiple) {
        const list = [];
        for (let i = 0; i < e.target.files.length; i++) {
          list.push(e.target.files[i]);
        }
        setFileList(list);
      }
      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);

      setIsShowRunVision(RunVisionFileType.includes(fileType));
      setFileType(fileType);
      setFile(file);
      setFileName(file.name);
      setUploadingState(UploadAssetState.FILE);
      setAutoSummarize(true);
    }
  };

  const handleRemoveFile = () => {
    form.resetFields();
    setUploadingState(UploadAssetState.INITIAL);
    setFileName("");
    setIsShowRunVision(false);
    setRunVision(false);
    setAutoSummarize(false);
    setAiSelectedValues([]);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onFinish = async () => {
    setProceedLoading(true);
    setIsUploading(true);
    dispatch(setFileUploadProgress(0));

    if (uploadMultiple && fileList.length) {
      if (setSelectedFiles) setSelectedFiles(fileList);
      for (let i = 0; i < fileList.length; i++) {
        const fileObject = fileList[i];
        const formData = new FormData();
        formData.append("file", fileObject);
        const extension = fileObject?.name?.substring(fileObject?.name.lastIndexOf(".") + 1);

        const formParams: IUploadFormProps = {
          formData,
          userId: getUserId(),
          title: "",
          summary: "",
          fileType: extension,
          fileLevel: fileLocation,
          visionProcessing: runVision,
          isSurveyData: false,
          generateSummary: autoSummarize,
          linkedTagIds: [],
          tags: [],
        };

        await uploadAsset(
          formParams,
          navigate,
          setUploadingState,
          "",
          "",
          fileObject.size,
          uploadMultiple,
          location.pathname.includes(PATHS.viewEditContentManagement)
        );

        if (fileList.length === i + 1) {
          setIsModalOpen(false);
        }
      }
    } else if (!!file) {
      const formData = new FormData();
      formData.append("file", file);

      const formParams: IUploadFormProps = {
        formData,
        userId: getUserId(),
        title: "",
        summary: "",
        fileType: fileType,
        fileLevel: fileLocation,
        visionProcessing: runVision,
        isSurveyData: false,
        generateSummary: false,
        linkedTagIds: [],
        tags: [],
      };

      await uploadAsset(
        formParams,
        navigate,
        setUploadingState,
        "",
        "",
        file.size,
        uploadMultiple,
        location.pathname.includes(PATHS.viewEditContentManagement)
      );

      setIsModalOpen(false);
    } else {
      setUploadingState(UploadAssetState.FAILED);
      toast.error("Failed to upload asset");
    }
    setIsUploading(false);
    setProceedLoading(false);
    handleRemoveFile();
  };

  const onFinishURL = async () => {
    setProceedLoading(true);
    dispatch(setURLUploadProgress(0));

    const uploadAssetsViaURL = URLValue;

    if (uploadAssetsViaURL && uploadAssetsViaURL !== "") {
      await uploadAssetURL(
        getUserId(),
        uploadAssetsViaURL,
        navigate,
        setUploadingState,
        fileLocation
      );
      setIsModalOpen(false);
    } else {
      setUploadingState(UploadAssetState.FAILED);
      setURLValue("");
      toast.error("Failed to upload asset");
    }
    setProceedLoading(false);
  };

  // const handleChangeURL = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e?.target?.value?.trim()) {
  //     setURLValue(e?.target?.value?.trim());
  //     setUploadingState(UploadAssetState.URL);

  //     if (urlPattern.test(e?.target?.value?.trim())) {
  //       setIsUrlValid(false);
  //     } else {
  //       setIsUrlValid(true);
  //     }
  //   } else {
  //     setURLValue("");
  //     setUploadingState(UploadAssetState.INITIAL);
  //     setIsUrlValid(false);
  //   }
  // };

  const renderAsserUploadingState = (
    fileName: string,
    fileUploadProgress: number,
    HeadingText?: string,
    index?: number,
    isUploaded?: boolean
  ) => {
    console.log(fileUploadProgress);

    return (
      <div className="uploading-field">
        {index === 0 ? (
          <p className="upload-status">
            {HeadingText}
            {/* {uploadingState === UploadAssetState.FILE || uploadFileList?.length < (index || 0)
            ? "Selected file"
            : fileUploadProgress < 100 || uploadFileList?.length === index
            ? "Uploading..."
            : "Uploaded"} */}
          </p>
        ) : (
          ""
        )}
        <div className="file-container">
          <span>
            <i className="ri-attachment-line browseIcon"></i>
            <span>{fileName}</span>
          </span>
          {uploadingState === UploadAssetState.FILE ? (
            <div className="btn-wrapper">
              <Button type="text" className="text-btn remove-file" onClick={handleRemoveFile}>
                <i className="ri-delete-bin-fill"></i>
              </Button>
            </div>
          ) : (
            <></>
          )}

          {uploadingState === UploadAssetState.UPLOADING && index === 0 && !isUploaded ? (
            <Progress percent={fileUploadProgress} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const fileLocationChange = ({ target: { value } }: RadioChangeEvent) => {
    setFileLocation(value);
  };

  const aiOptionsChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues: any[]) => {
    setAiSelectedValues(checkedValues);
    checkedValues.includes(AiOptions.SUMMARIZE) ? setAutoSummarize(true) : setAutoSummarize(false);
    checkedValues.includes(AiOptions.VISION_MODEL) ? setRunVision(true) : setRunVision(false);
  };

  const renderFileUpload = () => {
    return (
      <Form
        name="uploadFile"
        onFinish={onFinish}
        requiredMark={false}
        size="large"
        className="form-container add-user-form">
        <Card className="input-card">
          <p className="input-label">Upload</p>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple={uploadMultiple || false}
              placeholder="Upload Asset"
              onChange={handleFileUpload}
            />
            <div className="upload-file-container">
              <div className="file-name">
                <span>
                  <i className="ri-attachment-line browseIcon"></i>
                  <span>Browse</span>
                </span>
              </div>

              <Button
                onClick={handleClickUploadFile}
                className="btn-primary fill-button btn-type2"
                type="primary"
                size="middle"
                disabled={isUploading}>
                Browse
              </Button>
            </div>
          </div>

          {/* <Button
            loading={isUploading}
            htmlType="submit"
            className="btn-primary btn-type2 upload-btn"
            type="primary"
            size="middle"
            disabled={fileName === ""}>
            Upload
          </Button> */}

          {uploadingState === UploadAssetState.FILE ||
          uploadingState === UploadAssetState.UPLOADING ||
          uploadingState === UploadAssetState.UPLOADED ? (
            <div className="file-upload-extra">
              {uploadMultiple
                ? fileList
                    .filter((e) => !uploadFileList?.find((f) => f.original_file_name != e.name))
                    .map((fileObj, index) => (
                      <Fragment key={index}>
                        {renderAsserUploadingState(
                          fileObj.name,
                          fileUploadProgress,
                          "Selected file",
                          index,
                          false
                        )}
                      </Fragment>
                    ))
                : renderAsserUploadingState(
                    fileName,
                    fileUploadProgress,
                    "Selected file",
                    1,
                    false
                  )}
              {uploadFileList.map((fileObj, index) => (
                <Fragment key={index}>
                  {renderAsserUploadingState(
                    fileObj.original_file_name,
                    fileUploadProgress,
                    "Uploaded file",
                    index,
                    true
                  )}
                </Fragment>
              ))}

              <div className="upload-location">
                <span className="label">Location :</span>
                <Radio.Group
                  options={locationOptions}
                  onChange={fileLocationChange}
                  value={fileLocation}
                  optionType="button"
                />
              </div>
              <Divider className="extra-divider" />
              <Checkbox.Group
                className="ai-options"
                value={aiSelectedValues}
                onChange={aiOptionsChange}>
                {aiOptions.map((item) => (
                  <Checkbox {...item}>
                    <img src={item.icon} className="ai-check-icon" alt="Icon" />
                    {item.label}
                    <Tooltip title={item.tooltip}>
                      <i className="ri-information-line"></i>
                    </Tooltip>
                  </Checkbox>
                ))}
              </Checkbox.Group>
              {/* <div className="run-vision-warpper">
                <Checkbox
                  onChange={handleChangeRunVision}
                  checked={runVision}
                  value={runVision}
                  disabled={!isShowRunVision}>
                  Run Vision
                </Checkbox>
              </div> */}
            </div>
          ) : (
            <></>
          )}
        </Card>
      </Form>
    );
  };

  const renderDynamicForm = () => {
    switch (uploadingState) {
      case UploadAssetState.FILE:
        return renderFileUpload();

      case UploadAssetState.UPLOADING:
        return renderFileUpload();

      case UploadAssetState.UPLOADED:
        return renderFileUpload();

      case UploadAssetState.FAILED:
        return (
          <>
            {renderFileUpload()}
            {/* {renderOR()} */}
            {/* {renderURLUpload()} */}
          </>
        );

      // case UploadAssetState.URL:
      //   return renderURLUpload();

      default:
        return (
          <>
            {renderFileUpload()}
            {/* {renderOR()} */}
            {/* {renderURLUpload()} */}
          </>
        );
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleClose}
      title="Upload Asset"
      centered
      footer={
        <>
          <Button onClick={handleClose} className="btn" type="default" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={proceedLoading}
            onClick={handleProceed}
            htmlType="submit"
            className="btn"
            type="primary"
            disabled={
              uploadingState === UploadAssetState.INITIAL ||
              uploadingState === UploadAssetState.FAILED
            }>
            Proceed
          </Button>
        </>
      }
      width={500}>
      <div className="container upload-asset-modal">
        {/* <div className="header">
          <p className="title">Upload Asset</p>
          {!(isUploading || isUploadingUrl) && (
            <span onClick={handleClose}>
              <img className="closeIcon" alt="close-icon" src={IMAGES.closeIcon} />
            </span>
          )}
        </div> */}

        <div>{renderDynamicForm()}</div>
      </div>
    </Modal>
  );
};

export default UploadMultipleAssets;
