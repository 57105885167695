import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAccessGroupProps, IaccessGroups } from "./accessGroup.interface";
import { UserRoles } from "../../shared";

export const accessGroupsInitialState: IaccessGroups = {
  id: "new",
  title: "",
  role: UserRoles.User,
  industry: [],
  function: [],
  searchView: false,
  download: false,
  upload: false,
  edit: false,
  status: false,
  isNew: true,
  isEdit: true,
}

const initialState: IAccessGroupProps = {
  accessGroups: [],
  isAccessGroupsLoading: false,
};

export const accessGroup = createSlice({
  name: "accessGroup",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAccessGroups: (state, action: PayloadAction<IaccessGroups[]>) => {
      state.accessGroups = action.payload;
    },

    setIsAccessGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.isAccessGroupsLoading = action.payload;
    },
  },
});

export const { setAccessGroups, setIsAccessGroupsLoading } = accessGroup.actions;

export default accessGroup.reducer;
