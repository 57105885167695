import { Button, Card } from "antd";
import { Link } from "react-router-dom";
import { PATHS, getLocalStorageItem } from "../../shared";
import "./ErrorPage.scss";

const ErrorPage = () => {
  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  const renderBackBtn = () => {
    let backLink = PATHS.login;
    let backText = "Back to login";

    // isLoggedIn
    if (userDetails?.id) {
      backLink = PATHS.home;
      backText = "Back to home";
    }

    return (
      <Link to={backLink}>
        <Button type="primary" className="btn">
          {backText}
        </Button>
      </Link>
    );
  };

  return (
    <div className="error-page">
      <Card className="error-inner">
        <h2>404 Error</h2>
        <p>Oops! The requested URL was not found on this server.</p>
        {renderBackBtn()}
      </Card>
    </div>
  );
};

export default ErrorPage;
