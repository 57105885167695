import { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Col, Flex, Row } from "antd";
import { HomeSlider, HomeFeatured } from "../../components/home";
import { RootState } from "../../store";
import { getOthersLookingAssets, getRecentlyViewedAssets } from "../../services/home";
import { FromWhere, IMAGES, getLocalStorageItem, getUserFirstName } from "../../shared";
import "./HomePage.scss";

const sliderSettings = {
  // slidesToShow: 3.8,
};

const HomePage = () => {
  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const parsedUserDetails = localStorageItem && JSON.parse(localStorageItem);

  const {
    isRecentlyViewedAssetsLoading,
    recentlyViewedAssets,
    isOthersLookingAssetsLoading,
    othersLookingAssets,
  } = useSelector((state: RootState) => state.home);

  useEffect(() => {
    getRecentlyViewedAssets();
    getOthersLookingAssets();
  }, []);

  const renderHeader = () => {
    return (
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="header-page">
        <div className="header-inner">
          <h1 className="header-title">
            {!!parsedUserDetails?.name ? `Hi, ${getUserFirstName(parsedUserDetails?.name)} ` : `Hi`}
            <img className="handWave" src={IMAGES.handWave} alt="logo" />
          </h1>
          <div className="header-sub-text">
            Here's what's new and trending in your knowledge hub today.
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <Flex className="home-page-wrap" vertical>
      {renderHeader()}

      <div className="content-wrap">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.25 }}>
          <Row className="home-featured-row">
            <Col span={24}>
              <HomeFeatured />
            </Col>
          </Row>
        </motion.div>

        {/* <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.25 }}>
          <Row className="content-Browsing-wrap content-col-wrap">
            <Col span={10}>
              <HomeStats />
            </Col>
            <Col span={14}>
              <HomeSlider
                title="Continue browsing"
                isLoading={isRecentlyViewedAssetsLoading}
                data={recentlyViewedAssets}
                description={true}
                fromWhere={FromWhere.RecentlyViewed}
              />
            </Col>
          </Row>
        </motion.div> */}

        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}>
          <Row className="content-Browsing-wrap content-col-wrap-two multiple-col">
            <Col span={24}>
              <HomeSlider
                title="Continue browsing"
                isLoading={isRecentlyViewedAssetsLoading}
                data={recentlyViewedAssets}
                description={true}
                fromWhere={FromWhere.RecentlyViewed}
              />
              <HomeSlider
                title="See what others are looking at"
                isLoading={isOthersLookingAssetsLoading}
                data={othersLookingAssets}
                fromWhere={FromWhere.OthersViewed}
                settings={sliderSettings}
              />
              {/* <HomeSlider
                title="Recommended for you"
                isLoading={isRecentlyViewedAssetsLoading}
                data={recentlyViewedAssets}
                settings={sliderSettings}
              /> */}
            </Col>

            {/* <Col span={8}>
              <BookMarkedAsset />
            </Col> */}
          </Row>
        </motion.div>
      </div>
    </Flex>
  );
};

export default HomePage;
