import { FC } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Row } from "antd";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { setRelevantAssetView } from "../../store/contentManagement/contentSlice";
import { FromWhere, PATHS, RelevantAssetView } from "../../shared";
import "./PageHeader.scss";

interface IPageHeaderProps {
  title: string;
  content?: any;
  isRelevantAsset?: boolean;
  isHeadBack?: boolean;
  breadcrumbView?: boolean;
  fromWhere?: string;
}

const PageHeader: FC<IPageHeaderProps> = ({
  title,
  content,
  isRelevantAsset,
  isHeadBack,
  breadcrumbView,
  fromWhere = "",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseRelevantPageClose = () => {
    document.body.classList.remove("relevant-assets-page"); // Toggle the class on body tag
    dispatch(setRelevantAssetView(RelevantAssetView.None));
  };

  const handleBack = () => {
    if (fromWhere === FromWhere.MyContent) {
      navigate(PATHS.myContent, { state: { isDataPersist: true } });
    } else if (fromWhere === FromWhere.Source) {
      navigate(PATHS.sources, { state: { isDataPersist: true } });
    } else if (fromWhere === FromWhere.Home) {
      navigate(PATHS.home);
    } else if (fromWhere === FromWhere.AdminContentManagement) {
      navigate(PATHS.adminContentManagement, { state: { isDataPersist: true } });
    } else {
      navigate(-1);
    }
  };

  return (
    <motion.div
      className="page-header"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}>
      <Row className="page-header-inner" align="middle">
        <Col span={10}>
          {!breadcrumbView ? (
            <div className="d-flex" style={{ gap: "15px" }}>
              {isHeadBack && (
                <div className="head-back-icon" onClick={handleBack}>
                  <i className="ri-arrow-left-s-line"></i>
                </div>
              )}
              <h2 className="page-header-title">{title}</h2>
            </div>
          ) : (
            <div className="breadcrumb-wrap">
              <ul>
                <li>Topic Page</li>
                <li>Industry</li>
                <li>Aerospace</li>
              </ul>
            </div>
          )}
        </Col>

        <Col span={14} className="page-header-right d-flex d-flex-right d-flex-middle">
          {content && content}
          {isRelevantAsset && (
            <Button
              type="text"
              className="page-close-btn"
              icon={<i className="ri-close-fill"></i>}
              onClick={handleCloseRelevantPageClose}
            />
          )}
        </Col>
      </Row>
    </motion.div>
  );
};

export default PageHeader;
