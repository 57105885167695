import { FromWhere, PATHS } from "../../../shared";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNavigate } from "react-router-dom";
import { ConversationFileCard } from "./ConversationFileCard";

const PublishingQueue: FC = () => {
  const navigate = useNavigate();
  const { uploadFileList, selectedChatHistory } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  return (
    <>
      <div className="chat-body-content">
        <ul>
          {uploadFileList.map((file, index) => (
            <li
              key={`file-${index}`}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${PATHS.viewContentManagementByUser}/${file?.asset_id}`, {
                  state: {
                    fromWhere: FromWhere.ConversationalSearch,
                    selectedChatHistoryId: selectedChatHistory?.id,
                    releventAssetId: file.asset_id,
                    isEditMode: true,
                    isNewAssetUpload: true
                  },
                });
              }}>
              <ConversationFileCard {...{ file, isPublishInQueue: true }} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default PublishingQueue;
