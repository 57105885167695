import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IConfigurationList, IUser, IUserSliceProps } from "./user.interface";
import { PURGE } from "redux-persist";

const initialState: IUserSliceProps = {
  allUsers: [],
  userTableLoading: false,
  userDeleteLoading: false,
  totalUsersCount: 0,
  userCurrPage: 1,
  configurationList: [],
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllUsersDetails: (state, action: PayloadAction<IUser[]>) => {
      state.allUsers = action.payload;
    },
    setUserTableLoading: (state, action: PayloadAction<boolean>) => {
      state.userTableLoading = action.payload;
    },
    setUserDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.userDeleteLoading = action.payload;
    },
    setTotalUsersCount: (state, action: PayloadAction<number>) => {
      state.totalUsersCount = action.payload;
    },
    setUserCurrPage: (state, action: PayloadAction<number>) => {
      state.userCurrPage = action.payload;
    },
    setConfigurationList: (state, action: PayloadAction<IConfigurationList[]>) => {
      state.configurationList = action.payload;
    },
  },
});

export const {
  setAllUsersDetails,
  setUserTableLoading,
  setUserDeleteLoading,
  setTotalUsersCount,
  setUserCurrPage,
  setConfigurationList,
} = userSlice.actions;

export default userSlice.reducer;
