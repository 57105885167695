export enum PATHS {
  home = "/",
  login = "/login",
  verifyOtp = "/verify-otp",
  resetPassword = "/reset-password",
  changePassword = "/change-password",
  forgotPassword = "/forgot-password",
  history = "/history",
  myContent = "/my-content",
  relevantAssets = "/relevant-assets",
  viewEditContentManagement = "/view-edit-content-management",
  viewContentManagementByUser = "/view-edit-content-management",
  topicPage = "/topic-page",
  sources = "/sources",
  setPassword = "/set-password",

  // Admin
  admin = "/admin",
  userManagement = admin + "/user-management",
  conversationalSearch = "",
  browseCategory = "",
  adminContentManagement = admin + "/content-management",
  adminTaxonomyManagement = admin + "/taxonomy-management",
  adminTaxonomyOverview = admin + "/taxonomy-management-overview",
  adminAccessGroup = admin + "/access-group",
  adminContentManag = admin + "/content-manag",
  adminDataConnection = admin + "/data-connection",
  adminDataConnectionDetails = admin + "/data-connection-details",
  adminWorkflowManager = admin + "/workflow-manager",
  adminTheme = admin + "/theme",
  comingSoon = "/coming-soon",
  profile = "/profile",
  profileDetails = "/profile-details",
}

export enum TaxonomyView {
  FlatKeywords = "FlatKeywords",
  TreeHierarchy = "TreeHierarchy",
}

export enum TaxonomyType {
  FLAT = "FLAT",
  TREE = "TREE",
}

export enum ContentManage {
  InitSummaryLength = 42,
  InitTagsLength = 2,
}

export enum Month {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum TaxonomyTitle {
  Industry = "industry",
  Function = "function",
  AssetType = "asset type",
  Portfolios = "portfolios",
}

export enum ShowTaxonomyLimit {
  Industry = 1,
  Function = 1,
}

export enum TaxonomyDeleteOptions {
  MoveAllChildToParent = 1,
  DeleteAllChildren = 2,
  ReplaceText = 3,
}

export enum UserRoles {
  Admin = "ADMIN",
  ContentEditor = "CONTENT_EDITOR",
  User = "USER",
}

export enum Layout {
  Admin = "ADMIN",
  User = "USER",
}

export enum FromWhere {
  BrowseCategory = "browse-category",
  ConversationalSearch = "conversational-search",
  viewEditContentManagement = "view-edit-content-management",
  Home = "home",
  MyContent = "my-content",
  Source = "source",
  RecentlyViewed = "recentlyViewed",
  OthersViewed = "othersViewed",
  AdminContentManagement = "adminContentManagement",
}

export enum ContantManagementStatus {
  Processing = "PROCESSING",
  Failed = "FAILED",
  Completed = "LOADED_INTO_LLM",
  SentToLlm = "SENT_TO_LLM",
}

// Conversational Search Start
export enum Model {
  GPT3 = "gpt-3.5-turbo",
  GPT4 = "gpt-4",
  LLAMA = "llama",
}

export enum userType {
  AI = "ai",
  HUMAN = "human",
  INFO = "info"
}

export enum ChatActions {
  Like = "Like",
  Dislike = "Dislike",
}

// Conversational Search End
export enum AssetUploadStatus {
  FAILED = "FAILED",
  RECEIVED = "RECEIVED",
  PROCESSING = "PROCESSING",
  SENT_TO_LLM = "SENT_TO_LLM",
  FAILED_IN_LLM = "FAILED_IN_LLM",
  DELETING_START = "DELETING_START",
  UPLOADED_TO_DO = "UPLOADED_TO_DO",
  LOADED_INTO_LLM = "LOADED_INTO_LLM",
  CONVERTED_TO_PDF = "CONVERTED_TO_PDF",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
  STOPPING = "STOPPING",
  STOPPED = "STOPPED",
}

export enum FileLocation {
  ORGANISATION = "ORG",
  USER = "USER",
  ALL = "",
}

export enum FileLocationLabel {
  ORGANISATION = "Everyone",
  USER = "Only for me",
}

export enum UploadAssetState {
  INITIAL = "INITIAL",
  FILE = "FILE",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  FAILED = "FAILED",
  URL = "URL",
}

export enum ViewType {
  GRID = "GRID",
  LIST = "LIST",
}

export enum FileCardType {
  UPLOADED = "UPLOADED",
  GENERATED = "GENERATED",
}

export enum AiOptions {
  SUMMARIZE = "SUMMARIZE",
  AUTO_TAG_TAXONOMY = "AUTO_TAG_TAXONOMY",
  VISION_MODEL = "VISION_MODEL",
}

export enum PageLimit {
  UserManagement = 10, // set 50
  Taxonomy = 10, // set 10
  ContentManagement = 20, // set 20
  Sources = 20,
  ConversationalSearch = 20,
}

export enum PageNumber {
  ConversationalSearch = 0,
}

export const PublishedOption = [
  { label: "All", value: "ALL" },
  { label: "Published ", value: "TRUE" },
  { label: "Draft ", value: "FALSE" },
];

export enum RouteParams {
  UploadAsset = "upload-asset",
}

export enum FileName {
  AssetSCV = "Assets.csv",
}

export const Images = ["png", "jpg", "jpeg", "svg"];

export const NeedHelpEmail = "support@accenture.com";

export const RunVisionFileType = ["txt", "pdf", "doc", "docx", "ppt", "pptx"];
export const SurveyFileType = ["xls", "xlsx"];

export enum SummaryStaus {
  NOT_STARTED = "NOT_STARTED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  NULL = "NULL",
  CANCELLED = "CANCELLED",
  TO_BE_PROCESSED = "TO_BE_PROCESSED",
}
export const fileType = [
  { title: ".docx", key: "docx" },
  { title: ".doc", key: "doc" },
  { title: ".xls", key: "xls" },
  { title: ".xlsx", key: "xlsx" },
  { title: ".pdf", key: "pdf" },
  { title: ".ppt", key: "ppt" },
  { title: ".png", key: "png" },
  { title: ".jpg", key: "jpg" },
];

export enum RelevantAssetView {
  PDF = "PDF",
  PDFInfo = "PDFInfo",
  None = "None",
}

export const contentType = [
  {
    value: "USER",
    label: "My Uploads",
  },
  {
    value: "ORG",
    label: "Organisation",
  },
];

export const contentTypeForMyContent = [
  {
    value: "personal",
    label: "My Uploads",
  },
  {
    value: "contribution",
    label: "Contributed",
  },
];

export const assetStatusForMyContent = [
  {
    value: AssetUploadStatus.PROCESSING,
    label: "Processing",
  },
  {
    value: AssetUploadStatus.COMPLETED,
    label: "Processed",
  },
  {
    value: AssetUploadStatus.FAILED,
    label: "Failed",
  },
  {
    value: AssetUploadStatus.STOPPED,
    label: "Stopped",
  },
];

export enum ContentGroup {
  MyContent = "my-content",
  Sources = "sources",
}

export enum AppLayout {
  MINIMIZE = "minimize-chat-page",
  FULL = "full-chat-page",
  CLOSE = "close-chat-page",
}
