import { AssetUploadStatus } from "../../constants";
import "./AssetStatus.scss";

interface IAssetStatus {
  status: string;
  isShowAllStatus?: boolean;
}

const AssetStatus = ({ status, isShowAllStatus = false }: IAssetStatus) => {
  const renderAllAssetStatus = () => {
    switch (status) {
      case AssetUploadStatus.PROCESSING:
      case AssetUploadStatus.UPLOADED_TO_DO:
      case AssetUploadStatus.CONVERTED_TO_PDF:
      case AssetUploadStatus.SENT_TO_LLM:
        return <div className="inProgressStatus inner">Processing</div>;

      case AssetUploadStatus.FAILED:
      case AssetUploadStatus.FAILED_IN_LLM:
        return <div className="failedStatusText inner">Failed</div>;

      case AssetUploadStatus.STOPPING:
        return <div className="inProgressStatus inner">Stopping</div>;

      case AssetUploadStatus.STOPPED:
        return <div className="failedStatusText inner">Stopped</div>;

      case AssetUploadStatus.COMPLETED:
        return <div className="published inner">Processed</div>;

      default:
        return "";
    }
  };

  const renderAssetStatus = () => {
    switch (status) {
      case AssetUploadStatus.PROCESSING:
      case AssetUploadStatus.UPLOADED_TO_DO:
      case AssetUploadStatus.CONVERTED_TO_PDF:
      case AssetUploadStatus.SENT_TO_LLM:
        return <div className="inProgressStatus inner">Processing</div>;

      case AssetUploadStatus.STOPPING:
        return <div className="inProgressStatus inner">Stopping</div>;

      default:
        return "";
    }
  };

  return (
    <div className="asset-stat">
      {isShowAllStatus ? renderAllAssetStatus() : renderAssetStatus()}
    </div>
  );
};

export default AssetStatus;
