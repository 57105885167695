import { Button, Card, Checkbox, DatePicker, Select, TimeRangePickerProps } from "antd";
import { useSelector } from "react-redux";

import EditContentTags from "../../../components/viewEditContentManagement/EditContentTags/EditContentTags";
import { RootState } from "../../../store";
import { IContentFilter } from "../../../store/contentManagement/content.interface";
import { mapDataToTreeSelect } from "../../taxonomyHelper";
import { getAssetTypeOptions, getFileTypeOptions, isEmpty } from "../../helpers";
import {
  assetStatusForMyContent,
  contentType,
  contentTypeForMyContent,
  fileType,
  FromWhere,
} from "../../constants";
import "./ContentSourceFilter.scss";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

interface IContentSourceFilter {
  isShowContentStatus?: boolean;
  isShowContentType?: boolean;
  localFilters: IContentFilter;
  setLocalFilters: React.Dispatch<React.SetStateAction<IContentFilter>>;
  handleApplyFilter: () => void;
  handleResetFilter: () => void;
  fromWhere?: string;
}

const ContentSourceFilter = ({
  isShowContentStatus = true,
  isShowContentType = true,
  localFilters,
  setLocalFilters,
  handleApplyFilter,
  handleResetFilter,
  fromWhere = "",
}: IContentSourceFilter) => {
  const { assetTypeTaxonomy, industryTaxonomy, functionTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const assetTypes = assetTypeTaxonomy?.taxonomyTree || [];
  const mappedContentIndustry = mapDataToTreeSelect(industryTaxonomy.taxonomyTree ?? []);
  const mappedContentFunction = mapDataToTreeSelect(functionTaxonomy.taxonomyTree ?? []);
  const assetTypeOptions = getAssetTypeOptions(assetTypes) || [];
  const fileTypeOptions = getFileTypeOptions(fileType) || [];
  const contentTypeOptions =
    fromWhere === FromWhere.MyContent ? contentTypeForMyContent : contentType;
  const [isShowDocCount, setIsShowDocCount] = useState(false);
  const assetStatusOptions = assetStatusForMyContent;

  const { RangePicker } = DatePicker;
  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (dates) {
      setLocalFilters((prev) => ({ ...prev, startDate: dateStrings[0], endDate: dateStrings[1] }));
    } else {
      setLocalFilters((prev) => ({ ...prev, startDate: "", endDate: "" }));
      console.log("Clear");
    }
  };

  const handleSelectContentType = (value: any[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (value.includes("selectAll")) {
      if (contentTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, contentType: [] }));
      } else {
        const contentTypeValues = contentTypeOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, contentType: contentTypeValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, contentType: value }));
    }
  };

  const handleSelectAssetStatus = (value: any[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (value.includes("selectAll")) {
      if (assetStatusOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, assetStatus: [] }));
      } else {
        const assetStatusValues = assetStatusOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, assetStatus: assetStatusValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, assetStatus: value }));
    }
  };
  const handleSelectAssetType = (value: any[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (value.includes("selectAll")) {
      if (assetTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, assetType: [] }));
      } else {
        const contentTypeValues = assetTypeOptions?.map((item) => Number(item.value));
        console.log("contentTypeValues  ====", contentTypeValues);
        setLocalFilters((prev) => ({ ...prev, assetType: [...new Set(contentTypeValues)] }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, assetType: [...new Set(value)] }));
    }
  };

  const handleSelectDocType = (value: any[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));

    if (value.includes("selectAll")) {
      if (fileTypeOptions?.length === value.length - 1) {
        setLocalFilters((prev) => ({ ...prev, documentType: [] }));
      } else {
        const fileTypeValues = fileTypeOptions?.map((item) => item.value);
        setLocalFilters((prev) => ({ ...prev, documentType: fileTypeValues }));
      }
    } else {
      setLocalFilters((prev) => ({ ...prev, documentType: value }));
    }
  };

  const handleSelectFunction = (selectedNodes: string[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setLocalFilters((prev) => ({ ...prev, function: ids }));
  };

  const handleSelectIndustry = (selectedNodes: string[]) => {
    setLocalFilters((prev) => ({ ...prev, isApplied: false, isFilterChanged: true }));
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setLocalFilters((prev) => ({ ...prev, industry: ids }));
  };

  return (
    <>
      <Card className="content-filter-card">
        {isShowContentType && (
          <div>
            <div className="label-select">
              Content Type{" "}
              {localFilters?.contentType?.length > 0 && (
                <span className="count">({localFilters?.contentType?.length})</span>
              )}
            </div>
            <Select
              showSearch
              mode="multiple"
              popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
              value={localFilters?.contentType || []}
              options={contentTypeOptions}
              // options={[{ label: "Select All", value: "selectAll" }, ...contentTypeOptions]}
              allowClear={false}
              onChange={handleSelectContentType}
              filterOption={(inputValue: string, option: any) =>
                option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              menuItemSelectedIcon={({ isSelected }) => (
                <span>
                  {isSelected ||
                  (localFilters?.contentType &&
                    localFilters?.contentType?.length === contentTypeOptions?.length) ? (
                    <Checkbox checked={true}></Checkbox>
                  ) : (
                    <Checkbox checked={false}></Checkbox>
                  )}
                </span>
              )}
            />
          </div>
        )}

        {/* Asset Status */}
        {isShowContentStatus && (
          <div>
            <div className="label-select">
              Asset Status{" "}
              {localFilters?.assetStatus !== undefined && localFilters?.assetStatus?.length > 0 && (
                <span className="count">({localFilters?.assetStatus?.length})</span>
              )}
            </div>
            <Select
              showSearch
              mode="multiple"
              popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
              value={localFilters?.assetStatus || []}
              options={assetStatusOptions}
              allowClear={false}
              onChange={handleSelectAssetStatus}
              filterOption={(inputValue: string, option: any) =>
                option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              menuItemSelectedIcon={({ isSelected }) => (
                <span>
                  {isSelected ||
                  (localFilters?.assetStatus &&
                    localFilters?.assetStatus?.length === assetStatusOptions?.length) ? (
                    <Checkbox checked={true}></Checkbox>
                  ) : (
                    <Checkbox checked={false}></Checkbox>
                  )}
                </span>
              )}
            />
          </div>
        )}
        <div>
          <div className="label-select">
            Type{" "}
            {localFilters?.assetType?.length > 0 && (
              <span className="count">({localFilters?.assetType?.length})</span>
            )}
          </div>
          <Select
            showSearch
            allowClear={false}
            mode="multiple"
            popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
            value={localFilters?.assetType || []}
            onChange={handleSelectAssetType}
            options={assetTypeOptions}
            // options={[{ label: "Select All", value: "selectAll" }, ...assetTypeOptions]}
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            menuItemSelectedIcon={({ isSelected }) => (
              <span>
                {isSelected ||
                (localFilters?.assetType &&
                  localFilters?.assetType?.length === assetTypeOptions?.length) ? (
                  <Checkbox checked={true}></Checkbox>
                ) : (
                  <Checkbox checked={false}></Checkbox>
                )}
              </span>
            )}
          />
        </div>

        <div>
          <div className="label-select">
            {!isShowDocCount && (
              <>
                Document Type
                {localFilters?.documentType?.length > 0 && (
                  <span className="count"> ({localFilters?.documentType?.length})</span>
                )}
              </>
            )}
          </div>
          <Select
            showSearch
            mode="multiple"
            popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
            value={localFilters?.documentType || []}
            allowClear={false}
            onChange={handleSelectDocType}
            onFocus={() => setIsShowDocCount(true)}
            onBlur={() => setIsShowDocCount(false)}
            options={fileTypeOptions}
            // options={[{ label: "Select All", value: "selectAll" }, ...fileTypeOptions]}
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            menuItemSelectedIcon={({ isSelected }) => (
              <span>
                {isSelected ||
                (localFilters?.documentType &&
                  localFilters?.documentType?.length === fileTypeOptions?.length) ? (
                  <Checkbox checked={true}></Checkbox>
                ) : (
                  <Checkbox checked={false}></Checkbox>
                )}
              </span>
            )}
          />
        </div>

        <div>
          <div className="label-select">
            Function{" "}
            {localFilters?.function?.length > 0 && (
              <span className="count">({localFilters?.function?.length})</span>
            )}
          </div>
          <EditContentTags
            selectedTags={localFilters?.function || []}
            mappedContentTags={mappedContentFunction}
            popupClassName="ui-choose-select ui-choose-multiple"
            placeholder=""
            handleChange={handleSelectFunction}
            allowClear={false}
            isNonLeafNodeDisable={false}
            treeCheckStrictly={true}
          />
        </div>

        <div>
          <div className="label-select">
            Industry{" "}
            {localFilters?.industry?.length > 0 && (
              <span className="count">({localFilters?.industry?.length})</span>
            )}
          </div>
          <EditContentTags
            selectedTags={localFilters?.industry || []}
            mappedContentTags={mappedContentIndustry}
            popupClassName="ui-choose-select ui-choose-multiple"
            placeholder=""
            handleChange={handleSelectIndustry}
            allowClear={false}
            isNonLeafNodeDisable={false}
            treeCheckStrictly={true}
          />
        </div>
        <div className="date-picker">
          {/* <div className="label-select">Date</div> */}
          <RangePicker
            rootClassName="range-picker-default"
            presets={rangePresets}
            onChange={onRangeChange}
            value={
              localFilters.startDate
                ? [dayjs(localFilters.startDate), dayjs(localFilters.endDate)]
                : null
            }
          />
        </div>
        {!localFilters?.isApplied ? (
          <Button
            type="primary"
            className="btn apply-btn"
            disabled={!localFilters?.isFilterChanged}
            onClick={handleApplyFilter}>
            Apply
          </Button>
        ) : (
          <Button type="default" className="btn reset-btn" onClick={() => handleResetFilter()}>
            Reset
          </Button>
        )}
      </Card>
    </>
  );
};

export default ContentSourceFilter;
