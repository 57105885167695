import { Button, Popover } from "antd";
import "./History.scss";
import { useEffect, useState } from "react";
import { ChatPanel, HistoryPanel, PublishingQueue, StartChatPanel } from "../../components";
import { AppLayout, IMAGES, SettingModal, fileType, getAllIds, isEmpty, useChats } from "../../shared";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsChatStartWithAssetFilter,
  setIsNewChatStartWithContentFilter,
  setIsOpenChatHistory,
  setIsPublishingQueue,
  setSelectedFilesForAssetFilter,
  setStartNewConversation,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../store/filtersFromTaxonomyData/filters.slice";
import { setAppLayoutview } from "../../store/appState/appSlice";

const History = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { appLayoutView } = useSelector((state: RootState) => state.app);
  const {
    selectedChatHistory,
    isOpenChatHistory,
    isNewConversationStart,
    uploadFileList,
    startNewChatWithContentFilter,
    selectedFilesForAssetFilter,
    isChatStartWithAssetFilter,
    isPublishingInQueue,
  } = useSelector((state: RootState) => state.conversationalSearch);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const [isPublishingQueue, setPublishingQueue] = useState(false);
  const [isChatWrap, setIsChatWrap] = useState(false);
  const [isChatView, setIsChatView] = useState(false);
  const [bodyClass, setBodyClass] = useState(appLayoutView || AppLayout.MINIMIZE);
  const [isVisibleSettings, setIsVisibleSettings] = useState<boolean>(false);

  const { clearAllChatStates, defaultConfigureState } = useChats();

  useEffect(() => {
    document.body.classList.add(bodyClass);
    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, [bodyClass]);

  const handleNewTopicAction = () => {
    // dispatch(setIsOpenChatHistory(false));
    clearAllChatStates();
    defaultConfigureState();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setStartNewConversation(false));
  };

  const handleClassChange = (newClass: AppLayout) => {
    dispatch(setAppLayoutview(newClass));
    setBodyClass(newClass);
  };

  useEffect(() => {
    if ((uploadFileList?.length > 1 || isPublishingInQueue) && selectedChatHistory.id !== -1) {
      dispatch(setIsPublishingQueue(!isEmpty(uploadFileList)));
      setPublishingQueue(true);
    } else {
      setPublishingQueue(false);
      setIsChatWrap(
        selectedChatHistory.id !== -1 || isOpenChatHistory || isNewConversationStart ? true : false
      );
      setIsChatView(!isOpenChatHistory);
    }
  }, [
    selectedChatHistory,
    isOpenChatHistory,
    isNewConversationStart,
    uploadFileList,
    isPublishingInQueue,
  ]);

  useEffect(() => {
    if (startNewChatWithContentFilter) {
      handleNewTopicAction();
      dispatch(setIsNewChatStartWithContentFilter(false));
      if (selectedFilesForAssetFilter?.length && !isChatStartWithAssetFilter) {
        dispatch(setIsOpenChatHistory(false));
        dispatch(setIsChatStartWithAssetFilter(true));
      }
    }
  }, [startNewChatWithContentFilter, isChatStartWithAssetFilter, selectedFilesForAssetFilter]);

  const handleHistoryView = () => {
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(setIsOpenChatHistory(!isOpenChatHistory));
    if (isOpenChatHistory && selectedChatHistory.id === -1) {
      dispatch(setStartNewConversation(false));
    }
  };

  // const handleNavigation = (assetId: number) => {
  //   dispatch(setContentById(contentByIdInitialValue));
  //   dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
  //   updateViewCount(assetId);
  //   navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
  //     state: {
  //       isEditMode: false,
  //       isReadOnly: true,
  //       fromWhere: FromWhere.ConversationalSearch,
  //     },
  //   });
  // };

  const handleSaveSetting = () => {
    setIsVisibleSettings(false);
  };

  const reanderChatHeader = () => {
    return (
      <div
        className={`chat-header d-flex d-flex-middle d-flex-between ${
          isPublishingQueue ? "publishing-queue-wrapper" : ""
        } ${!isEmpty(selectedChatHistory?.fileContext) ? "has-context" : ""}`}>
        <div className="chat-header-left">
          {!isOpenChatHistory && !isPublishingQueue ? (
            <div className="chat-header-title d-flex d-flex-middle">
              <span className="octopus-icon">
                <img src={IMAGES.brandIcon} alt="Brand Icon" />
              </span>
              AI Assistant
            </div>
          ) : (
            <div className="chat-header-title">
              {isPublishingQueue ? "Publishing Queue" : "History"}
            </div>
          )}
          {/* Section show only History view */}
        </div>
        {/* Section Remove */}

        <div className="chat-header-middle d-flex d-flex-middle">
          {isPublishingQueue ? "" : <span className="gpt-version">GPT-4.0</span>}
          <Button
            type="link"
            onClick={handleHistoryView}
            className={`chat-header-icon ${isOpenChatHistory ? "active" : ""}`}>
            <i className="ri-history-line"></i>
          </Button>
          <Popover
            open={isVisibleSettings}
            placement="bottomLeft"
            title={false}
            content={<SettingModal onSubmit={handleSaveSetting} />}
            trigger="click"
            onOpenChange={() => setIsVisibleSettings(false)}
            rootClassName="setting-pop">
            <Button
              type="link"
              className="chat-header-icon"
              onClick={() => setIsVisibleSettings(true)}>
              <i className="ri-settings-4-line"></i>
            </Button>
          </Popover>
        </div>

        <div className="chat-header-right d-flex d-flex-middle">
          <Button
            type="link"
            onClick={() => handleClassChange(AppLayout.MINIMIZE)}
            className={
              appLayoutView === AppLayout.MINIMIZE ? "mini-icon min disable-grey" : "mini-icon min"
            }>
            <i className="ri-subtract-line"></i>
          </Button>
          <Button
            type="link"
            className={
              appLayoutView === AppLayout.FULL ? "mini-icon max disable-grey" : "mini-icon max"
            }
            onClick={() => handleClassChange(AppLayout.FULL)}>
            <i className="ri-expand-diagonal-line"></i>
          </Button>
          <Button
            type="link"
            className="mini-icon close"
            onClick={() => handleClassChange(AppLayout.CLOSE)}>
            <i className="ri-close-line"></i>
          </Button>
        </div>
        {/* {!isEmpty(selectedChatHistory?.fileContext) ? (
          <div className="selected-assets-wrapper ">
            <span>You have selected {selectedChatHistory?.fileContext?.length} Assets</span>
            <span className="tooltip-wrap">
              <Tooltip
                trigger={"click"}
                placement="bottomRight"
                overlayClassName="asset-overlay"
                overlay={
                  <div>
                    <ul>
                      <li key={"01"} className="title-asset">
                        Referenced assets
                      </li>
                      {(selectedChatHistory?.fileContext || []).map(
                        (item: IFileContext, index: number) => (
                          <li
                            title={item.fileName}
                            onClick={() => handleNavigation(item.assetId)}
                            key={index}>
                            <span className="file-icon">
                              <img src={renderFileTypeIcon(item.fileType)} alt="Icon" />
                            </span>
                            {item.fileName}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                }>
                <InfoCircleFilled />
              </Tooltip>
            </span>
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  };

  return (
    <>
      <div className="chat-wrap">
        {reanderChatHeader()}

        {isPublishingQueue ? (
          <PublishingQueue />
        ) : (
          <>
            {isChatWrap ? (
              <>
                <div className="chat-body">{isChatView ? <ChatPanel /> : <HistoryPanel />}</div>
              </>
            ) : (
              <StartChatPanel />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default History;
