import React from "react";
import { Col, Card, Checkbox, CheckboxProps, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { IContent } from "../../store/contentManagement/content.interface";
import {
  setSelectContentDetails,
  setSelectionAppliedForContent,
  setSelectionAppliedForSources,
} from "../../store/contentManagement/contentSlice";
import {
  FileCardType,
  renderFileTypeIcon,
  getFormattedDate,
  AssetStatus,
  AssetSummaryPopover,
  PATHS,
  getIsDisableEditContent,
  FromWhere,
  AssetUploadStatus,
  getCardOwnersDetail,
  getCardOwnersFullName,
  FileLocation,
} from "../../shared";
import "./FileCard.scss";

interface IFileCardProps {
  data: IContent;
  type: FileCardType;
  extraOptions?: React.ReactNode | null;
  extraOptionsTwo?: React.ReactNode;
  hoverOptions?: React.ReactNode;
  isPopOver?: boolean;
  fromWhere?: string;
  onClick?: () => void;
}

const FileCard = (props: IFileCardProps) => {
  const {
    data,
    type,
    extraOptions,
    extraOptionsTwo,
    hoverOptions,
    onClick,
    isPopOver = true,
    fromWhere = "",
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { contents } = useSelector((state: RootState) => state.content);

  const handleSelection: CheckboxProps["onChange"] = (e: any) => {
    const val: boolean = e.target.checked;
    if (typeof val === "boolean") {
      const updatedContent = {
        ...data,
        isSelected: val,
      };
      const contentsValues = [...contents];
      const i = contentsValues.findIndex((item) => item?.asset_id === data?.asset_id);
      contentsValues[i] = updatedContent;
      if (location.pathname.includes(PATHS.myContent))
        dispatch(setSelectionAppliedForContent(false));
      else if (location.pathname.includes(PATHS.sources))
        dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(contentsValues));
    }
  };

  const noClick = (e: any) => {
    e.stopPropagation();
  };

  const handleCardClick = () => {
    return onClick && onClick();
  };

  const pointerClass = () => {
    if (data?.assetStatus === AssetUploadStatus.COMPLETED) return "cursor-pointer";
    else return "cursor-default";
  };

  const RenderStatus = () => {
    return (
      <AssetStatus
        status={data?.assetStatus}
        isShowAllStatus={[String(FromWhere.AdminContentManagement), FromWhere.MyContent].includes(
          fromWhere
        )}
      />
    );
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          {/* if user pic is true */}
          {/* <img src={IMAGES.avatarDefault} alt="profile-pic" className="profile-pic" /> */}

          {/* else */}
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const renderFileCard = () => {
    return (
      <Card className={`file-card ${pointerClass()}`} onClick={handleCardClick}>
        <div className="file-preview">
          <div
            className="file-bg"
            style={{ backgroundImage: `url(${data?.thumbnail_pre_signed_url})` }}></div>
          <div className="file-prev-inner">
            {fromWhere !== FromWhere.Source && (
              <div className="checkbox-wrap" onClick={noClick}>
                <Checkbox
                  checked={!getIsDisableEditContent(data) && data?.isSelected}
                  value={data?.isSelected}
                  onChange={handleSelection}
                  disabled={getIsDisableEditContent(data)}
                />
              </div>
            )}

            {RenderStatus()}

            {extraOptionsTwo ? (
              <div className="extra-options" onClick={noClick}>
                {extraOptionsTwo && extraOptionsTwo}
              </div>
            ) : (
              ""
            )}
            <div className="file-icon">
              <img src={renderFileTypeIcon(data?.file_type)} alt="Icon" />
            </div>
          </div>
        </div>

        <div className="file-info">
          <div className="file-name-info d-flex d-flex-middle">
            <h6 className="file-name">
              {data?.title !== "" && data?.title ? data?.title : data?.original_file_name}
            </h6>
            {/* <div onClick={noClick}></div> */}
            <div className="extra-options" onClick={noClick}>
              {extraOptions && extraOptions}
            </div>
          </div>
          <div className="file-data">
            {/* <p className="file-cat">Owner: {getCardOwnersDetail(data?.user)}</p> */}
            {type === FileCardType.UPLOADED &&
              (data?.file_level === FileLocation.USER ? (
                <p className="file-cat">
                  <span className="tag">{getCardOwnersDetail(data?.user)}</span>
                </p>
              ) : (
                renderOwnerUI(data)
              ))}
            {type === FileCardType.GENERATED && renderOwnerUI(data)}
            <p className="file-date">{getFormattedDate(data?.modifiedOn, "type1")}</p>
          </div>
        </div>

        <div className="hover-options" onClick={noClick}>
          {hoverOptions && hoverOptions}
        </div>
      </Card>
    );
  };

  return (
    <Col>
      {isPopOver ? (
        <Popover
          placement="bottomLeft"
          rootClassName="custom-description-root"
          content={<AssetSummaryPopover content={data} fromWhere={fromWhere} />}>
          {renderFileCard()}
        </Popover>
      ) : (
        renderFileCard()
      )}
    </Col>
  );
};

export default FileCard;
