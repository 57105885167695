import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IChatContentFilter,
  IChatHistory,
  ICitations,
  IConversationalSearch,
  IFileINContext,
  IMessages,
  IThumbnailsByPage,
} from "./conversationalSearch.interface";
import { isEmpty, Model } from "../../shared";
import { PURGE } from "redux-persist";
import { IContent } from "../contentManagement/content.interface";

const initialState: IConversationalSearch = {
  isOpenChatHistory: false,
  chatHistoryLoading: false,
  chatHistory: [],
  selectedChatHistory: {
    id: -1,
    title: "",
    messages: [],
    userId: -1,
    modelName: Model.GPT3,
    createdOn: "",
    chatTaxonomies: [],
  },
  deleteChatHistoryLoading: false,
  isCloseWebSocket: false,
  saveTitleLoading: false,
  isStreamingStart: false,
  loadingMessageId: -1,
  showStartNewTopicMessage: false,
  isNewConversationStart: false,
  isNetworkOpen: true,
  userQuestion: "",
  isExportingChatHistoryLoading: false,
  isThumbnailLoading: false,
  uploadFileList: [],
  selectedFilesForAssetFilter: [],
  isChatStartWithAssetFilter: false,
  selectedMessageCitations: [],
  selectedReleventAsset: [],
  isPublishingInQueue: false,
  chatContentFilter: null,
  startNewChatWithContentFilter: false,
  isNewContextFilterApplied: false,
};

export const conversationalSearchSlice = createSlice({
  name: "conversationalSearch",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setIsOpenChatHistory: (state, action: PayloadAction<boolean>) => {
      state.uploadFileList = [];
      state.isOpenChatHistory = action.payload;
    },
    setChatHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.chatHistoryLoading = action.payload;
    },
    setChatHistory: (state, action: PayloadAction<IChatHistory[]>) => {
      state.chatHistory = action.payload;
    },
    setSelectedChatHistory: (state, action: PayloadAction<IChatHistory>) => {
      state.selectedChatHistory = action.payload;
    },
    addNewQuestionAnswer: (
      state,
      action: PayloadAction<{ chatId: number; queAns: IMessages[] }>
    ) => {
      const findIndex = state.chatHistory.findIndex(
        (history) => history.id === action.payload.chatId
      );
      if (findIndex !== -1) {
        state.chatHistory[findIndex].messages.push(...action.payload.queAns);
        if (state.selectedChatHistory && state.selectedChatHistory.id === action.payload.chatId) {
          state.selectedChatHistory = state.chatHistory[findIndex];
        }
      }
    },
    setIsStreamingStart: (state, action: PayloadAction<boolean>) => {
      state.isStreamingStart = action.payload;
    },
    updateAIAnswer: (state, action: PayloadAction<{ chatId: number; type: string; ans: any }>) => {
      const findIndex = state.chatHistory.findIndex(
        (history) => history.id === action.payload.chatId
      );
      if (findIndex !== -1) {
        const messageLength = state.chatHistory[findIndex].messages.length - 1;
        const messagesArray: IMessages[] = state.chatHistory[findIndex].messages;
        if (messagesArray[messageLength]) {
          state.chatHistory[findIndex].messages[messageLength] = {
            ...messagesArray[messageLength],
            [action.payload.type]: action.payload.ans,
          };
        }
        if (state.selectedChatHistory && state.selectedChatHistory.id === action.payload.chatId) {
          state.selectedChatHistory = state.chatHistory[findIndex];
        }
      }
    },
    setLoadingMessageId: (state, action: PayloadAction<number>) => {
      state.loadingMessageId = action.payload;
    },
    setNewChatHistoryId: (state, action: PayloadAction<{ oldId: number; newId: number }>) => {
      const findIndex = state.chatHistory.findIndex(
        (chatHistory) => chatHistory.id === action.payload.oldId
      );
      if (findIndex !== -1) {
        state.chatHistory[findIndex].id = action.payload.newId;
        if (state.selectedChatHistory && state.selectedChatHistory.id === action.payload.oldId) {
          state.selectedChatHistory.id = action.payload.newId;
        }
      }
    },
    setNewChatHistoryTitle: (
      state,
      action: PayloadAction<{ chatHistoryId: number; title: string }>
    ) => {
      const findIndex = state.chatHistory.findIndex(
        (chatHistory) => chatHistory.id === action.payload.chatHistoryId
      );
      if (findIndex !== -1) {
        state.chatHistory[findIndex].title = action.payload.title;
        if (
          state.selectedChatHistory &&
          state.selectedChatHistory.id === action.payload.chatHistoryId
        ) {
          state.selectedChatHistory.title = action.payload.title;
        }
      }
    },
    // called when the user starts new chat
    addNewChatHistory: (state, action: PayloadAction<IChatHistory>) => {
      state.chatHistory = [action.payload, ...state.chatHistory];
      state.selectedChatHistory = action.payload;
    },
    setChatAction: (state, action: PayloadAction<IMessages>) => {
      let findIndex = state.chatHistory.findIndex(
        (history) => history.id === state.selectedChatHistory.id
      );
      if (findIndex > -1) {
        let findMessageId = state.chatHistory[findIndex].messages.findIndex(
          (message) => message.id === action.payload.id
        );
        state.chatHistory[findIndex].messages[findMessageId] = action.payload;
        state.selectedChatHistory.messages[findMessageId] = action.payload;
      }
    },
    setDeleteChatLoading: (state, action: PayloadAction<boolean>) => {
      state.deleteChatHistoryLoading = action.payload;
    },
    updateChat: (state, action: PayloadAction<{ id: number }>) => {
      state.chatHistory = state.chatHistory.filter((v) => v.id !== action?.payload?.id);
    },
    setIsCloseWebSocket: (state, action: PayloadAction<boolean>) => {
      state.isCloseWebSocket = action.payload;
    },
    deleteLastChatMessage: (state, action: PayloadAction<number>) => {
      const findIndex = state.chatHistory.findIndex((history) => history.id === action.payload);
      if (findIndex !== -1) {
        state.chatHistory[findIndex].messages.splice(-2);
        state.selectedChatHistory = state.chatHistory[findIndex];
      }
    },
    setSaveTitleLoading: (state, action: PayloadAction<boolean>) => {
      state.saveTitleLoading = action.payload;
    },
    setShowNewTopicAlert: (state, action: PayloadAction<boolean>) => {
      state.showStartNewTopicMessage = action.payload;
    },
    // for check network
    setIsNetworkOpen: (state, action: PayloadAction<boolean>) => {
      state.isNetworkOpen = action.payload;
    },
    setThumbnailsByPage: (state, action: PayloadAction<IThumbnailsByPage>) => {
      const findIndex = state.chatHistory.findIndex(
        (history) => history.id === action.payload.selectedChatHistoryId
      );
      if (findIndex !== -1) {
        const messagesLength = state.chatHistory[findIndex].messages.length;
        const citations = state.chatHistory[findIndex].messages[messagesLength - 1].citations;
        if (citations.length > 0) {
          const updatedCitations = citations.map((citation) => {
            const findThumbnail = action.payload.thumbnails?.find(
              (thumbnail) =>
                thumbnail.file_name === citation.file_name && thumbnail.page_no === citation.page
            );
            if (findThumbnail?.pre_signed_url) {
              return { ...citation, pre_signed_url: findThumbnail.pre_signed_url };
            }
            return citation;
          });
          state.chatHistory[findIndex].messages[messagesLength - 1].citations = updatedCitations;
          if (
            state.selectedChatHistory &&
            state.selectedChatHistory.id === action.payload.selectedChatHistoryId
          ) {
            state.selectedChatHistory.messages[messagesLength - 1].citations = updatedCitations;
          }
        }
      }
    },
    setUserQuestion: (state, action: PayloadAction<string>) => {
      state.userQuestion = action.payload;
    },
    setIsExportingChatHistory: (state, action: PayloadAction<boolean>) => {
      state.isExportingChatHistoryLoading = action.payload;
    },
    setIsThumbnailLoading: (state, action: PayloadAction<boolean>) => {
      state.isThumbnailLoading = action.payload;
    },
    setStartNewConversation: (state, action: PayloadAction<boolean>) => {
      state.isNewConversationStart = action.payload;
    },
    setNewChatMessageInChatHistory: (state, action: PayloadAction<IMessages>) => {
      const chatIndex = state.chatHistory.findIndex(
        (history) => history.id === state.selectedChatHistory.id
      );
      state.chatHistory[chatIndex].messages.push(action.payload);
      state.selectedChatHistory.messages.push(action.payload);
    },
    setUploadFileList: (state, action: PayloadAction<IContent>) => {
      if (!state.uploadFileList) state.uploadFileList = [action.payload];
      else state.uploadFileList.push(action.payload);
    },
    setUpdateUploadFileList: (state, action: PayloadAction<IContent[]>) => {
      state.uploadFileList = action.payload;
      if (isEmpty(action.payload)) {
        state.isPublishingInQueue = false;
      }
    },
    setEmptyUploadFileList: (state) => {
      state.uploadFileList = [];
    },
    setSelectedFilesForAssetFilter: (state, action: PayloadAction<IFileINContext[]>) => {
      state.selectedFilesForAssetFilter = action.payload;
    },
    setSelectedReleventAsset: (state, action: PayloadAction<IFileINContext[]>) => {
      state.selectedReleventAsset = action.payload;
    },
    setIsChatStartWithAssetFilter: (state, action: PayloadAction<boolean>) => {
      state.isChatStartWithAssetFilter = action.payload;
    },
    setSelectedMessageCitations: (state, action: PayloadAction<ICitations[]>) => {
      state.selectedMessageCitations = action.payload;
    },
    setIsPublishingQueue: (state, action: PayloadAction<boolean>) => {
      state.isPublishingInQueue = action.payload;
    },
    setContentFilterForNewChat: (state, action: PayloadAction<IChatContentFilter>) => {
      state.chatContentFilter = action.payload;
      // state.startNewChatWithContentFilter = true;
    },
    setContentFilterForChat: (state, action: PayloadAction<IChatContentFilter>) => {
      state.chatContentFilter = action.payload;
      state.startNewChatWithContentFilter = false;
    },
    setIsNewContextFilterApplied: (state, action: PayloadAction<boolean>) => {
      state.isNewContextFilterApplied = action.payload;
      state.startNewChatWithContentFilter = false;
    },
    setIsNewChatStartWithContentFilter: (state, action: PayloadAction<boolean>) => {
      state.startNewChatWithContentFilter = action.payload;
    },
    setUpdateLastInfoMessage: (state, action: PayloadAction<{ message: IMessages | null }>) => {
      if (action.payload?.message)
        state.selectedChatHistory.messages[
          (state?.selectedChatHistory?.messages?.length || 1) - 1
        ] = action.payload.message;
    },
  },
});

export const {
  setIsPublishingQueue,
  setUpdateUploadFileList,
  setUploadFileList,
  setEmptyUploadFileList,
  setIsOpenChatHistory,
  setChatHistoryLoading,
  setChatHistory,
  setSelectedChatHistory,
  addNewQuestionAnswer,
  setIsStreamingStart,
  updateAIAnswer,
  setLoadingMessageId,
  setNewChatHistoryId,
  setNewChatHistoryTitle,
  addNewChatHistory,
  setChatAction,
  setDeleteChatLoading,
  updateChat,
  setIsCloseWebSocket,
  deleteLastChatMessage,
  setSaveTitleLoading,
  setShowNewTopicAlert,
  setIsNetworkOpen,
  setThumbnailsByPage,
  setUserQuestion,
  setIsExportingChatHistory,
  setIsThumbnailLoading,
  setStartNewConversation,
  setNewChatMessageInChatHistory,
  setSelectedFilesForAssetFilter,
  setIsChatStartWithAssetFilter,
  setSelectedMessageCitations,
  setSelectedReleventAsset,
  setContentFilterForNewChat,
  setContentFilterForChat,
  setIsNewContextFilterApplied,
  setIsNewChatStartWithContentFilter,
  setUpdateLastInfoMessage,
} = conversationalSearchSlice.actions;

export default conversationalSearchSlice.reducer;
