import { useRef, useState } from "react";
import { Button, Input, Popover } from "antd";
import { TaxonomyItem, useClickOutsideModal } from "../../shared";
import { updateTaxonomyNode } from "../../services/taxonomy";

interface ITreeDescription {
  isOpen: boolean;
  setModalOpen(value: number): void;
  nodeId: number;
  description: string;
  taxonomy: TaxonomyItem[];
  setTaxonomy(taxonomy: TaxonomyItem[]): void;
  disablePopover: boolean;
}

const TreeDescription = (props: ITreeDescription) => {
  const { isOpen, setModalOpen, nodeId, description, setTaxonomy, taxonomy, disablePopover } =
    props;

  const [currentDescription, setCurrentDescription] = useState(description);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isDescriptionLoading, setIsDescriptionLoading] = useState<boolean>(false);

  const moreTreeDescriptionRef = useRef(null);

  useClickOutsideModal(moreTreeDescriptionRef, () => resetModal(), isOpen);

  const resetModal = () => {
    setEditMode(false);
    setModalOpen(-1);
    setCurrentDescription(description);
  };

  const handleSaveDescription = async () => {
    var taxonomyNodeName = "";
    var taxonomyNodeSynonym = "";

    const updateDescription = (nodes: TaxonomyItem[]): TaxonomyItem[] => {
      return nodes.map((node) => {
        if (node.taxonomyNodeId === nodeId) {
          taxonomyNodeName = node.taxonomyNodeName;
          taxonomyNodeSynonym = node.synonyms;
          return { ...node, description: currentDescription };
        }
        if (node?.children?.length > 0) {
          return { ...node, children: updateDescription(node.children) };
        }
        return node;
      });
    };
    const taxonomyDetails = updateDescription(taxonomy);

    try {
      setIsDescriptionLoading(true);

      await updateTaxonomyNode(
        nodeId,
        true,
        {
          description: currentDescription,
          taxonomyNodeName,
          synonyms: taxonomyNodeSynonym,
        },
        taxonomy
      );

      setTaxonomy(taxonomyDetails);
    } catch (e) {
      console.error(e, "Error updating");
      resetModal();
    } finally {
      setEditMode(false);
      setModalOpen(-1);
      setIsDescriptionLoading(false);
    }
  };

  const renderFooter = () => {
    if (isEditMode) {
      return (
        <>
          <Button
            style={{ marginRight: "10px" }}
            type="text"
            onClick={() => setEditMode(false)}
            disabled={isDescriptionLoading}>
            Cancel
          </Button>
          {isEditMode && (
            <Button onClick={handleSaveDescription} type="text" loading={isDescriptionLoading}>
              Save
            </Button>
          )}
        </>
      );
    }
    return (
      <>
        <Button
          type="text"
          onClick={() => {
            setEditMode(true);
          }}>
            <i className="ri-edit-fill tags-edit-icon"></i>
        </Button>
        <Button type="text" onClick={resetModal}>
          Close
        </Button>
      </>
    );
  };

  return (
    <Popover
      overlayClassName="custom-description-body"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container" ref={moreTreeDescriptionRef}>
          <Input.TextArea
            placeholder="Type here..."
            className={isEditMode ? "popover-text editModeBackground" : "popover-text"}
            value={currentDescription}
            readOnly={isEditMode === false ? true : false}
            onChange={(e) => setCurrentDescription(e.target.value)}
          />
          <div className="des-btn">{renderFooter()}</div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight">
      <span
        onClick={() => !disablePopover && setModalOpen(nodeId)}
        className={disablePopover ? "disableDescriptionCursor" : ""}>
        {description === "" ? "Type here.." : description}
      </span>
    </Popover>
  );
};

export default TreeDescription;
