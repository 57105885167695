import { Button, Card, Checkbox, Flex, Progress, Switch } from "antd";
import "./profile.scss";
import { PageHeader } from "../../components";
import { CustomPagination, IMAGES } from "../../shared";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Profile = () => {

  const handlePagination = (_page: number) => {};

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="View Profiles"
          isRelevantAsset={false}
          content={
            <>
              <Button
                type="text"
                className="page-close-btn"
                icon={<i className="ri-close-fill"></i>}
              />
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Card className="profile-card">
            <div className="bottom-bar d-flex d-flex-middle d-flex-between">
              <div className="ant-tabs-nav-container">
                <Checkbox className="select-label">
                3 Selected  <span>(Showing 8 results)</span>
                </Checkbox>
              </div>
              <div className="ant-tabs-extra-content">
                <div className="published-box">
                  <span className="published-label">Show Shortlisted Only</span>
                  <Switch />
                </div>
                <Button
                  type="text"
                  className="text-btn"
                  icon={<i className="ri-message-3-line"></i>}>
                  Chat
                </Button>
                <Button
                  type="text"
                  className="text-btn"
                  icon={<i className="ci-small-download-icon ci icon-link"></i>}>
                  Download
                </Button>
              </div>
            </div>
            <div className="page-scroll">
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                    <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
              <Card className="profile-card-inner">
                <div className="profile-checkbox">
                  <Checkbox className="select-label"></Checkbox>
                </div>
                <div className="pdf-box">
                  <Card className="file-card cursor-pointer variant">
                    <div className="pdf-preview">
                      <Link to={'/profile-details'}><img src={IMAGES.pdfIconBig} alt=" " /></Link>
                    </div>
                    <div className="file-info">
                      <h6 className="file-name">Profile 1</h6>
                      <div className="extra-options">
                        <Button className="no-style">
                          <i className="ci-small-download-icon ci icon-link"></i>
                        </Button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="profile-text">
                  <h2 className="wrapper-title">Samuel Nash</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s... when
                    an unknown printer took a galley of make a type specimen book. It has survived
                    not only five centuries, but also the leap into the electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing...
                  </p>
                </div>
                <div className="score-box">
                  <h4>Relevance Score</h4>
                  <Progress
                    className="score-number"
                    type="circle"
                    percent={86}
                    format={(percent) => `${percent}  `}
                    size={36}
                  />

                  <Button type="primary" className="btn">
                    Shortlist
                  </Button>
                </div>
              </Card>
            </div>

            <CustomPagination
                  currentPage={10}
                  total={10}
                  pageSize={10}
                  handlePagination={handlePagination}
                />
          </Card>
        </motion.div>
      </Flex>
    </>
  );
};

export default Profile;
