import { Button, Dropdown, MenuProps } from "antd";
import { useDispatch } from "react-redux";
import { IContent, IContentFilter } from "../../store/contentManagement/content.interface";
import { ICitations, IFileINContext } from "../../store/conversationalSearch/conversationalSearch.interface";
import { setContentSelectedIds } from "../../store/contentManagement/contentSlice";
import {
  setContentFilterForChat,
  setContentFilterForNewChat,
  setIsChatStartWithAssetFilter,
  setIsNewChatStartWithContentFilter,
  setIsNewContextFilterApplied,
  setSelectedFilesForAssetFilter,
  setSelectedReleventAsset,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { setAppLayoutview } from "../../store/appState/appSlice";
import { AppLayout, isEmpty } from "../../shared";
import "../contentActionButton/contentActionButton.scss";
import { useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import {
  isMyContentChatFilterEmpty,
  isSourcesChatFilterEmpty,
} from "../../shared/helper/contentHelper";

interface IContentActionButton {
  localFilters?: IContentFilter;
  content?: IContent;
  selectedContents?: IContent[] | ICitations[];
  isVisible?: boolean;
  isDisabled?: boolean;
}

const ContentChatButton = ({
  localFilters,
  content,
  selectedContents,
  isVisible = false,
  isDisabled = false,
}: IContentActionButton) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);

  const handleChatWithAssetFilter = () => {
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    dispatch(setSelectedReleventAsset([]));
    const fileInContext: IFileINContext[] =
      content && !isEmpty(content)
        ? [
            {
              asset_id: content.asset_id,
              asset_type: "",
              file_name: content.file_name,
              file_type: content.file_type || "",
              title: content.title,
              isPublished: content.isPublished,
            },
          ]
        : !isEmpty(selectedContents) ? (selectedContents?.map((co) => ({
          asset_id: co.asset_id,
          asset_type: "",
          file_name: co.file_name,
          file_type: co.file_type || "",
          title: co.title,
          isPublished: co?.isPublished ? co.isPublished : "TRUE",
        })) || []) : [];
        
    dispatch(setSelectedFilesForAssetFilter(fileInContext));
    dispatch(setContentSelectedIds(fileInContext.map((e) => e.asset_id)));
  };

  const handleExistingChat = () => {
    if (selectedChatHistory.id === -1) startNewChat();
    if (content || !isEmpty(selectedContents)) {
      handleChatWithAssetFilter();
      dispatch(setContentFilterForChat({ pageLocation: location.pathname }));
      dispatch(setIsNewContextFilterApplied(true));
    } else {
      dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setAppLayoutview(AppLayout.MINIMIZE));
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForChat({ ...localFilters, pageLocation: location.pathname }));
    }
  };

  const startNewChat = () => {
    if (content || !isEmpty(selectedContents)) {
      dispatch(setIsChatStartWithAssetFilter(false));
      handleChatWithAssetFilter();
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForNewChat({ pageLocation: location.pathname }));
      dispatch(setIsNewChatStartWithContentFilter(true));
    } else {
      dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setAppLayoutview(AppLayout.MINIMIZE));
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForNewChat({ ...localFilters, pageLocation: location.pathname }));
      dispatch(setIsNewChatStartWithContentFilter(true));
    }
  };

  const getItems = () => {
    let items: MenuProps["items"] = [];

    items.push(
      {
        key: "1",
        label: "In Existing Chat",
        onClick: handleExistingChat,
      },
      {
        key: "2",
        label: "Start New Chat",
        onClick: startNewChat,
      }
    );

    return items;
  };

  const isChatBtnVisible = () => {
    return (
      (localFilters &&
        (!isMyContentChatFilterEmpty(localFilters) || !isSourcesChatFilterEmpty(localFilters))) ||
      content ||
      isVisible ||
      !isEmpty(selectedContents)
    );
  };

  return (
    <>
      {isChatBtnVisible() ? (
        <>
          <Dropdown
            trigger={["click"]}
            disabled={isDisabled}
            menu={{
              items: getItems(),
            }}
            overlayClassName="table-action-dropdown content-dropdown"
            placement="bottomRight">
            <Button
              type="primary"
              className="btn chat-document"
              icon={<i className="ci-small-chat-icon ci"></i>}>
              Chat
              <i className="ri-arrow-down-s-line"></i>
            </Button>
          </Dropdown>
          {/* <Tooltip placement="topRight">
              <Button
                onClick={handleChatWithAssetFilter}
                type="primary"
                className="btn chat-document"
                icon={<i className="ci-small-chat-icon ci"></i>}>
                Chat
              </Button>
            </Tooltip> */}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ContentChatButton;
