import { Button, Form, Input, Modal } from "antd";
import "./DataConnectionModal.scss";
import { IMAGES } from "../../shared";
import { sharepointConnect } from "../../services/dataConnection";

interface IModal {
  title: string;
  isLoading: boolean;
  isModalOpen: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
}

const DataConnectionModal: React.FC<IModal> = (props: IModal) => {
  const { isLoading, isModalOpen, handleCancel, title } = props;

  const [form] = Form.useForm();

  const SourceDetails = [
    { title: "SalesForce", icon: IMAGES.salesforceIcon },
    { title: "SharePoint", icon: IMAGES.sharepointIcon },
    { title: "OneDrive", icon: IMAGES.oneDriveIcon },
    { title: "Confluence", icon: IMAGES.ConfluenceIcon },
    { title: "Web Content", icon: IMAGES.sharepointIcon },
  ];

  const sharepointFormSubmit = async (v: {
    username: string;
    password: string;
    domain: string;
  }) => {
    console.log(v, "adfjskojfk");

    await sharepointConnect(v);
  };

  return (
    <Modal
      rootClassName="data-modal-root"
      title={title}
      centered={true}
      onCancel={handleCancel}
      footer={
        <Button
          className="btn data-submit-btn"
          type="primary"
          loading={isLoading}
          onClick={() => {
            console.log("form submit"), form.submit();
          }}>
          Connect
        </Button>
      }
      open={isModalOpen}>
      <div className="data-modal-sub-header">Select the source type</div>

      <div className="data-source-wrap">
        {SourceDetails.map((v) => (
          <div className="data-source-container position-relative d-flex d-flex-middle">
            <span className="radio-box-custom position-absolute"></span>
            <img src={v?.icon} alt="source" />
            <div className="data-source-title">{v?.title}</div>
            <Button className="more-action position-absolute">
              <i className="ri-more-2-fill"></i>
            </Button>
          </div>
        ))}
      </div>

      <Form
        name="sharepointForm"
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: false }}
        onFinish={sharepointFormSubmit}
        layout="vertical"
        size="large">
        <Form.Item
          label="Source username"
          name="username"
          rules={[{ required: true, message: "Please input your email!", type: "email" }]}>
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Source password"
          name="password"
          className="InputPassword"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}>
          <Input.Password placeholder="" />
        </Form.Item>
        <Form.Item
          label="Source domain"
          name="domain"
          className="InputPassword"
          rules={[
            {
              required: true,
              message: "Please input source domain",
            },
          ]}>
          <Input placeholder="" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DataConnectionModal;
