import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Input, Modal, Select, Spin, Switch, Table } from "antd";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";

import TreeDescription from "../taxonomyTree/TreeDescription";
import { RootState } from "../../store";
import {
  createNewFlatTaxonomyNode,
  deleteTaxonomyNodes,
  fetchTaxonomyById,
  getTaxonomyNodesBySearch,
  updateTaxonomyNode,
} from "../../services/taxonomy";
import { TaxonomyItem, generateRandomNumber, useDebounce } from "../../shared";
import "../taxonomyTree/TaxonomyTree.scss";

interface IFrom {
  colLevel: number;
}

function TaxonomyFlat({ colLevel }: IFrom) {
  const { id } = useParams();
  const taxonomyId: number = Number(id) > 0 ? Number(id) : -1;

  const { getTreeTableLoading, getTaxonomyTree } = useSelector(
    (state: RootState) => state.taxonomy
  );

  const [maxLevel, setMaxLevel] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(-1);
  const [editableNodeId, setEditableNodeId] = useState(-1);
  const [editedNodeValue, setEditedNodeValue] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<React.Key[]>([]);
  const [taxonomy, setTaxonomy] = useState<TaxonomyItem[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (taxonomyId) {
      fetchTaxonomyById(taxonomyId);
    }
  }, [taxonomyId]);

  useEffect(() => {
    getTaxonomyTree?.taxonomyTree && getTaxonomyTree?.taxonomyTree?.length > 0
      ? setTaxonomy(getTaxonomyTree?.taxonomyTree)
      : null;
  }, [getTaxonomyTree]);

  useEffect(() => {
    setMaxLevel(colLevel);
  }, [colLevel]);

  const handleChange = (value: string) => {
    setMaxLevel(Number(value));
  };

  const handleSubmitDeleteModal = async () => {
    setIsDeleteModalOpen(false);
    const res = await deleteTaxonomyNodes(selectedRowIds);
    if (res?.data && taxonomyId) {
      await fetchTaxonomyById(taxonomyId);
    }
  };

  const handleSaveNode = (nodeId: number, newName: string) => {
    const updateNode = (nodes: TaxonomyItem[]): TaxonomyItem[] => {
      return nodes.map((node) => {
        if (node.taxonomyNodeId === nodeId) {
          if (node.taxonomyNodeId === 0) {
            const body = {
              taxonomyNodeName: newName,
              description: "",
              parentTaxonomyNodeId: null,
              taxonomyId: taxonomyId,
              taxonomyNodeLevel: 0,
            };
            createNewFlatTaxonomyNode(body, taxonomyId);
          } else {
            updateTaxonomyNode(
              node.taxonomyNodeId,
              true,
              {
                description: node.description,
                taxonomyNodeName: newName,
                synonyms: ''
              },
              taxonomy
            );
          }
          setEditableNodeId(-1);
          setEditedNodeValue("");
          return { ...node, taxonomyNodeName: newName }; // Update the node's taxonomyNodeName and set isEdit to false
        }
        return node;
      });
    };

    setTaxonomy(updateNode(taxonomy));
  };

  const updateNodeInTaxonomy = (
    taxonomy: TaxonomyItem[],
    updatedNode: TaxonomyItem
  ): TaxonomyItem[] => {
    return taxonomy.map((node) =>
      node.taxonomyNodeId === updatedNode.taxonomyNodeId
        ? updatedNode
        : { ...node, children: updateNodeInTaxonomy(node.children, updatedNode) }
    );
  };

  const getTaxonomyBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdit(true);
    const trimmedSearchText = e.target.value.trim();

    if (trimmedSearchText.length >= 3)
      await getTaxonomyNodesBySearch(taxonomyId, trimmedSearchText);
    if (e.target.value.length === 0) {
      await fetchTaxonomyById(taxonomyId);
    }
    setSearchText(trimmedSearchText);
  };

  const handleSearchDebounce = useDebounce(getTaxonomyBySearch, 500);

  const rowClassName = (record: any) => {
    return record.isMatching ? "matching-row" : "";
  };

  // remove nodes from taxonomy
  const removeNodesRecursively = (
    nodes: TaxonomyItem[],
    nodeIds: React.Key[] | number[]
  ): TaxonomyItem[] => {
    return nodes.reduce((acc, node) => {
      const updatedChildren = removeNodesRecursively(node.children, nodeIds);
      if (!nodeIds.includes(node.taxonomyNodeId)) {
        acc.push({ ...node, children: updatedChildren });
      }
      return acc;
    }, [] as TaxonomyItem[]);
  };

  const handleCloseNodeInput = (node: TaxonomyItem) => {
    setEditableNodeId(-1);
    setEditedNodeValue("");

    if (node?.taxonomyNodeName === "") {
      const updatedNodes = removeNodesRecursively(taxonomy, [node?.taxonomyNodeId]);
      setTaxonomy(updatedNodes);
    }
  };

  const generateDataSource = (): any[] => {
    const dataSource: any[] = [];
    let count = 1;
    const traverse = (nodes: TaxonomyItem[]) => {
      nodes.forEach((node) => {
        const row: any = {};
        const isMatching =
          searchText && node.taxonomyNodeName.toLowerCase().includes(searchText.toLowerCase());
        row[`taxonomyNodeId`] = node.taxonomyNodeId; // Add taxonomyNodeId field
        row[`no`] = count++;
        row[`title`] = (
          <span className="edit-table-input">
            {editableNodeId === node.taxonomyNodeId ? (
              <Input
                value={editedNodeValue}
                autoFocus
                onChange={(e) => {
                  setEditedNodeValue(e.target.value);
                }}
                onPressEnter={() => {
                  handleSaveNode(node.taxonomyNodeId, editedNodeValue);
                }}
                suffix={
                  <>
                    <Button
                      type="link"
                      disabled={editedNodeValue === ""}
                      className={editedNodeValue === "" ? "disableDescriptionCursor" : ""}
                      icon={<CheckOutlined style={{ fontSize: "13px" }} />}
                      onClick={() => {
                        handleSaveNode(node.taxonomyNodeId, editedNodeValue);
                      }}
                    />
                    <Button
                      type="link"
                      icon={<CloseOutlined style={{ fontSize: "13px" }} />}
                      onClick={() => handleCloseNodeInput(node)}
                    />
                  </>
                }
              />
            ) : (
              <span className="title-level-inner d-flex d-flex-middle">
                <span
                  className="title-level"
                  onClick={() => {
                    setEditableNodeId(node.taxonomyNodeId);
                    setEditedNodeValue(node.taxonomyNodeName);
                  }}>
                  {node.taxonomyNodeName}
                </span>
              </span>
            )}
          </span>
        );
        row["description"] = (
          <>
            <div className="tree-discription d-flex">
              <TreeDescription
                isOpen={node.taxonomyNodeId === isModalOpen}
                setModalOpen={setIsModalOpen}
                nodeId={node?.taxonomyNodeId}
                setTaxonomy={setTaxonomy}
                taxonomy={taxonomy}
                description={node?.description}
                disablePopover={false}
              />
              {node?.description !== "" && (
                <u className="more-tag" onClick={() => setIsModalOpen(node?.taxonomyNodeId)}>
                  +more
                </u>
              )}
            </div>
          </>
        );

        row["linkedAsset"] = node?.linkedAsset?.length;
        dataSource.push({ ...row, isMatching });
      });
    };

    traverse(taxonomy);

    // Add a row with an "Add" button at level 0
    const lastRow: any = {};
    lastRow[`taxonomyNodeId`] = generateRandomNumber(); // Add taxonomyNodeId field
    lastRow[`title`] = (
      <span>
        <Button
          size="small"
          disabled={editableNodeId !== -1}
          onClick={() => {
            const newNode: TaxonomyItem = {
              taxonomyNodeId: 0,
              taxonomyNodeName: ``,
              description: "",
              linkedAsset: [],
              children: [],
              synonyms: ""
            };
            setTaxonomy([...taxonomy, newNode]);
            setEditableNodeId(newNode.taxonomyNodeId);
            setEditedNodeValue(newNode.taxonomyNodeName);
          }}
          icon={<i className="ri-add-circle-fill"></i>}
          className="plus-icon plus-icon-fill"
          type="link"
        />
      </span>
    );
    lastRow["description"] = "";
    lastRow["linkedAsset"] = "";
    dataSource.push(lastRow);

    return dataSource;
  };

  const columns = [
    { title: "No.", width: "50px", dataIndex: "no", key: "no" },
    {
      title: `Title`,
      dataIndex: `title`,
      key: `title`,
      width: "230px",
    },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "No. of linked assets", width: "170px", dataIndex: "linkedAsset", key: "linkedAsset" },
    {
      title: "Topic Pages",
      width: "170px",
      dataIndex: "topicPages",
      key: "topicPages",
      render: () => (
        <div className="topic-pages">
          <Switch
          // disabled={user?.id === userDetails?.id}
          // defaultChecked={user?.status === "A" ? true : false}
          // onChange={(e) => updateStatus(e, user)}
          />
        </div>
      ),
    },
  ];

  const dataSource = generateDataSource();

  return (
    <div className="tree-hierarchy-new tree-hierarchy-flat">
      <div className={`container-taxonomy ${getTreeTableLoading && "layer"}`}>
        <div
          className={
            selectedRowIds?.length > 0
              ? "header-taxonomy d-flex d-flex-middle d-flex-between"
              : "header-taxonomy d-flex d-flex-middle d-flex-right"
          }>
          <div hidden={colLevel > 0 ? true : false} className="add-level add-taxonomy">
            <Button type="text" className="add">
              <PlusCircleOutlined /> Add taxonomy
            </Button>
          </div>

          {selectedRowIds?.length > 0 && (
            <Button
              className="balk-delete"
              disabled={!selectedRowIds?.length}
              onClick={() => setIsDeleteModalOpen(true)}
              icon={<i className="ri-delete-bin-6-fill"></i>}>
              Delete
            </Button>
          )}
          {(dataSource?.length > 1 || isEdit) && (
            <div className="two-action d-flex d-flex-middle">
              <div className="search-form">
                <Input
                  type="search"
                  className="header-search"
                  placeholder="Search terms…"
                  prefix={<i className="ri-search-line"></i>}
                  onChange={handleSearchDebounce}
                />
              </div>
            </div>
          )}

          <div hidden={colLevel > 0 ? true : false} className="add-level level-select">
            <Select
              placeholder="Select"
              disabled={taxonomy.length > 0}
              style={{ width: 120 }}
              value={maxLevel >= 0 ? maxLevel.toString() : null}
              onChange={handleChange}
              options={[
                { value: "0", label: "Level 0" },
                { value: "1", label: "Level 1" },
                { value: "2", label: "Level 2" },
                { value: "3", label: "Level 3" },
                { value: "4", label: "Level 4" },
                { value: "5", label: "Level 5" },
                { value: "6", label: "Level 6" },
              ]}
            />
          </div>
        </div>
        {getTreeTableLoading && (
          <div className="loader-tree">
            <Spin />
          </div>
        )}
        <div className="table-ui">
          {!!(maxLevel >= 0) && (
            <Table
              pagination={false}
              className="tree-table-ui"
              rowKey={"taxonomyNodeId"}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[]) => {
                  setSelectedRowIds(selectedRowKeys);
                },
                getCheckboxProps: (record: any) => ({
                  disabled:
                    record.taxonomyNodeId === dataSource[dataSource.length - 1].taxonomyNodeId,
                  style: {
                    display:
                      record.taxonomyNodeId === dataSource[dataSource.length - 1].taxonomyNodeId
                        ? "none"
                        : "",
                  },
                  name: record.name,
                }),
              }}
              columns={columns}
              dataSource={dataSource}
              rowClassName={rowClassName} // Set the rowClassName callback
            />
          )}
        </div>
        {/* <Button className="add-row-btn" type="primary">
          <span className="plus-icon">
            <img src={IMAGES.plusIcon} />
          </span>
          Add a row
        </Button> */}
      </div>

      <Modal
        className="delete-confirmation-modal"
        centered
        open={isDeleteModalOpen}
        onOk={handleSubmitDeleteModal}
        okText="Delete"
        onCancel={() => setIsDeleteModalOpen(false)}
        cancelButtonProps={{ style: { display: "block" }, className: "border-button" }}
        okButtonProps={{}}>
        <div className="inner-modal-delete">
          <h3>Are you sure you want to delete this taxonomy?</h3>
          <p>
            This action will permanently delete the selected row and cannot be undone. Following
            this deletion, the remaining taxonomies will be repositioned upward in the sequence
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default TaxonomyFlat;
