import { Image } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { RootState } from "../../../store";
// import { ICitations } from "../../../store/conversationalSearch/conversationalSearch.interface";
// import { getThumbnailsByPage } from "../../../services/conversationalSearch";
import { toast } from "react-toastify";
import { RootState } from "../../store";
// import { getThumbnailsByPage } from "../../services/conversationalSearch";
import { ICitations, IThumbnails } from "../../store/conversationalSearch/conversationalSearch.interface";
import { FromWhere, Images, PATHS, renderFileTypeIcon } from "../../shared";
import { setSelectedMessageCitations } from "../../store/conversationalSearch/conversationalSearchSlice";
import { useDispatch } from "react-redux";
import { getThumbnailsByPage } from "../../services/conversationalSearch";

interface IGroupedItems {
  id: number;
  title: string;
  file_name: string;
  original_file_name: string;
  page: number[];
  file_type: string;
  pre_signed_url?: string;
  asset_id?: number;
}

interface IReleventAsset {
  citations: ICitations[];
}
const RelevanAssets: FC<IReleventAsset> = ({ citations }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedChatHistory } = useSelector((state: RootState) => state.conversationalSearch);
  // const { messages } = selectedChatHistory;
  // const citations = messages[messages?.length - 1]?.citations;
  // const [isGridView, setGridView] = useState(true);
  const [, setListViewRecords] = useState<IGroupedItems[]>([]);
  const [citationList, setCitationList] = useState<IGroupedItems[]>([]);
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);

  useEffect(() => {
    if (citations.length > 0) {
      const filteredCitations = citations.filter((c) => c.asset_level === "primary");

      const groupedData = groupItemsByFileName(filteredCitations);
      setCitationList(groupedData);
      setListViewRecords(groupedData);
    }
  }, [citations]);

  // useEffect(() => {
  //   if (citations.length > 0 && selectedChatHistory?.id !== -1) {
  //     const request = citations?.map((citation) => {
  //       return { file_name: citation.file_name, page_no: citation?.page };
  //     });
  //     getThumbnailsByPage(selectedChatHistory.id, request);
  //   }
  // }, [selectedChatHistory?.id]);

  const groupItemsByFileName = (items: ICitations[]): IGroupedItems[] => {
    const grouped: { [key: string]: IGroupedItems } = {};
    items.forEach((item) => {
      const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
      if (!(key in grouped)) {
        grouped[key] = {
          id: item.asset_id,
          title: item.title,
          file_name: item.file_name,
          original_file_name: item.original_file_name,
          page: [],
          file_type: item?.file_type,
          pre_signed_url: item.pre_signed_url,
        };
      }
      if (!grouped[key].page.includes(item.page)) {
        grouped[key].page.push(item.page);
      }
    });

    return Object.values(grouped);
  };

  // const resultCount = (data: number = 0) => {
  //   if (data === 0) {
  //     return "";
  //   } else if (data < 10) {
  //     return `Showing top 0${data} results`;
  //   } else {
  //     return `Showing top ${data} results`;
  //   }
  // };

  const isImage = (item: ICitations | IGroupedItems) => Images.includes(item?.file_type || "");

  const handleOnTitleClick = (
    item: ICitations | IGroupedItems,
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    handleNavigateReleventAssets(0);
    if (isImage(item)) {
      if (item.pre_signed_url) {
        setVisible(true);
        setSelectedImageURL(item.pre_signed_url);
      } else {
        getThumbnailsByPage(selectedChatHistory.id, [
          { file_name: item.file_name, page_no: 0 },
        ]).then((thumbnails: IThumbnails[]) => {
          if (thumbnails.length) {
            setVisible(true);
            setSelectedImageURL(thumbnails[0]?.pre_signed_url);
          }
        });
      }
    } else {
      e.stopPropagation();
      if (item.file_type === "xls" || item.file_type === "xlsx") {
        toast.error("Cannot open preview of this file");
      } else {
        // document.body.classList.add("relevant-assets-page");
        // handleOpenPdfDoc(item?.asset_id || 0, item.file_name, "", item?.title, item.page, true);
      }
    }
  };
  const showMore = () => {
    handleNavigateReleventAssets(0);
  };

  const handleNavigateReleventAssets = (s_no: number) => {
    dispatch(setSelectedMessageCitations(citations));
    navigate(PATHS.relevantAssets, {
      state: { s_no: s_no, fromWhere: FromWhere.ConversationalSearch },
    });
  };

  return (
    <>
      <h5>Relevant Documents:</h5>
      <div className="documents-list cursor-pointer">
        {!!citationList.length &&
          citationList.slice(0, 5).map((item) => (
            <div
              className="documents-item"
              onClick={(e: any) => {
                handleOnTitleClick(item, e);
              }}>
              <img src={renderFileTypeIcon(item.file_type)} alt="File Icon" className="file-icon" />
              <span className="file-name">{item.title || item.original_file_name}</span>
              <span className="page-no" title={item.page.join(", ")}>
                {item.page.slice(0, 5).join(", ")}
                {item.page.length > 5 ? "..." : ""}
              </span>
            </div>
          ))}

        {citationList.length > 5 ? (
          <div className="documents-item documents-show-more" onClick={showMore}>
            <span className="file-name">Show more</span>
          </div>
        ) : (
          ""
        )}
        <div>{/* <PDFViewer /> */}</div>
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                handleNavigateReleventAssets(0);
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RelevanAssets;
