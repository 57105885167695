import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "./HeadLogo.scss";

interface IHeadLogoProps {
  type?: "lg" | "sm";
}

const HeadLogo: FC<IHeadLogoProps> = ({ type }) => {
  const { appearance } = useSelector((state: RootState) => state.app);

  let logoURL: string = appearance.logo.lg;

  if (type === "lg") {
  } else if (type === "sm") {
    logoURL = appearance.logo.sm;
  }

  return (
    <div className="logo d-flex d-flex-middle">
      {appearance.logo.text === "" || type ? (
        <img className="logo-img" src={logoURL} alt="logo" />
      ) : (
        <span className="logo-text">{appearance.logo.text}</span>
      )}
    </div>
  );
};

export default HeadLogo;
