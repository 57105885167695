import { useEffect } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ComingSoon, Layout, PATHS } from "./shared";
import { DataConnectionDetails, TaxonomyOverview } from "./components";
import TopicPage from "./pages/topicPage/TopicPage";
import { ProtectedRoute, PersistLogin } from "./routes";
import { getAppearance } from "./services/appAppearanceApi";
import { AdminLayout, AppLayout, AuthLayout } from "./layout";
import {
  AccessGroup,
  Usermanagement,
  ContentManagement,
  DataConnection,
  TaxonomyManagement,
  WorkflowManager,
  Theme,
} from "./admin/pages";
import {
  ForgotPassword,
  Login,
  History,
  ResetPassword,
  OTP,
  HomePage,
  MyContent,
  RelevantAssets,
  ViewEditContentManagement,
  Sources,
  ErrorPage,
  ChangePassword,
} from "./pages";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Profile } from "./pages/profile";
import { ProfileDetails } from "./pages/profileDetails";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const App = () => {
  const { layout } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    getAppearance();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route>
          <Route element={<PersistLogin />}>
            {/* Auth */}
            <Route element={<AuthLayout />}>
              <Route index path={PATHS.login} element={<Login />} />
              <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
              <Route path={PATHS.changePassword} element={<ChangePassword />} />
              <Route path={PATHS.verifyOtp} element={<OTP />} />
            </Route>
          </Route>

          <Route element={<ProtectedRoute />}>
            {/* Auth */}
            <Route element={<AuthLayout />}>
              <Route path={PATHS.resetPassword} element={<ResetPassword />} />
            </Route>
            {/* App */}
            <Route element={<AppLayout />}>
              <Route index path={PATHS.home} element={<HomePage />} />
              <Route path={PATHS.history} element={<History />} />
              <Route path={PATHS.myContent} element={<MyContent />} />
              <Route index path={PATHS.home} element={<HomePage />} />
              <Route path={PATHS.relevantAssets} element={<RelevantAssets />} />
              {(layout === Layout.User || !layout) && (
                <Route
                  path={`${PATHS.viewEditContentManagement}/:assetId`}
                  element={<ViewEditContentManagement isRelevantAsset={false} />}
                />
              )}
              <Route path={PATHS.sources} element={<Sources />} />
              <Route path={PATHS.topicPage} element={<TopicPage />} />
              <Route path={PATHS.profile} element={<Profile />} />
              <Route path={PATHS.profileDetails} element={<ProfileDetails />} />
            </Route>

            {/* Admin */}
            <Route element={<AdminLayout />}>
              <Route path={PATHS.adminAccessGroup} element={<AccessGroup />} />
              <Route path={PATHS.userManagement} element={<Usermanagement />} />
              <Route path={PATHS.adminContentManagement} element={<ContentManagement />} />
              <Route path={PATHS.adminDataConnection} element={<DataConnection />} />
              <Route
                path={`${PATHS.adminDataConnectionDetails}/:connectionId`}
                element={<DataConnectionDetails />}
              />
              <Route path={PATHS.adminTaxonomyManagement} element={<TaxonomyManagement />} />
              <Route path={`${PATHS.adminTaxonomyOverview}/:id`} element={<TaxonomyOverview />} />
              <Route path={PATHS.adminWorkflowManager} element={<WorkflowManager />} />
              <Route path={PATHS.adminTheme} element={<Theme />} />
              {layout === Layout.Admin && (
                <Route
                  path={`${PATHS.viewEditContentManagement}/:assetId`}
                  element={<ViewEditContentManagement isRelevantAsset={false} />}
                />
              )}
            </Route>
          </Route>
          <Route path="*" element={<ErrorPage />} />
          <Route path={PATHS.comingSoon} element={<ComingSoon />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
