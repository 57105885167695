import { Button, Divider, Image, Input, Radio, RadioChangeEvent, Skeleton, Tooltip } from "antd";
import {
  ChatActions,
  fileType,
  FromWhere,
  getAllIds,
  getFilesInContext,
  getLocalStorageItem,
  getUserId,
  isEmpty,
  Model,
  PATHS,
  uniqueId,
  useChats,
  userType,
} from "../../../shared";
import "./ChatPanel.scss";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  IFileINContext,
  IMessages,
  ITags,
} from "../../../store/conversationalSearch/conversationalSearch.interface";
import { ExclamationCircleOutlined, InfoCircleFilled } from "@ant-design/icons";
import {
  addNewChatHistory,
  addNewQuestionAnswer,
  setChatAction,
  setContentFilterForChat,
  setContentFilterForNewChat,
  setEmptyUploadFileList,
  setIsNewContextFilterApplied,
  setIsStreamingStart,
  setLoadingMessageId,
  setNewChatHistoryId,
  setNewChatHistoryTitle,
  setNewChatMessageInChatHistory,
  setSelectedFilesForAssetFilter,
  setSelectedReleventAsset,
  setStartNewConversation,
  setUpdateLastInfoMessage,
  setUpdateUploadFileList,
  setUserQuestion,
  updateAIAnswer,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { messageComment, saveStreamChatReply } from "../../../services/conversationalSearch";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import {
  setContentFilesInContext,
  setContentSelectedIds,
  setUpdateAllContentFilter,
  setUpdateAllSourceFilter,
} from "../../../store/contentManagement/contentSlice";
import dayjs from "dayjs";
import configs from "../../../shared/config";
import RelevanAssets from "../../assets/RelevantAssets";
import Markdown from "react-markdown";
import { IContent, IContentFilter } from "../../../store/contentManagement/content.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { ConversationFileCard } from "../publishingQueue/ConversationFileCard";
import { UploadMultipleAssets } from "../../uploadAssetsContentMangement";
import { TFiltersType } from "../../../store/filtersFromTaxonomyData/filters.interface";

let chatHistoryId: number = -1;
let isNewChat: boolean = false;
const ChatPanel = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedChatHistory,
    userQuestion,
    isNetworkOpen,
    isStreamingStart,
    loadingMessageId,
    uploadFileList,
    selectedFilesForAssetFilter,
    selectedReleventAsset,
    chatContentFilter,
    isNewContextFilterApplied,
  } = useSelector((state: RootState) => state.conversationalSearch);
  const { sourceFilter, contentFilter } = useSelector((state: RootState) => state.content);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { id, messages } = selectedChatHistory;
  const { getSelectedFilterObject, clearAllChatStates, defaultConfigureState } = useChats();

  const isProcessInProgress = useRef(false);
  const wsRef = useRef<WebSocket | null>(null);
  const chatContainerRef: any = useRef(null);

  const [visible, setVisible] = useState(false);
  const [selectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState(false);
  const [isSaveChatReply, setIsSaveChatReply] = useState(false);
  const [isRetryBtnClick, setIsRetryBtnClick] = useState(false);
  const [question, setQuestion] = useState("");
  const [value, setValue] = useState("global");
  const [isMessageSentAfterChange, setIsMessageSentAfterChange] = useState(false);
  const [, setIsComment] = useState(false);
  const [, setSelectedMessage] = useState<IMessages | undefined>(undefined);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isNewChatStarted, setIsNewChatStarted] = useState(false);

  const onChange = (e: RadioChangeEvent) => {
    setIsMessageSentAfterChange(false);
    if (e.target.value === "global") {
      dispatch(setContentFilesInContext([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setSelectedReleventAsset([]));
      dispatch(setContentFilterForChat({ pageLocation: "" }));
      dispatch(setIsNewContextFilterApplied(true));
      setValue(e.target.value);
    } else {
      const localFilters = location.pathname === PATHS.myContent ? contentFilter : sourceFilter;
      if (
        (PATHS.myContent === location.pathname || PATHS.sources === location.pathname) &&
        localFilters.isApplied
      ) {
        dispatch(setSelectedFilesForAssetFilter([]));
        dispatch(setContentSelectedIds([]));
        dispatch(setContentFilterForChat({ ...localFilters, pageLocation: location.pathname }));
        setValue(e.target.value);
        dispatch(setIsNewContextFilterApplied(true));
      } else {
        setValue("global");
      }
    }
  };

  useEffect(() => {
    if (userQuestion) {
      setQuestion(userQuestion);
    }
  }, [userQuestion]);

  useEffect(() => {
    if (userQuestion && question) {
      dispatch(setUserQuestion(""));
      startNewChat();
    }
  }, [userQuestion, question]);

  useEffect(() => {
    if (isNewContextFilterApplied) {
      const infoMessage: IMessages | null = getInfoMessage();
      if (!isEmpty(messages) && messages[messages.length - 1]?.type === userType.INFO)
        dispatch(setUpdateLastInfoMessage({ message: infoMessage }));
      else if (infoMessage) dispatch(addNewQuestionAnswer({ chatId: id, queAns: [infoMessage] }));
    }
  }, [isNewContextFilterApplied, chatContentFilter]);

  const getInfoMessage = (): IMessages | null => {
    if (!isNewContextFilterApplied && isMessageSentAfterChange) return null;
    let messageText: string = !isEmpty(selectedFilesForAssetFilter)
      ? "Conversation started for the selected file"
      : "";

    if (!messageText && !isEmpty(chatContentFilter) && chatContentFilter?.pageLocation) {
      messageText = !isEmpty(chatContentFilter) ? "Conversation started for file in context" : "";
    }
    if (!messageText) {
      setValue("global");
      messageText = "Conversation started for global filter";
    } else {
      setValue("context");
    }
    setIsMessageSentAfterChange(true);
    if (messageText)
      return {
        id: uniqueId(),
        type: userType.INFO,
        content: JSON.stringify({
          filter:
            !isEmpty(chatContentFilter) && chatContentFilter?.pageLocation
              ? chatContentFilter
              : null,
          pageLocation: !isEmpty(chatContentFilter)
            ? chatContentFilter?.pageLocation
            : location.pathname,
          filesInContext: !isEmpty(selectedFilesForAssetFilter)
            ? selectedFilesForAssetFilter
            : null,
          text: messageText,
        }),
        contentType: "text",
        citations: [],
        error: false,
        answerId: "",
        thumbsUp: false,
        thumbsDown: false,
        userComments: "",
        optimised_Question: "",
        isStopGenerating: "FALSE",
        isShowGeneratingLabel: false,
      };
    else return null;
  };
  const startNewChat = () => {
    setIsNewChatStarted(true);
    isNewChat = true;
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      contentType: "text",
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
    };
    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };
    let messages: IMessages[] = [];
    const infoMessage: IMessages | null = getInfoMessage();
    if (infoMessage) {
      messages = [infoMessage, userMessage, aiReply];
      dispatch(setIsNewContextFilterApplied(false));
    } else messages = [userMessage, aiReply];
    const newChatHistory = {
      id: uniqueId(),
      title: "Greetings",
      messages: messages,
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      createdOn: dayjs().toISOString(),
      chatTaxonomies: [getSelectedFilterObject()],
      fileContext: getFilesInContext(
        selectedFilesForAssetFilter,
        selectedReleventAsset,
        selectedChatHistory,
        "saveAIReply"
      ),
    };
    chatHistoryId = newChatHistory.id;
    dispatch(addNewChatHistory(newChatHistory));
    dispatch(setLoadingMessageId(aiReply.id || 0));
    connectWebSocket(() => {
      askToWebSocket();
    });
    // dispatch(setSelectedFilesForAssetFilter([]));
  };

  const getLastContext = () => {
    if (chatContentFilter?.pageLocation) return chatContentFilter;
    const infoMessage = messages.filter((e) => e.type === "info").reverse()[0];
    return infoMessage ? JSON.parse(infoMessage.content)?.filter : chatContentFilter;
  };

  const getTaxonomyFilter = () => {
    let contextFilterObj: any = getLastContext();

    const taxonomyFilterObject = getSelectedFilterObject();
    if (contextFilterObj) {
      taxonomyFilterObject.tags.map((e: ITags) => {
        if (e.taxonomyName === TFiltersType.Functions) {
          e.taxonomyNodeIds = contextFilterObj.function ? contextFilterObj.function : [];
          delete e.taxonomyName;
        }
        if (e.taxonomyName === TFiltersType.Industry) {
          e.taxonomyNodeIds = contextFilterObj.industry ? contextFilterObj.industry : [];
          delete e.taxonomyName;
        }
        if (e.taxonomyName === TFiltersType.AssetType) {
          e.taxonomyNodeIds = contextFilterObj.assetType ? contextFilterObj.assetType : [];
          delete e.taxonomyName;
        }
      });
      if (contextFilterObj.documentType)
        taxonomyFilterObject.fileType = contextFilterObj.documentType;
      if (contextFilterObj.startDate) {
        taxonomyFilterObject.startDate = contextFilterObj.startDate;
        taxonomyFilterObject.endDate = contextFilterObj.endDate;
      }
      // if (!isEmpty(contextFilterObj?.assetStatus)) {
      //   taxonomyFilterObject.asset_status = contextFilterObj?.assetStatus;
      // }
      if (contextFilterObj.isBookmarked)
        taxonomyFilterObject.is_bookmarked = contextFilterObj.isBookmarked;
      if (!isEmpty(contextFilterObj.contentType) && contextFilterObj?.contentType?.length === 1)
        taxonomyFilterObject.is_owner = contextFilterObj.contentType.includes("personal");
    }
    return taxonomyFilterObject;
  };
  const askToWebSocket = () => {
    let files_in_context: IFileINContext[] = [];
    files_in_context = getFilesInContext(
      selectedFilesForAssetFilter,
      selectedReleventAsset,
      selectedChatHistory,
      "ws"
    );
    let taxonomy_filter = getTaxonomyFilter();
    if (wsRef.current) {
      const request = {
        question: isRetryBtnClick ? messages[messages.length - 2].content : question.trim(),
        chat_context: selectedChatHistory.messages.slice(
          0,
          isRetryBtnClick ? messages?.length - 2 : messages?.length
        ),
        files_in_context: files_in_context,
        taxonomy_filter: isEmpty(files_in_context) ? taxonomy_filter : null,
      };

      setQuestion("");
      setIsRetryBtnClick(false);
      wsRef.current.send(JSON.stringify(request));
    } else {
      console.error("WebSocket connection is not available.");
    }
  };
  const connectWebSocket = (callback?: () => void) => {
    const token = getLocalStorageItem("token_foundationKM");
    let newWs = new WebSocket(`${configs.WEB_SOCKET_URL}rag/rag-with-metadata?token=${token}`);

    newWs.onopen = () => {
      wsRef.current = newWs;
      if (typeof callback === "function") {
        callback();
      }
    };

    let currentType = "";
    let currentContent = "";
    newWs.onmessage = (event: any) => {
      if (event?.data) {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "start":
            if (data.content_type === null) {
              currentType = "stream";
              currentContent = "";
              dispatch(setIsStreamingStart(true));
            } else if (
              data.content_type === "optimised_question" ||
              data.content_type === "answer"
            ) {
              currentType = data.content_type;
            }
            break;

          case "stream":
            if (data.content_type === null) {
              if (currentType === "optimised_question") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "optimised_Question",
                    ans: currentContent,
                  })
                );
              } else if (currentType === "answer") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "content",
                    ans: currentContent,
                  })
                );
              }
            }
            break;

          case "end":
            if (data.content_type === "optimised_question") {
              currentContent = "";
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "isShowGeneratingLabel",
                  ans: true,
                })
              );
            } else if (data.content_type === "answer") {
              currentContent = "";
            } else {
              dispatch(setIsStreamingStart(false));
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "answerId",
                  ans: "m_" + Date.now(),
                })
              );
              dispatch(setLoadingMessageId(-1));
              setIsSaveChatReply(true);
            }
            break;

          case "blob":
            if (data.content_type === "citations") {
              let citations = JSON.parse(data.message);
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "citations",
                  ans: citations,
                })
              );
            }
            break;

          case "error":
            setIsNewChatStarted(false);
            dispatch(setIsStreamingStart(false));
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "error",
                ans: true,
              })
            );
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "content",
                ans: data.message,
              })
            );
            break;

          default:
            // Handle unknown message types
            break;
        }
      }
    };

    newWs.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    newWs.onclose = (event) => {
      // newWs?.close();
      wsRef.current = null;
      if (event?.reason === "NETWORK_CLOSE") {
        setErrorMessage(true);
        dispatch(setIsStreamingStart(false));
      }
    };
  };

  useEffect(() => {
    if (isSaveChatReply) {
      SaveAIStreamReply();
      setIsSaveChatReply(false);
    }
  }, [isSaveChatReply]);

  useEffect(() => {
    if (uploadFileList?.length && uploadFileList?.length === selectedFiles?.length) {
      SaveAIStreamReply();
    } else if (
      uploadFileList?.length &&
      selectedChatHistory?.id === -1 &&
      !isProcessInProgress?.current
    ) {
      isProcessInProgress.current = true;
      isNewChat = true;
      SaveAIStreamReply();
    }
  }, [selectedFiles, uploadFileList]);

  // const scrollToBottom = () => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  //   }
  // };

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, []);

  const isAtBottom = (container: HTMLDivElement): boolean => {
    const threshold = 1; // Adjust this threshold if necessary
    const scrollTop = Math.round(container.scrollTop);
    const scrollHeight = Math.round(container.scrollHeight);
    const clientHeight = Math.round(container.clientHeight);
    const atBottom = scrollHeight - scrollTop <= clientHeight + threshold;
    return atBottom;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      setShowScrollButton(false);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      setShowScrollButton(!isAtBottom(container));
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  }, [messages]);

  const SaveAIStreamReply = async () => {
    let sliceCount =
      !isEmpty(messages) &&
      messages?.length > 2 &&
      messages[messages.length - 3].type === userType.INFO
        ? -3
        : -2;
    const request = {
      id: isNewChat ? null : selectedChatHistory?.id,
      configSettingId: 1,
      title: isNewChat ? "" : selectedChatHistory.title,
      messages: messages.length ? messages.slice(sliceCount) : [],
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      message: isNewChat ? messages[0]?.content : "",
      createdOn: selectedChatHistory.createdOn || new Date().toISOString(),
      fileContext: getFilesInContext(
        selectedFilesForAssetFilter,
        selectedReleventAsset,
        selectedChatHistory,
        "saveAIReply"
      ),
    };
    if (isNewChat) {
      Object.assign(request, {
        chatTaxonomies: [getSelectedFilterObject()],
      });
    } else {
      Object.assign(request, { chatTaxonomies: selectedChatHistory.chatTaxonomies });
    }
    let addNewHistory = false;
    if (selectedFiles?.length || uploadFileList?.length) {
      request.title = "File Upload";
      const newMessage = {
        id: null,
        type: "human",
        contentType: "file",
        content: JSON.stringify(uploadFileList),
        citations: [],
        error: false,
        answerId: "",
        thumbsUp: false,
        thumbsDown: false,
        userComments: "",
        optimised_Question: "",
        isStopGenerating: "FALSE",
        isShowGeneratingLabel: false,
      };

      request.messages = [...request.messages, newMessage];
      if (!isNewChat) dispatch(setNewChatMessageInChatHistory(newMessage));
      setSelectedFiles([]);
      // dispatch(setEmptyUploadFileList());
      addNewHistory = true;
    }
    try {
      const response = await saveStreamChatReply(request);
      if (response && response.status === 200 && isNewChat) {
        const { id, title } = response.data;
        dispatch(setNewChatHistoryId({ oldId: chatHistoryId, newId: id }));
        dispatch(setNewChatHistoryTitle({ chatHistoryId: id, title: title }));
        setIsNewChatStarted(false);
        if (addNewHistory) dispatch(addNewChatHistory(response.data));
      }
    } catch (error) {
      setIsNewChatStarted(false);
      console.log("Error while saving AI stream reply", error);
    }
    dispatch(setIsStreamingStart(false));
    isNewChat = false;
  };

  const handleRetryBtn = () => {
    if (isNetworkOpen) {
      setIsRetryBtnClick(true);
      setErrorMessage();
      connectWebSocket(() => {
        askToWebSocket();
      });
    }
  };
  const handleNewTopicAction = () => {
    // dispatch(setIsOpenChatHistory(false));
    clearAllChatStates();
    defaultConfigureState();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setSelectedReleventAsset([]));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setStartNewConversation(false));
    dispatch(setUpdateUploadFileList([]));
    dispatch(setContentFilesInContext([]));
    dispatch(setContentSelectedIds([]));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(setContentFilterForNewChat({ pageLocation: "" }));
  };
  const isDisableChat = () => {
    return (
      !isNetworkOpen ||
      isStreamingStart ||
      (selectedChatHistory.id !== -1 && loadingMessageId === selectedChatHistory.id) ||
      isNewChatStarted
    );
  };
  const handleOldChat = () => {
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
    };
    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };
    // const infoMessage: IMessages | null = getInfoMessage();

    let queAns: IMessages[] = [];
    // if (infoMessage) {
    //   queAns = [infoMessage, userMessage, aiReply];
    //   dispatch(setIsNewContextFilterApplied(false));
    // } else
    queAns = [userMessage, aiReply];
    setQuestion("");
    dispatch(addNewQuestionAnswer({ chatId: id, queAns: queAns }));
    connectWebSocket(() => {
      askToWebSocket();
    });
  };
  const handleAskFurther = () => {
    if (id !== -1) {
      handleOldChat();
    } else {
      startNewChat();
    }
  };
  const setErrorMessage = (isError: boolean = false) => {
    // setIsRetryBtnClick(false);
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "error",
        ans: isError,
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "content",
        ans: isError ? "Connection is closed. Please try again" : "",
      })
    );
  };

  const handleCopyClick = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      toast.success("Text copied successfully");
    } catch (error) {
      console.error("Error copying text:", error);
    }
  };

  // Message Like Dislike
  const handleMessageActions = async (message: IMessages, action: string) => {
    try {
      let thumbsUp = false,
        thumbsDown = false;
      if (action === ChatActions.Like) {
        thumbsUp = !message.thumbsUp;
        thumbsDown = false;
      } else {
        thumbsDown = !message.thumbsDown;
        thumbsUp = false;
      }
      dispatch(
        setChatAction({
          ...message,
          userComments: "",
          thumbsUp: thumbsUp,
          thumbsDown: thumbsDown,
        })
      );

      if (action === ChatActions.Dislike && thumbsDown) {
        setIsComment(true);
      }
      if (thumbsUp) toast.success("Feedback sent.");

      const request = {
        chatId: selectedChatHistory.id,
        answerId: message.answerId,
        userComments: "",
        thumbsUp: thumbsUp,
        thumbsDown: thumbsDown,
      };

      const response = await messageComment(request);
      if (response && response.status === 200) {
      }
    } catch (error) {
      console.log("Error while message action", error);
    }
  };
  const handleStopGenerate = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "answerId",
        ans: "m_" + Date.now(),
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "isStopGenerating",
        ans: "TRUE",
      })
    );
    dispatch(setLoadingMessageId(-1));
    dispatch(setIsStreamingStart(false));
    setIsSaveChatReply(true);
  };

  const handleFileUpload = (file: File[]) => {
    setSelectedFiles(file);
  };

  const handleNavigatePage = (content: { filter: IContentFilter; pageLocation: string }) => {
    dispatch(setUpdateAllContentFilter(content.filter));
    dispatch(setUpdateAllSourceFilter(content.filter));
    navigate(content.pageLocation, { state: { isReloadData: true } });
  };

  return (
    <>
      <div className="chat-body-content body-scroll" ref={chatContainerRef}>
        {messages?.length > 0 && (
          <ul>
            {messages.map((message: IMessages, index) => {
              return (
                <React.Fragment key={index}>
                  {message.type === userType.AI ? (
                    <li>
                      <div className="ai-text-wrap">
                        {message?.optimised_Question && (
                          <div className="ai-searching ai-text">
                            <i className="ri-check-line"></i> Searching for :{" "}
                            <b>{message?.optimised_Question}</b>
                          </div>
                        )}
                        {message?.isShowGeneratingLabel && (
                          <div className="ai-generating  ai-text">
                            <i className="ri-check-line"></i> Generating answers for you...
                          </div>
                        )}
                      </div>
                      <div className="conversation-list">
                        <div className="chat-avatar">
                          <div className="user-icon">
                            <i className="ri-user-fill"></i>
                          </div>
                        </div>
                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content position-relative">
                            {!message?.content && message.isStopGenerating !== "TRUE" ? (
                              <div className="p-wrap-content">
                                <span className="skeleton-loader">
                                  <Skeleton title={false} active paragraph={{ rows: 1 }} />
                                </span>
                              </div>
                            ) : (
                              <div className="p-wrap-content">
                                {message?.error ? (
                                  <ExclamationCircleOutlined className="error-icon" />
                                ) : null}
                                {message.isStopGenerating === "TRUE" &&
                                message.content === "Generating..." ? (
                                  <i style={{ color: "#9e9e9e" }}>User has stopped generating.</i>
                                ) : message.isStopGenerating === "TRUE" &&
                                  message.content !== "Generating..." ? (
                                  <>
                                    <Markdown className="mark-down">{message.content}</Markdown>
                                    <span className="stopped-generating">
                                      ...
                                      <br />
                                      <i>User has stopped generating.</i>
                                    </span>
                                  </>
                                ) : (
                                  <Markdown className="mark-down">{message.content}</Markdown>
                                )}
                              </div>
                            )}
                            {!isEmpty(message?.citations) && (
                              <div className="documents-view">
                                <RelevanAssets {...{ citations: message.citations }} />
                              </div>
                            )}

                            {message?.error ? (
                              <div className="retry-btn acrion-btn-3 position-absolute">
                                <Tooltip title="Regenerate">
                                  <Button
                                    className="m-10"
                                    // type="primary"
                                    // danger
                                    onClick={handleRetryBtn}
                                    icon={<i className="ri-loop-right-fill"></i>}></Button>
                                </Tooltip>
                              </div>
                            ) : null}
                            {!message?.error && message?.contentType !== "file" && (
                              <div className="acrion-btn-3 position-absolute">
                                <Tooltip title="Good">
                                  <Button
                                    className={message?.thumbsUp ? "btn active" : "btn"}
                                    type="text"
                                    onClick={() => {
                                      handleMessageActions(message, ChatActions.Like);
                                    }}
                                    icon={<i className="ri-thumb-up-fill"></i>}>
                                    {/* Good */}
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Bad">
                                  <Button
                                    className={message?.thumbsDown ? "btn active" : "btn"}
                                    type="text"
                                    onClick={() => {
                                      handleMessageActions(message, ChatActions.Dislike);
                                      setSelectedMessage(message);
                                    }}
                                    icon={<i className="ri-thumb-down-fill"></i>}>
                                    {/* Bad */}
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Copy">
                                  <Button
                                    className="btn"
                                    type="text"
                                    onClick={() => {
                                      handleCopyClick(message.content);
                                    }}
                                    icon={<i className="ri-file-copy-fill"></i>}>
                                    {/* Copy */}
                                  </Button>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : message?.contentType === "file" && message.content ? (
                    JSON.parse(message.content).map((file: IContent, index: number) => (
                      <li
                        key={`message-${index}`}
                        className="right-chat file-card-chat"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          navigate(`${PATHS.viewContentManagementByUser}/${file?.asset_id}`, {
                            state: {
                              fromWhere: FromWhere.ConversationalSearch,
                              selectedChatHistoryId: selectedChatHistory?.id,
                            },
                          });
                        }}>
                        <ConversationFileCard {...{ file }} />
                      </li>
                    ))
                  ) : message.type === userType.INFO ? (
                    <li className="info-message">
                      {/* remove ↑ this hidden after UI is done */}
                      {/* <div className="chat-avatar">
                          <div className="user-icon">
                            <img src={IMAGES.infoChatIcon} />
                          </div>
                        </div> */}
                      <Divider>
                        {message.content.includes("{")
                          ? JSON.parse(message.content)?.text
                          : message.content}
                        <span className="tooltip-wrap">
                          <Tooltip
                            trigger={"click"}
                            placement="bottomRight"
                            overlayClassName="asset-overlay">
                            <InfoCircleFilled
                              onClick={() => handleNavigatePage(JSON.parse(message.content))}
                            />
                          </Tooltip>
                        </span>
                      </Divider>
                    </li>
                  ) : (
                    <li className="right-chat">
                      <div className="conversation-list">
                        <div className="chat-avatar">
                          <div className="user-icon">
                            <i className="ri-user-fill"></i>
                          </div>
                        </div>

                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content position-relative">
                            <div className="p-wrap-content">{message.content}</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
      <div className="chat-footer">
        <div className="chat-footer-top position-relative d-flex d-flex-middle d-flex-between">
          <Button
            onClick={handleNewTopicAction}
            className="new-topic-btn"
            icon={<i className="ci ci-new-topic"></i>}>
            New Topic
          </Button>
          {!!showScrollButton && (
            <div className="chat-scroll-down position-absolute" onClick={scrollToBottom}>
              <i className="ri-arrow-down-line"></i>
            </div>
          )}
          <Radio.Group className="radio-wrap" onChange={onChange} value={value}>
            <Radio value={"context"}>In Context</Radio>
            <Radio value={"global"}>Everything</Radio>
          </Radio.Group>
        </div>

        <div className="chat-footer-bottom d-flex d-flex-middle">
          <div className="ask-me-input d-flex d-flex-middle position-relative">
            <Input
              type="text"
              value={userQuestion ? "" : question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              disabled={isDisableChat()}
              onPressEnter={() => {
                if (question.trim() && !isDisableChat()) {
                  handleAskFurther();
                }
              }}
              prefix={
                <i
                  onClick={() => [dispatch(setEmptyUploadFileList()), setIsOpenUploadAsset(true)]}
                  className="ri-attachment-2"></i>
              }
              placeholder="Ask me anything or upload content..."
            />
            <Button
              type="primary"
              disabled={!question.trim() || isDisableChat()}
              onClick={handleAskFurther}
              className="send-btn position-absolute">
              <i className="ri-send-plane-fill"></i>
            </Button>
            {isStreamingStart && (
              <Button
                type="primary"
                onClick={handleStopGenerate}
                className="send-btn position-absolute stop-generating">
                <i className="ri-stop-fill"></i>
                {/* Stop generating */}
              </Button>
            )}
          </div>
          {/* <Button type="link" className="recording-btn d-flex d-flex-middle d-flex-center ">
            <i className="ci ci-mic"></i>
          </Button> */}
        </div>
        <UploadMultipleAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          setSelectedFiles={handleFileUpload}
          uploadMultiple={true}
        />
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ChatPanel;
