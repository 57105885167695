import { Button } from "antd";
import "./BookmarkIcon.scss";

interface IBookmarkIcon {
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
}

const BookmarkIcon = ({ isActive = false, disabled = false, className = "" }: IBookmarkIcon) => {
  return (
    <Button type="text" className={`${className} custom-icon bookmark-icon`} disabled={disabled}>
      {isActive ? (
        <i className="ci-bookmark-icon-check ci"></i>
      ) : (
        <i className="ci-bookmark-icon-uncheck ci"></i>
      )}
    </Button>
  );
};

export default BookmarkIcon;
