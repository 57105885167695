import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";
import { deleteRequest, get, post, put } from "./apiClients";
import { store } from "../store";
import {
  setAllContentDetails,
  setContentById,
  setIsContentByIdLoading,
  setIsContentTableLoading,
  setIsEditContentLoading,
  setSelectedAllTagsByAssestId,
  setIsAllTagsByAssestIdLoading,
  setIsUpdateTagsByAssestIdLoading,
  refreshedAssetByAssetId,
  setTotalContentCount,
  setContentDeleteLoading,
  contentByIdInitialValue,
  tagsByAssestIdInitValue,
  autoRefreshContent,
  updateUploadedContent,
  setContentExportCSV,
  setIsContentExportCSVLoading,
  setFileUploadProgress,
  setURLUploadProgress,
  setDownloadLogError,
  setDownloadLimit,
  setIsFetchDownloadLimit,
  setIsSaveDownloadLimit,
  setIsRunVisionLoading,
  setIsProcessStopLoading,
  updatePageOnContentDelete,
  setIsMultipleContentDeleteLoading,
  setBookmarkedContents,
  setContentSelectedIds,
  setContentFilesInContext,
  updatePageOnSourceDelete,
  setIsRetryLoading,
} from "../store/contentManagement/contentSlice";
import {
  IContent,
  IContentFilter,
  IContentSelectedIds,
} from "../store/contentManagement/content.interface";
import {
  setSelectedFilesForAssetFilter,
  setUploadFileList,
} from "../store/conversationalSearch/conversationalSearchSlice";
import {
  setAssetCount,
  setIsAssetCountLoading,
  setIncDownloadCount,
  setIncViewCount,
} from "../store/appState/appSlice";
import { fetchAllUsers } from "./userManagement";
import {
  PageLimit,
  PublishedOption,
  PATHS,
  FileName,
  onDownloadPdf,
  UploadAssetState,
  convertToNumber,
  IUploadFormProps,
} from "../shared";

export const uploadAsset = async (
  uploadFormData: IUploadFormProps,
  navigate: NavigateFunction,
  setUploadingState: (state: UploadAssetState) => void,
  fromWhere?: string,
  toWhere?: string,
  fileSize?: number,
  uploadMultiple?: boolean,
  preventRedirect?: boolean
) => {
  try {
    setUploadingState(UploadAssetState.UPLOADING);
    const { data } = await post(`/core/asset/assetUpload`, uploadFormData?.formData, {
      params: {
        userId: uploadFormData?.userId,
        content_type: uploadFormData?.fileType,
        file_level: uploadFormData?.fileLevel,
        title: uploadFormData?.title,
        summary: uploadFormData?.summary,
        ["linked-tag-ids"]: String(uploadFormData?.linkedTagIds),
        tags: String(uploadFormData?.tags),
        ["vision-processing"]: uploadFormData?.visionProcessing,
        ["is-survey-data"]: uploadFormData?.isSurveyData,
        ["generate-summary"]: uploadFormData?.generateSummary,
      },
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    store.dispatch(updateUploadedContent(data[0]));
    store.dispatch(setContentById(contentByIdInitialValue));
    // store.dispatch(autoRefreshContent({ assetId: data[0]?.asset_id }));
    store.dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));

    if (toWhere) {
      navigate(toWhere);
    }
    if (!preventRedirect && fromWhere)
      navigate(`${PATHS.viewEditContentManagement}/${data[0]?.asset_id}`, {
        state: {
          isEditMode: true,
          isNewAssetUpload: true,
          fromWhere,
          releventAssetId: uploadMultiple ? data[0]?.asset_id : null,
        },
      });
    // updateViewCount(data[0]?.asset_id);
    if (uploadMultiple) {
      store.dispatch(setFileUploadProgress(0));
      store.dispatch(setUploadFileList({ ...data[0], size: fileSize }));
    }
    setUploadingState(UploadAssetState.UPLOADED);
    toast.success("Asset uploaded successfully");
    return data;
  } catch (error: any) {
    setUploadingState(UploadAssetState.FAILED);
    console.log("UploadMainRfp api Error", error?.error);
    toast.error(error?.error ?? "Failed to upload asset");
    return error;
  }
};

export const autoRefreshedAssetById = async (assetId: number): Promise<IContent> => {
  try {
    const data = await get(`core/asset/assetInfoForUser/${assetId}`);
    store.dispatch(refreshedAssetByAssetId({ data, id: assetId }));

    return data;
  } catch (err: any) {
    console.log("get AssetBy Id api error", err);
    store.dispatch(autoRefreshContent({ assetId: -1 }));
    return err;
  }
};

const getTaxonomyNodes = (contentFilter: IContentFilter): string => {
  let nodesIds: number[] = [];

  if (contentFilter?.assetType && contentFilter?.assetType?.length) {
    nodesIds = [...nodesIds, ...contentFilter?.assetType];
  }
  if (contentFilter?.industry && contentFilter?.industry?.length) {
    nodesIds = [...nodesIds, ...convertToNumber(contentFilter?.industry)];
  }
  if (contentFilter?.function && contentFilter?.function?.length) {
    nodesIds = [...nodesIds, ...convertToNumber(contentFilter?.function)];
  }
  return String(nodesIds);
};

export const getAllContent = async (contentFilter: IContentFilter) => {
  const searchText = contentFilter?.searchText ?? null;
  const page = contentFilter?.currentPage ? contentFilter?.currentPage : 1;
  const isPublished = contentFilter?.publishedFilter
    ? contentFilter?.publishedFilter
    : PublishedOption[0].value;
  const isBookmarked = contentFilter?.isBookmarked ? contentFilter?.isBookmarked : null;
  const group = contentFilter?.group ?? null;
  const taxonomyNodes = getTaxonomyNodes(contentFilter) ? getTaxonomyNodes(contentFilter) : null;
  const docType = contentFilter?.documentType?.length ? String(contentFilter?.documentType) : null;
  const contentType = contentFilter?.contentType?.length
    ? String(contentFilter?.contentType)
    : null;

  const assetStatus = contentFilter?.assetStatus?.length
    ? String(contentFilter?.assetStatus)
    : null;

  const fileLevel = contentFilter?.fileLevel ? contentFilter?.fileLevel : null;

  try {
    store.dispatch(setIsContentTableLoading(true));
    store.dispatch(setAllContentDetails([]));

    const { content, totalElements } = await get(`/core/asset/searchByUserAndTitleOrSummary`, {
      params: {
        titleOrSummary: searchText ? searchText : null,
        page: page - 1,
        limit: PageLimit.ContentManagement,
        isPublished: isPublished,
        is_bookmarked: isBookmarked,
        group,
        linked_tags: taxonomyNodes,
        file_types: docType,
        file_levels: fileLevel,
        subGroup: contentType ? contentType : null,
        asset_status: assetStatus ? assetStatus : null,
        from_date: contentFilter?.startDate ? contentFilter?.startDate : null,
        to_date: contentFilter?.endDate ? contentFilter?.endDate : null,
      },
    });

    // await getAssetCount();
    const contentRes = content ?? [];
    const totalElementsRes = totalElements ?? 0;

    store.dispatch(setAllContentDetails(contentRes));
    store.dispatch(setTotalContentCount(totalElementsRes));
    return contentRes;
  } catch (error) {
    console.log(error);
    store.dispatch(setAllContentDetails([]));
    store.dispatch(setTotalContentCount(0));
    return error;
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

const handleGetTitle = (res: IContent) => {
  let title = res.title;
  if (!title) {
    if (res.asset_url) {
      title = res.asset_url;
    } else {
      title = res.original_file_name;
    }
  }

  return title;
};

export const getAssetById = async (
  assetId: number,
  setContent: (v: IContent) => void,
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsContentByIdLoading(true));
    const res = await get(`/core/asset/assetInfoForUser/${assetId}`);
    fetchAllUsers("");
    store.dispatch(setContentById(res));
    setContent({ ...res, title: handleGetTitle(res) });
    return res;
  } catch (error: any) {
    if (error && error?.status === 404) {
      false && navigate(-1);
    }
    return error;
  } finally {
    store.dispatch(setIsContentByIdLoading(false));
  }
};

export const editAssetById = async (
  assetId: number,
  title: string,
  summary: string,
  assetType: string,
  userId: number,
  contributors: { id: number }[],
  summaryStatus: string | null
) => {
  try {
    store.dispatch(setIsEditContentLoading(true));
    await put(`/core/asset/editAsset`, {
      asset_id: assetId,
      title,
      summary,
      assetType,
      user: { id: userId },
      contributors: contributors,
      summaryStatus,
    });
    toast.success("Successfully saved asset details");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to save asset details");
  } finally {
    store.dispatch(setIsEditContentLoading(false));
  }
};

export const deleteAssetById = async (
  assetId: number,
  totalContentsCount: number,
  isCallGetAllContent: boolean,
  contentFilter: IContentFilter,
  currentPage?: string
) => {
  store.dispatch(setContentDeleteLoading(true));

  try {
    let isCallGetAllContentValue = isCallGetAllContent;
    const res = await deleteRequest(`/core/asset/deleteAssetInfo/${assetId}`);

    let newPage = contentFilter?.currentPage;
    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      newPage = newPage - 1;
      isCallGetAllContentValue = true;
    }

    toast.success("Content successfully deleted");
    isCallGetAllContentValue && getAllContent({ ...contentFilter, currentPage: newPage });

    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      if (currentPage === "contet") store.dispatch(updatePageOnContentDelete(newPage));
      else store.dispatch(updatePageOnSourceDelete(newPage));
    }
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to delete content");
    return error;
  } finally {
    store.dispatch(setContentDeleteLoading(false));
  }
};

export const searchAsset = async (text: string) => {
  try {
    store.dispatch(setIsContentTableLoading(true));
    const res = await get(`/core/asset/searchByTitle?title=${text}`);
    store.dispatch(setAllContentDetails(res));
    return res;
  } catch (error) {
    console.log("search text error ", error);
    return error;
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

export const enablePublish = async (id: number) => {
  try {
    const res = await put(`/core/asset/enable/${id}`);
    toast.success("Successfully published");
    return res;
  } catch (error) {
    console.log("enable publish error ", error);
    // return error;
  }
};

export const disablePublish = async (id: number) => {
  try {
    const res = await put(`/core/asset/disable/${id}`);
    toast.success("Successfully disabled");
    return res;
  } catch (error) {
    console.log("disable publish error ", error);
    // return error;
  }
};

export const getAllTagsByAssestId = async (assetId: number) => {
  try {
    store.dispatch(setIsAllTagsByAssestIdLoading(true));
    const res = await get(`/core/asset/linkedTags/${assetId}`);

    store.dispatch(setSelectedAllTagsByAssestId(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setIsAllTagsByAssestIdLoading(false));
  }
};

export const saveTagsByAssestId = async (assetId: number, taxonomyNodeIds: number[]) => {
  try {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(true));
    const res = await post(`/core/asset/linkedTags/${assetId}`, {
      taxonomyNodeIds,
    });
    toast.success("Tags successfully saved.");

    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to save the Tags");
  } finally {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(false));
  }
};

export const updateTagsByAssestId = async (assetId: number, taxonomyNodeIds: number[]) => {
  try {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(true));
    const res = await put(`/core/asset/linkedTags/${assetId}`, {
      taxonomyNodeIds,
    });
    toast.success("Tags successfully updated.");

    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to update the Tags");
  } finally {
    store.dispatch(setIsUpdateTagsByAssestIdLoading(false));
  }
};

export const uploadAssetURL = async (
  userId: number,
  uploadAssetsViaURL: string,
  navigate: NavigateFunction,
  setUploadingState: (state: UploadAssetState) => void,
  fileLevel: string,
  fromWhere?: string
) => {
  try {
    const { data } = await post(
      `/core/asset/assetUrlUpload?userId=${userId}&asset_url=${uploadAssetsViaURL}&file_level=${fileLevel}`,
      {},
      {
        onUploadProgress: function (progressEvent) {
          if (progressEvent?.loaded && progressEvent?.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            store.dispatch(setURLUploadProgress(percentCompleted));
          }
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    store.dispatch(setContentById(contentByIdInitialValue));
    store.dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    navigate(`${PATHS.viewEditContentManagement}/${data?.asset_id}`, {
      state: { isEditMode: true, isNewAssetUpload: true, fromWhere },
    });
    toast.success("Asset URL uploaded successfully");
    return data;
  } catch (error: any) {
    console.log("UploadMainRfp api Error", error?.error);
    setUploadingState(UploadAssetState.FAILED);
    toast.error(error?.error ?? "Failed to upload asset");
    return error;
  }
};

export const getContentExportCSV = async () => {
  store.dispatch(setIsContentExportCSVLoading(true));

  try {
    const res = await get(`/core/asset/csvFile`);
    store.dispatch(setContentExportCSV(res));
    onDownloadPdf(res, null, FileName.AssetSCV);
    toast.success("Asset successfully exported");
  } catch (error) {
    console.log(error);
    toast.error("Failed to export Asset. Please try again");
  } finally {
    store.dispatch(setIsContentExportCSVLoading(false));
  }
};

export const updateDownloadLogs = async (content: IContent) => {
  try {
    await post(`/core/file-download-logs/add-log`, {
      asset_id: content?.asset_id,
    });

    store.dispatch(setDownloadLogError(null));
  } catch (error: any) {
    error?.errorCode === 400 ? store.dispatch(setDownloadLogError(error?.errorMessage)) : null;
    toast.error(error?.error ?? "Failed to download the file");
    return error;
  }
};

export const updateDownloadCount = async (assetId: number) => {
  try {
    const res = await post(`/core/asset/${assetId}/download-count/increment`);
    if (res) {
      store.dispatch(setIncDownloadCount());
    }
  } catch (error: any) {
    toast.error(error?.error ?? "Failed update download count");
    return error;
  }
};

export const getDownloadLimit = async (isLoading: boolean = true) => {
  store.dispatch(setIsFetchDownloadLimit(isLoading));
  try {
    const res = await get(`/core/file-download-logs/download-limit`);
    store.dispatch(setDownloadLimit(res));
  } catch (error) {
    store.dispatch(setDownloadLimit(0));
  } finally {
    store.dispatch(setIsFetchDownloadLimit(false));
  }
};

export const updateDownloadLimit = async (downloadLimit: number) => {
  try {
    store.dispatch(setIsSaveDownloadLimit(true));
    await put(`/core/file-download-logs/download-limit`, downloadLimit);
    store.dispatch(setDownloadLimit(downloadLimit));
    toast.success("Download limit successfully updated.");
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to update the Download limit");
  } finally {
    store.dispatch(setIsSaveDownloadLimit(false));
  }
};

export const runVision = async (assetId: number) => {
  try {
    store.dispatch(setIsRunVisionLoading(true));

    const res = await post(`/core/asset/reprocess/${assetId}?vision-processing=true`);
    // toast.success("Vision successfully ran");
    return res;
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to run vision");
  } finally {
    store.dispatch(setIsRunVisionLoading(false));
  }
};

export const getAssetDetailsByAssetId = async (assetId: number) => {
  try {
    const data = await get(`core/asset/assetInfoForUser/${assetId}`);
    return data;
  } catch (err: any) {
    console.log("get AssetBy Id api error", err);
    throw err;
  }
};

export const stopProcessing = async (assetId: number) => {
  try {
    store.dispatch(setIsProcessStopLoading(true));
    const res = await post(`/core/asset/stop-processing/${assetId}`);
    toast.success("Process stopping successfully started");
    return res;
  } catch (error: any) {
    toast.error(error?.error ?? "Failed to stop process");
  } finally {
    store.dispatch(setIsProcessStopLoading(false));
  }
};

export const generateSummary = async (assetId: number) => {
  try {
    const res = await post(`/core/asset/summarize-document/${assetId}`);
    toast.success("Summary generation successfully started");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate summary");
  }
};

export const bookmarkContent = async (assetId: number, isBookmark: boolean) => {
  try {
    const res = await put(`/core/asset/${assetId}/bookmark`, { is_bookmarked: isBookmark });

    if (isBookmark) {
      toast.success("Content bookmarked");
    } else {
      toast.success("Content removed from bookmark");
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to bookmark");
  }
};

export const likeContent = async (assetId: number, isLike: boolean | null, flag: string) => {
  try {
    // store.dispatch(setIsContentTableLoading(true));
    const res = await put(`/core/asset/${assetId}/like`, { is_liked: isLike });

    if (flag === "like") {
      if (isLike === true) {
        toast.success(`Content liked`);
      } else {
        toast.success(`Content removed from like`);
      }
    } else if (flag === "dislike") {
      console.log("isLike  ===", isLike);
      if (isLike === false) {
        toast.success(`Content disliked`);
      } else {
        toast.success(`Content removed from dislike`);
      }
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? `Failed to ${isLike ? "like" : "dislike"}`);
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

export const deleteMultipleContentByIds = async (
  assetIds: number[],
  filter: IContentFilter,
  currentPage: string,
  totalContentsCount: number
) => {
  store.dispatch(setIsMultipleContentDeleteLoading(true));

  try {
    const res = await deleteRequest(`/core/asset`, {
      params: { id: String(assetIds) },
    });
    let newPage = filter?.currentPage;
    if ((totalContentsCount - assetIds.length) % PageLimit.ContentManagement === 0) {
      newPage = newPage - 1;
      if (currentPage === "content") store.dispatch(updatePageOnContentDelete(newPage));
      else store.dispatch(updatePageOnSourceDelete(newPage));
      getAllContent({ ...filter, currentPage: newPage });
    }

    toast.success("Contents successfully deleted");

    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to delete contents");
    return error;
  } finally {
    store.dispatch(setIsMultipleContentDeleteLoading(false));
  }
};

export const updateViewCount = async (assetId: number) => {
  try {
    const res = await post(`/core/asset/${assetId}/view-count/increment`);
    if (res) {
      store.dispatch(setIncViewCount());
    }
  } catch (error: any) {
    // toast.error(error?.error ?? "Failed update view count");
    return error;
  }
};

export const getAssetCount = async () => {
  try {
    store.dispatch(setIsAssetCountLoading(true));
    const res = await get(`/core/asset/stats`);
    store.dispatch(setAssetCount(res));
  } catch (error: any) {
    return error;
  } finally {
    store.dispatch(setIsAssetCountLoading(false));
  }
};

export const getAllBookmarkedContent = async (isLoading: boolean) => {
  try {
    isLoading && store.dispatch(setIsContentTableLoading(true));
    const { content } = await get(`/core/asset/searchByUserAndTitleOrSummary`, {
      params: {
        is_bookmarked: true,
        group: "sources",
      },
    });
    store.dispatch(setBookmarkedContents(content ?? []));
    return content;
  } catch (error) {
    store.dispatch(setBookmarkedContents([]));
    return error;
  } finally {
    store.dispatch(setIsContentTableLoading(false));
  }
};

export const selectContent = async (payload: number[], isAfterDelete?: boolean) => {
  try {
    // store.dispatch(setIsContentTableLoading(true));
    const res = await put(`/core/asset/select`, payload);
    if (!isAfterDelete) toast.success("Changes applied successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to select");
  }
};

export const getContentSelected = async () => {
  try {
    // store.dispatch(setIsContentTableLoading(true));
    const res = await get(`/core/asset/file-in-context`);

    const selectedIds = res.length && res?.map((v: IContentSelectedIds) => v?.asset_id);
    selectedIds && store.dispatch(setContentSelectedIds(selectedIds));
    store.dispatch(setContentFilesInContext(res));
    store.dispatch(setSelectedFilesForAssetFilter(res));
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to get selected files");
  } finally {
    // store.dispatch(setIsContentTableLoading(false));
  }
};

export const retryProcess = async (assetId: number) => {
  try {
    store.dispatch(setIsRetryLoading(true));

    const res = await post(`/core/asset/reprocess/${assetId}`);
    toast.success("Retry successfully started");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to retry");
  } finally {
    store.dispatch(setIsRetryLoading(false));
  }
};
