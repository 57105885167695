import { Button, Card, ColorPicker, Flex, Input } from "antd";
import "./theme.scss";
import { PageHeader } from "../../../components";
import { motion } from "framer-motion";
import { ChangeEvent, useRef, useState } from "react";

const Theme = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>("");
  // const [isUploading, setIsUploading] = useState<boolean>(false);
  // const [fileType, setFileType] = useState<string>("");
  // const [file, setFile] = useState<File | null>(null);
  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      // const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);

      // setFileType(fileType);
      // setFile(file);
      setFileName(file.name);
    }
  };
  // const handleRemoveFile = () => {
  //   setFileName("");

  //   // setFile(null);
  //   // form.setFieldsValue({ file: null });
  //   // form.setFieldsValue({
  //   //   title: "",
  //   // });

  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = "";
  //   }
  // };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader title="Theme Configuration" isRelevantAsset={false} />
        <div className="theme-wrap">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="card-theme">
            <Card className="theme-inner">
              <h3>Upload Logo*</h3>
              <div className="card-list">
                <Card className="card-item">
                  <label className="item-label">Application Logo</label>

                  <div className="browse-wrap">
                    <input
                      hidden
                      ref={fileInputRef}
                      className="file-input"
                      type="file"
                      multiple={false}
                      placeholder="Upload Asset"
                      onChange={handleFileUpload}
                    />
                    <div className="upload-file-container">
                      <div className="file-name">
                        {fileName ? (
                          <>
                            <div className="file-name-div acn-flex">
                              <i className="ri-attachment-line browseIcon"></i>
                              <span className="file-name-span">{fileName}</span>
                            </div>
                            {/* {!isUploading && (
                              <Button
                                type="text"
                                className="text-btn remove-file"
                                onClick={handleRemoveFile}>
                                <i className="ri-delete-bin-fill"></i>
                              </Button>
                            )} */}
                          </>
                        ) : (
                          <span className="acn-flex acn-flex-middle">
                            <i className="ri-attachment-line browseIcon"></i>
                            <span>Browse</span>
                          </span>
                        )}
                      </div>

                      <Button
                        onClick={handleClickUploadFile}
                        className=" btn btn-primary browse-btn"
                        type="primary"
                        size="middle"
                        // disabled={isUploading}
                      >
                        Browse
                      </Button>
                    </div>
                  </div>
                </Card>
                <Card className="card-item">
                  <label className="item-label">Assistant Logo</label>

                  <div className="browse-wrap">
                    <input
                      hidden
                      ref={fileInputRef}
                      className="file-input"
                      type="file"
                      multiple={false}
                      placeholder="Upload Asset"
                      onChange={handleFileUpload}
                    />
                    <div className="upload-file-container">
                      <div className="file-name">
                        {fileName ? (
                          <>
                            <div className="file-name-div acn-flex">
                              <i className="ri-attachment-line browseIcon"></i>
                              <span className="file-name-span">{fileName}</span>
                            </div>
                            {/* {!isUploading && (
                              <Button
                                type="text"
                                className="text-btn remove-file"
                                onClick={handleRemoveFile}>
                                <i className="ri-delete-bin-fill"></i>
                              </Button>
                            )} */}
                          </>
                        ) : (
                          <span className="acn-flex acn-flex-middle">
                            <i className="ri-attachment-line browseIcon"></i>
                            <span>Browse</span>
                          </span>
                        )}
                      </div>

                      <Button
                        onClick={handleClickUploadFile}
                        className=" btn btn-primary browse-btn"
                        type="primary"
                        size="middle"
                        // disabled={isUploading}
                      >
                        Browse
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </Card>
            <Card className="theme-inner">
              <h3>Select Color*</h3>
              <div className="card-list">
                <Card className="card-item">
                  <label className="item-label">Primary Color</label>
                  <div className="browse-wrap d-flex d-flex-middle d-flex-between">
                    <div className="color-code d-flex d-flex-middle">
                      <ColorPicker defaultValue="#1677ff" showText />
                    </div>
                    <div className="color-code-input">
                      <Input placeholder="Enter color code" />
                    </div>
                  </div>
                </Card>
                <Card className="card-item">
                  <label className="item-label">Secondary Color</label>
                  <div className="browse-wrap d-flex d-flex-middle d-flex-between">
                    <div className="color-code d-flex d-flex-middle">
                      <ColorPicker defaultValue="#1677ff" showText />
                    </div>
                    <div className="color-code-input">
                      <Input placeholder="Enter color code" />
                    </div>
                  </div>
                </Card>
                <Card className="card-item">
                  <label className="item-label">Tertiary Color</label>
                  <div className="browse-wrap d-flex d-flex-middle d-flex-between">
                    <div className="color-code d-flex d-flex-middle">
                      <ColorPicker defaultValue="#1677ff" showText />
                    </div>
                    <div className="color-code-input">
                      <Input placeholder="Enter color code" />
                    </div>
                  </div>
                </Card>
                <Card className="card-item">
                  <label className="item-label">Text Color</label>
                  <div className="browse-wrap d-flex d-flex-middle d-flex-between">
                    <div className="color-code d-flex d-flex-middle">
                      <ColorPicker defaultValue="#1677ff" showText />
                    </div>
                    <div className="color-code-input">
                      <Input placeholder="Enter color code" />
                    </div>
                  </div>
                </Card>
              </div>
            </Card>
          </motion.div>
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="card-footer-btn">
            <Button type="default" className="btn text-btn cancel">
              Cancel
            </Button>
            <Button type="primary" className="btn text-btn apply">
              Apply
            </Button>
          </motion.div>
        </div>
      </Flex>
    </>
  );
};

export default Theme;
